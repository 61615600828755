import React, { useEffect, useState } from "react";
import { Sidebar } from "react-pro-sidebar";
import luxiferLogoWhite from "../../assets/white-logo.png";
import "./SendCode.css";
import { useLocation, useNavigate } from "react-router-dom";
import ShareAnalyticsScript from "../ShareAnalyticsScript/ShareAnalyticsScript";
import { ToastContainer } from "react-toastify";

function SendCodeComponent() {
  const [error, setError] = useState(null);
  const [siteid, setSiteId] = useState("");
  const [code, setCode] = useState("");
  const [type, setType] = useState("");
  const [sample, setSample] = useState("");

  const location = useLocation();
  const navigate = useNavigate();

  const getQueryParams = (query) => {
    return query
      .substring(1)
      .split("&")
      .reduce((result, value) => {
        const [key, val] = value.split("=");
        result[key] = decodeURIComponent(val);
        return result;
      }, {});
  };

  useEffect(() => {
    const queryParams = getQueryParams(location.search);
    if (!queryParams.type) {
      setError("Error: Invalid or missing type provided.");
    } else {
      const type = queryParams.type || "";
      const code = queryParams.code || "";
      const siteid = queryParams.siteid || "";
      const sample = queryParams.sample || "10";

      if (type === "none" && !siteid) {
        setError("Error: Missing site ID for 'none' type.");
      } else if (type === "onetrust" && (!code || !siteid)) {
        setError("Error: Missing stats code or site ID for 'onetrust' type.");
      }

      setSiteId(siteid);
      setCode(code);
      setType(type);
      setSample(sample);
    }
  }, [location.search, navigate]);

  return (
    <>
      <div className="send-code-container">
        {/* Sidebar */}
        <Sidebar
          style={{
            height: "100vh",
            zIndex: 2,
            position: "fixed",
            border: "none",
          }}
          backgroundColor="black"
          breakPoint="sm"
        >
          <a href="/">
            <div className="d-flex w-100">
              <img
                src={luxiferLogoWhite}
                alt="logo"
                style={{
                  width: "133.4px",
                  height: "32px",
                  marginLeft: "35.8px",
                  marginTop: "35.8px",
                }}
              />
            </div>
          </a>
        </Sidebar>

        {/* Main Content */}
        <div className="main-content">
          <div
            style={{ marginBottom: "16px" }}
            className="fw-700 fs-20 lh-243 txt-b"
          >
            Implement
          </div>
          {error ? (
            <div className="error-message">{error}</div>
          ) : (
            <>
              <ShareAnalyticsScript
                showButton={false}
                type={type}
                siteid={siteid}
                oneTrustCode={code}
                sampleSize={sample}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default function SendCode() {
  return (
    <>
      <ToastContainer />
      <SendCodeComponent />
    </>
  );
}
