import {
  deleteDocumentsByProjectId,
  startAnalysisMaestro,
} from "../../helpers";
import CustomInput from "../CustomInput/CustomInput";
import { useEffect, useState } from "react";

function AdminSpecialAnalysisContent() {
  const [pid, setPid] = useState("");
  const [insights, setInsights] = useState(false);
  const [elementize, setElementize] = useState(false);
  const [screenshot, setScreenshot] = useState(false);
  const [updateMRAID, setUpdateMRAID] = useState(false);

  return (
    <div style={{ height: "100vh", flexGrow: 1 }}>
      <div className="">
        <h1>Special analysis page</h1>
        <CustomInput
          placeholder="Project id"
          containerClassName="observation-text-input-container"
          inputClassNames="delete-width-input"
          className={pid === "" ? "active" : ""}
          valueState={pid}
          onChange={(e) => setPid(e.target.value)}
          value={pid}
        />
      </div>
      <div>
        <button
          type="button"
          onClick={() => {
            setInsights(!insights);
          }}
          className="behavior-toggle align-items-center fw-700"
          style={{
            backgroundColor: insights ? "#2A00FF" : "#707070",
            float: "left",
          }}
        >
          <div
            className={
              insights
                ? "behavior-toggle-circle-on"
                : "behavior-toggle-circle-off"
            }
          ></div>
        </button>
        Generate insights
      </div>
      <div>
        <button
          type="button"
          onClick={() => {
            setElementize(!elementize);
          }}
          className="behavior-toggle align-items-center fw-700"
          style={{
            backgroundColor: elementize ? "#2A00FF" : "#707070",
            float: "left",
          }}
        >
          <div
            className={
              elementize
                ? "behavior-toggle-circle-on"
                : "behavior-toggle-circle-off"
            }
          ></div>
        </button>
        Generate Elements
      </div>
      <div>
        <button
          type="button"
          onClick={() => {
            setScreenshot(!screenshot);
          }}
          className="behavior-toggle align-items-center fw-700"
          style={{
            backgroundColor: screenshot ? "#2A00FF" : "#707070",
            float: "left",
          }}
        >
          <div
            className={
              screenshot
                ? "behavior-toggle-circle-on"
                : "behavior-toggle-circle-off"
            }
          ></div>
        </button>
        Generate Screenshots
      </div>
      <div>
        <button
          type="button"
          onClick={() => {
            setUpdateMRAID(!updateMRAID);
          }}
          className="behavior-toggle align-items-center fw-700"
          style={{
            backgroundColor: updateMRAID ? "#2A00FF" : "#707070",
            float: "left",
          }}
        >
          <div
            className={
              updateMRAID
                ? "behavior-toggle-circle-on"
                : "behavior-toggle-circle-off"
            }
          ></div>
        </button>
        Update MRAID
      </div>
      <div>
        <button
          className="btn-primary"
          onClick={() => {
            if (
              window.confirm("Are you sure you want to start the analysis?")
            ) {
              const data = {
                projectId: pid,
                skipAnalysis: !insights,
                skipScreenshot: !screenshot,
                skipElementize: !elementize,
                skipMRAID: true,
              };
              startAnalysisMaestro(data);
            }
          }}
        >
          Analyze
        </button>
      </div>
      <div>
        <button
          className="btn-secondary"
          onClick={() => {
            if (
              window.confirm("Are you sure you want to delete screenshots?")
            ) {
              deleteDocumentsByProjectId(pid, "projectScreenshots");
            }
          }}
        >
          Delete screenshots
        </button>
      </div>
      <div>
        <button
          className="btn-secondary"
          onClick={() => {
            if (window.confirm("Are you sure you want to delete elements?")) {
              deleteDocumentsByProjectId(pid, "heatmapElements");
            }
          }}
        >
          Delete elements
        </button>
      </div>
    </div>
  );
}

export default function AdminSpecialAnalysis(props) {
  return (
    <>
      <AdminSpecialAnalysisContent />
    </>
  );
}
