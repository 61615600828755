import "./CustomSidebar.css";
import { Menu, MenuItem, Sidebar } from "react-pro-sidebar";
import { useEffect, useState } from "react";
import Select, { components } from "react-select";
import { Link, useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { useContext, useRef } from "react";
import arrowLeft from "../../assets/arrow-left.svg";
import arrowRight from "../../assets/arrow-right-black.svg";
import grayPfp from "../../assets/gray.PNG";
import luxiferLogo from "../../assets/luxifer-logo.svg";
import luxiferLogoSmall from "../../assets/small-icon-hires.png";
import luxiferLogoWhite from "../../assets/white-logo.png";
import voixLogo from "../../assets/voix-logo.png";
import dashboardLogo from "../../assets/dashboard.svg";
import notificationsLogo from "../../assets/notifications.svg";
import dashboardLogoInactive from "../../assets/dashboard-inactive.svg";
import notificationsLogoInactive from "../../assets/notifications-inactive.svg";
import todoLogoActive from "../../assets/blue-house.png";
import todoLogoInactive from "../../assets/black-house.png";
import lightbulbBlue from "../../assets/lightbulb-blue.png";
import lightbulbBlack from "../../assets/lightbulb-black.png";
import cursorBlue from "../../assets/cursor-blue.png";
import cursorBlack from "../../assets/cursor-black.png";
import logoutBtn from "../../assets/power-off-light.png";
import logoutBtnWhite from "../../assets/power-off-light2.png";
import newProject from "../../assets/new-project.png";
import {
  UserInfoContext,
  IsMobileContext,
  ProjectsContext,
  SelectedProjectContext,
} from "../../contexts";
import projectsLogo from "../../assets/projects.svg";
import projectsLogoInactive from "../../assets/projects-inactive.svg";
import { auth } from "../../firebase";
import { Button, Spinner, OverlayTrigger, Tooltip } from "react-bootstrap";
import { firebaseLogout } from "../../helpers";
import FunnelAnalysisSidebarContent from "../FunnelAnalysisSidebarContent/FunnelAnalysisSidebarContent";

function CustomSidebar({
  setUserInfo,
  setSelectedProject,
  activeMenuItem,
  setActiveMenuItem,
  sidebarCollapsed,
  setSidebarCollapsed,
  hideSidebar,
  profileView,
  setUserChannels,
  setAdminPage,
  setHeatmapFunnelFilters,
  heatmapFunnelFilters,
  heatmapOrFunnel,
  heatmapPrefsLoaded,
  setHeatmapPrefsLoaded,
  ...props
}) {
  const selectedPage = window.location.pathname;
  const navigate = useNavigate();
  let userInfo = useContext(UserInfoContext);
  if (props.isEmptyBar) {
    userInfo = null;
  }

  const isMobileView = useContext(IsMobileContext);
  const projects = useContext(ProjectsContext);
  const selectedProject = useContext(SelectedProjectContext);
  /*  const [menuCollapsed, setMenuCollapsed] = useState(true);*/
  const [projectsOpen, setProjectsOpen] = useState(false);
  const [projectImageLoading, setProjectImageLoading] = useState(true);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [hideSidebarText, setHideSidebarText] = useState(false);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    setHideSidebarText(windowWidth < 900);
  }, [windowWidth]);

  const [projectDropdownOptions, setProjectDropdownOptions] = useState([]);
  const selectRef = useRef();

  useEffect(() => {
    if (selectedProject && projects) {
      setProjectDropdownOptions(
        projects.filter((project) => project !== selectedProject),
      );
    }
  }, [selectedProject]);

  useEffect(() => {
    if (projectsOpen && selectRef.current) {
      selectRef.current.focus();
    }
  }, [projectsOpen]);

  const projectListRef = useRef(null);

  const handleClickOutside = (event) => {
    if (
      projectListRef.current &&
      !projectListRef.current.contains(event.target)
    ) {
      setProjectsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Set active menu item based on URL
  useEffect(() => {
    if (window.location.pathname === "/") {
      setActiveMenuItem(
        ["client", "owner", "reader", "editor"].includes(userInfo.role)
          ? "dashboard"
          : "todo",
      );
      return;
    }
    if (!props.isEmptyBar && !props.isUserSettings && !profileView) {
      const path = window.location.pathname;
      const pathParts = path.split("/");
      if (pathParts[1] === "report" || pathParts[1] === "create-report")
        pathParts[1] = "reports";
      const activeMenuItem = pathParts[1];
      setActiveMenuItem(activeMenuItem);
    }
  }, [userInfo]);

  useEffect(() => {
    if (
      userInfo &&
      ["client", "owner", "reader", "editor"].includes(userInfo.role) &&
      projects &&
      projects.length > 0
    ) {
      const img = new Image();
      img.onload = () => setProjectImageLoading(false);
      img.onerror = () => {
        console.error("Error loading image");
        setProjectImageLoading(false);
      };
      img.src = projects[0].imageLink;
    }

    if (userInfo && projects && projects.length === 0) {
      setProjectImageLoading(false);
    }
  }, [projects]);

  /*function onMouseEnter() {
    setMenuCollapsed(false);
  }

  function onMouseLeave() {
    setMenuCollapsed(true);
  }*/

  // Profile management sidebar items
  const sidebarItems = [
    {
      icon: (
        <i
          className="fa-light fa-mask fa-xl"
          style={{
            marginLeft: "36px",
            marginRight: "16.5px",
            fontSize: "20px",
          }}
        ></i>
      ),
      text: "Account & Profile",
      link: "/profile",
      blockedRoles: [],
    },
    {
      icon: (
        <i
          className="fa-light fa-hippo  fa-xl"
          style={{
            marginLeft: "35px",
            marginRight: "15px",
            fontSize: "20px",
          }}
        ></i>
      ),
      text: "Manage projects",
      link: "/manage-projects",
      blockedRoles: [],
    },
    {
      icon: (
        <i
          className="fa-light fa-bell  fa-xl"
          style={{
            marginLeft: "38.5px",
            marginRight: "19px",
            fontSize: "20px",
          }}
        ></i>
      ),
      text: "Notifications",
      link: "/notifications",
      blockedRoles: [],
    },
    {
      icon: (
        <i
          className="fa-light fa-lock  fa-xl"
          style={{
            marginLeft: "38.5px",
            marginRight: "19px",
            fontSize: "20px",
          }}
        ></i>
      ),
      text: "Authorizations",
      link: "/authorizations",
      blockedRoles: [],
    },
    {
      activeImg: (
        <img
          src={cursorBlue}
          alt="cursor"
          style={{
            width: "20px",
            height: "22px",
            marginLeft: "38x",
            marginRight: "16px",
          }}
        />
      ),
      inactiveImg: (
        <img
          src={cursorBlack}
          alt="cursor"
          style={{
            width: "20px",
            height: "22px",
            marginLeft: "38px",
            marginRight: "16px",
          }}
        />
      ),
      text: "Preferences",
      link: "/preferences",
      blockedRoles: [],
    },
    {
      icon: (
        <i
          className="fa-light fa-file-invoice  fa-xl"
          style={{
            marginLeft: "38.5px",
            marginRight: "19px",
            fontSize: "20px",
          }}
        ></i>
      ),
      text: "Payment Plan",
      link: "/plan-and-billings",
      blockedRoles: ["owner", "editor", "reader"],
    },
    {
      icon: (
        <i
          className="fa-light fa-book-section  fa-xl"
          style={{
            marginLeft: "38.5px",
            marginRight: "19px",
            fontSize: "20px",
          }}
        ></i>
      ),
      text: "Legal",
      link: "/legal",
      blockedRoles: [],
    },
  ];

  // This is the list of menu items that will be displayed in the sidebar
  const menuItems = [
    {
      name: "dashboard",
      label: "Dashboard",
      icon: dashboardLogo,
      iconInactive: dashboardLogoInactive,
      link: "/",
      isMobile: false,
      userRoles: ["client", "owner", "reader", "editor"],
      marginLeft: "8px",
      marginRight: "15px",
      hover: "Go to your Dashboard",
    },
    {
      name: "todo",
      label: "Todo",
      icon: todoLogoActive,
      iconInactive: todoLogoInactive,
      link: "/",
      isMobile: false,
      userRoles: ["designer"],
      marginLeft: "5px",
      marginRight: "20px",
      hover: "Go to your Todo list",
    },
    {
      name: "insights",
      label: "Insights",
      icon: lightbulbBlue,
      iconInactive: lightbulbBlack,
      mobileIconActive: projectsLogo,
      mobileIconInactive: projectsLogoInactive,
      link:
        userInfo?.role &&
        ["client", "owner", "reader", "editor"].includes(userInfo.role)
          ? "/insights/view"
          : "",
      isMobile: false,
      userRoles: ["client", "designer", "owner", "reader", "editor"],
      marginLeft: "14px",
      marginRight: "21px",
      hover: "Go to insights",
    },
    {
      name: "funnel and heatmap",
      label: "Funnel & Heatmap",
      icon: <i className="fa-light fa-fire-flame-curved  fa-2xl"></i>,
      link: "/funnels/view/",
      isMobile: false,
      marginLeft: "14px",
      marginRight: "21px",
      userRoles: ["client", "owner", "reader", "editor"],
      hover: "Go to Funnel & Heatmap",
      isIcon: true,
    },
    {
      name: "personas",
      label: "Personas",
      icon: <i className="fa-light fa-head-side-brain  fa-2xl"></i>,
      link: "/personas/summary/",
      isMobile: false,
      marginLeft: "11px",
      marginRight: "21px",
      userRoles: ["client", "owner", "reader", "editor"],
      hover: "Go to Personas",
      isIcon: true,
    },
    {
      name: "some_path",
      label: "Some Path",
      icon: voixLogo,
      iconInactive: voixLogo,
      link: "/some_path/",
      isMobile: true,
      userRoles: ["client", "designer", "owner", "reader", "editor"],
      hover: "Some path",
    },
    {
      name: "notifications",
      label: "Notifications",
      icon: notificationsLogo,
      iconInactive: notificationsLogoInactive,
      link: "/notifications/",
      isMobile: true,
      userRoles: ["client", "designer", "owner", "reader", "editor"],
      hover: "Go to your notifications",
    },
    {
      name: "approveAnalysis",
      label: "Approve client analysis requests",
      icon: <i className="fa-sharp fa-light fa-universal-access fs-28"></i>,
      link: "/",
      isMobile: false,
      userRoles: ["admin"],
      marginLeft: "8px",
      marginRight: "20px",
      hover: "Access & Plans",
      isIcon: true,
    },
    {
      name: "insights",
      label: "Insights",
      icon: lightbulbBlue,
      iconInactive: lightbulbBlack,
      link: "/manage-insights",
      isMobile: false,
      userRoles: ["admin"],
      marginLeft: "14px",
      marginRight: "21px",
      hover: "Manage Insights",
    },
    {
      name: "messages",
      label: "Messages",
      icon: <i className="fa-sharp fa-light fa-mailbox fs-28"></i>,
      link: "/messages",
      isMobile: false,
      userRoles: ["admin"],
      marginLeft: "8px",
      marginRight: "21px",
      hover: "Messages",
      isIcon: true,
    },
    // {
    //   name: "managePaymentPlans",
    //   label: "Manage Payment Plans",
    //   icon: <i className="fa-sharp fa-light fa-mailbox fs-28"></i>,
    //   link: "/under-construction",
    //   isMobile: false,
    //   userRoles: ["admin"],
    //   marginLeft: "8px",
    //   marginRight: "20px",
    //   hover: "Messages (Under construction)",
    //   isIcon: true,
    // },
  ];

  const selectBackground = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "var(--selected-background)"
        : "inherit",
      margin: "4px 0px",
    }),
  };

  const CustomOption = ({ innerProps, data }) => (
    <div {...innerProps} style={{ paddingLeft: "10px" }}>
      <img
        src={data.image}
        alt={data.label}
        className="project-dropdown-image img-keep-ratio"
      />
      <div
        className="fw-700 fs-16 lh-216 fc-black"
        style={{
          marginTop: "15px",
          maxWidth: "150px",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        {data.label}
      </div>
    </div>
  );

  const onSelect = (project) => {
    if (project.value === "create-new-project") {
      navigate("/create-project");
      return;
    }

    if (project) {
      setSelectedProject(project);
      if (project.myRole) {
        setUserInfo((prev) => ({ ...prev, role: project.myRole }));
      }
    }
  };

  const menuItemsFiltered = menuItems.filter((item) =>
    item.userRoles.includes(userInfo?.role ?? "client"),
  );

  const CustomNoOptionsMessage = (props) => {
    return null;
  };

  const getIsOnline = (parties) => {
    if (parties && userInfo?.uid && props.onlineChats) {
      const partnerId = parties.filter((party) => party !== userInfo.uid)[0];

      return props.onlineChats.includes(partnerId) ? true : false;
    }
    return false;
  };

  if (!isMobileView && !profileView) {
    if (userInfo && Object.keys(userInfo).length > 0) {
      return (
        <div>
          <div className="d-flex h-100">
            {props.isFunnel && (
              <Button
                className="d-flex justify-content-center align-items-center arrow-button"
                style={{
                  left: !props.hideFunnelFilters ? "100px" : "292px",
                  position: !props.hideFunnelFilters ? "fixed" : "absolute",
                  zIndex: 1,
                }}
                onClick={() => {
                  props.setHideFunnelFilters(true);
                }}
              >
                <img
                  src={!props.hideFunnelFilters ? arrowLeft : arrowRight}
                  key={!props.hideFunnelFilters ? arrowLeft : arrowRight}
                />
              </Button>
            )}

            <div>
              {projectsOpen && (
                <div className="v4-project-list" ref={projectListRef}>
                  {userInfo &&
                    ["client", "owner", "editor"].includes(userInfo.role) && (
                      <div
                        className="navigate-to-manage"
                        onClick={(e) => {
                          setProjectsOpen(false);
                          navigate(
                            `/manage-projects?pid=${selectedProject.value}`,
                          );
                        }}
                        style={{
                          cursor: "pointer",
                          color: "blue",
                          textDecoration: "underline",
                        }}
                      >
                        Manage project
                      </div>
                    )}

                  <Select
                    ref={selectRef}
                    options={projectDropdownOptions}
                    onChange={(project) => {
                      setProjectsOpen(false);
                      onSelect(project);
                    }}
                    value={selectedProject}
                    unstyled
                    menuIsOpen={true}
                    isSearchable={false}
                    styles={{ ...selectBackground }}
                    className="dropdown hidden-scrollbar"
                    classNames={{
                      placeholder: () => "projects-placeholder",
                      control: () =>
                        "expanded-projects-control d-flex align-items-center justify-content-between",
                      menu: () => "projects-menu",
                      option: () => "option",
                    }}
                    components={{
                      SingleValue: CustomOption,
                      Option: CustomOption,
                      NoOptionsMessage: CustomNoOptionsMessage,
                      DropdownIndicator: (props) => (
                        <components.DropdownIndicator {...props}>
                          <div style={{ display: "none" }}></div>
                        </components.DropdownIndicator>
                      ),
                    }}
                    onMenuOpen={() => setProjectsOpen(true)}
                    //onMenuClose={() => setProjectsOpen(false)}
                    defaultMenuIsOpen={true}
                  />
                  <div
                    className="fc-grey fw-500 fs-16 lh-216"
                    style={{
                      position: "relative",
                      paddingTop: "25px",
                      paddingLeft: "20px",
                    }}
                  >
                    {projectDropdownOptions.length > 1
                      ? "Other projects"
                      : "No other projects"}
                  </div>
                </div>
              )}
              <Sidebar
                style={{
                  overflowX: "visible",
                  overflowY: "auto",
                  position: "fixed",
                  height: "100%",
                  width: sidebarCollapsed ? "100px" : "340px",
                  zIndex: 10,
                  border: "none",
                  borderRight: props.isUxInsightsPage
                    ? "1px solid #E5E5E5"
                    : "none",
                  background: "#F7F7F7",
                }}
                className="hidden-scrollbar"
                breakPoint="sm"
                collapsed={sidebarCollapsed}
                collapsedWidth="100px"
              >
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip className="v4-tooltip">LUXIFER home</Tooltip>
                  }
                >
                  <a
                    onClick={() => {
                      setActiveMenuItem("dashboard");
                      navigate("/");
                      if (userInfo && userInfo.role === "admin") {
                        setAdminPage("manage");
                        setActiveMenuItem("insights");
                        window.scrollTo(0, 0);
                      }
                    }}
                    role="button"
                  >
                    <div
                      className="d-flex w-100"
                      style={{ position: "relative" }}
                    >
                      <img
                        src={luxiferLogo}
                        alt="logo"
                        style={{
                          width: "auto",
                          maxWidth: "180px",
                          height: "36px",
                          marginTop: "23px",
                          marginBottom: "23px",
                          marginLeft: "32px",
                        }}
                      />
                      <div
                        style={{
                          background: "#F7F7F7",
                          height: "36px",
                          width: "120px",
                          display: sidebarCollapsed ? "block" : "none",
                          position: "absolute",
                          left: "66px",
                          top: "22px",
                        }}
                      ></div>
                    </div>
                  </a>
                </OverlayTrigger>
                <div className="d-flex mt-3">
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip className="v4-tooltip">
                        Manage your account
                      </Tooltip>
                    }
                  >
                    <a
                      className="d-flex"
                      style={{
                        background: "none",
                        padding: "0px",
                        color: "black",
                      }}
                      href="/profile"
                    >
                      <div className="d-flex align-items-center">
                        <img
                          src={userInfo?.pfp ?? grayPfp}
                          alt="profile pic"
                          style={{
                            width: "60px",
                            height: "60px",
                            minWidth: "60px",
                            borderRadius: "50%",
                            marginLeft: "18px",
                            marginRight: "24px",
                          }}
                        />
                      </div>
                    </a>
                  </OverlayTrigger>
                </div>
                <div className="settings-chip">Settings</div>
                <hr
                  style={{
                    backgroundColor: "#D7D7D7",
                    margin: "32px 20px",
                  }}
                ></hr>
                {["client", "owner", "reader", "editor"].includes(
                  userInfo.role,
                ) && (
                  <div>
                    <div className={`w-100 mb-1`} style={{ marginTop: "34px" }}>
                      <h6
                        style={{
                          marginLeft: "23px",
                          fontWeight: "500",
                          fontSize: "14px",
                        }}
                      >
                        Projects
                      </h6>
                    </div>

                    {projectImageLoading ? (
                      <div className="sidebar-spinner-parent">
                        <Spinner style={{ width: "50px", height: "50px" }} />
                      </div>
                    ) : (
                      <div
                        className="d-flex w-100"
                        style={{
                          marginTop: "13px",
                          height: projects.length > 0 ? "62.25px" : "0px",
                        }}
                      >
                        <div
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          {sidebarCollapsed && projects.length > 0 && (
                            <OverlayTrigger
                              placement="right"
                              overlay={
                                <Tooltip className="v4-tooltip">
                                  Your projects
                                </Tooltip>
                              }
                            >
                              <img
                                className="img-keep-ratio"
                                onClick={() => {
                                  setProjectsOpen(!projectsOpen);
                                  if (selectRef.current) {
                                    selectRef.current.focus();
                                  }
                                }}
                                src={
                                  selectedProject
                                    ? selectedProject.image
                                    : projectsLogoInactive
                                }
                                alt="Current project image"
                                style={{
                                  height: "60px",
                                  width: "60px",
                                  borderRadius: "5px",
                                  marginLeft: "20px",
                                  marginRight: "35px",
                                }}
                              />
                            </OverlayTrigger>
                          )}
                        </div>
                        <div
                          className="d-flex align-items-center"
                          style={{
                            height: "53px",
                            width: "302px",
                            marginLeft: "20px",
                          }}
                        >
                          <div style={{ minWidth: "302px" }}></div>
                        </div>
                      </div>
                    )}
                    {!["reader", "owner", "editor"].includes(
                      userInfo?.role,
                    ) && (
                      <div>
                        <OverlayTrigger
                          placement="right"
                          overlay={
                            <Tooltip className="v4-tooltip">
                              Add a new project
                            </Tooltip>
                          }
                        >
                          <img
                            onClick={() => {
                              navigate("/create-project");
                            }}
                            src={newProject}
                            style={{
                              width: "60px",
                              height: "60px",
                              marginLeft: "19.06px",
                              marginTop: "14.31px",
                              cursor: "pointer",
                            }}
                            alt="Create new project"
                          ></img>
                        </OverlayTrigger>
                      </div>
                    )}
                  </div>
                )}
                <hr style={{ color: "#eeeeee" }} className="mx-4" />
                <h6
                  style={{
                    marginLeft: "20px",
                    marginTop: "36px",
                    marginBottom: "21px",
                  }}
                >
                  Overview
                </h6>
                <div className="d-flex">
                  <div>
                    {menuItemsFiltered.map((item) => {
                      return (
                        !item.isMobile && (
                          <div
                            key={item.name}
                            className="selected-tab"
                            style={{
                              background:
                                activeMenuItem === item.name
                                  ? "var(--main-blue)"
                                  : "inherit",
                            }}
                          />
                        )
                      );
                    })}
                  </div>
                  <Menu className="w-100">
                    {menuItemsFiltered.map(
                      (item) =>
                        !item.isMobile && (
                          <OverlayTrigger
                            key={item.name}
                            placement="right"
                            overlay={
                              <Tooltip className="v4-tooltip">
                                {item.hover}
                              </Tooltip>
                            }
                          >
                            <MenuItem
                              className="sidebar-menu-item"
                              active={activeMenuItem === item.name}
                              onClick={() => {
                                setActiveMenuItem(item.name);
                                props.handleChannelClick("null");
                              }}
                              component={<NavLink to={item.link} />}
                            >
                              <div
                                className="d-flex align-items-center"
                                style={{ height: "36px" }}
                              >
                                {item.isIcon ? (
                                  <div
                                    style={{
                                      marginLeft: item.marginLeft,
                                      marginRight: item.marginRight,
                                      height: "28px",
                                    }}
                                    className={
                                      activeMenuItem === item.name
                                        ? "fc-blue"
                                        : "fc-black"
                                    }
                                  >
                                    {item.icon}
                                  </div>
                                ) : (
                                  <img
                                    style={{
                                      marginLeft: item.marginLeft,
                                      marginRight: item.marginRight,
                                      height: "28px",
                                    }}
                                    alt={item.name}
                                    src={
                                      item.name === activeMenuItem
                                        ? item.icon
                                        : item.iconInactive
                                    }
                                  />
                                )}

                                {item.number >= 1 && sidebarCollapsed && (
                                  <div
                                    className="number-circle d-flex align-items-center justify-content-center"
                                    style={{
                                      position: "absolute",
                                      top: "-10px",
                                      right: "5px",
                                    }}
                                  >
                                    {item.number}
                                  </div>
                                )}
                                {!sidebarCollapsed && (
                                  <div className="d-flex align-items-center">
                                    <h6
                                      className={`expanded-menu-label${
                                        activeMenuItem === item.name
                                          ? "-active"
                                          : ""
                                      }`}
                                      style={{
                                        marginTop: "5px",
                                        marginLeft: "17px",
                                      }}
                                    >
                                      {item.label}{" "}
                                    </h6>
                                    {item.number >= 1 && (
                                      <div
                                        className="number-circle d-flex align-items-center justify-content-center"
                                        style={{ marginLeft: "20px" }}
                                      >
                                        {item.number}
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            </MenuItem>
                          </OverlayTrigger>
                        ),
                    )}
                  </Menu>
                </div>
                {userInfo.role !== "admin" && (
                  <>
                    <div
                      className="w-100 mb-1"
                      style={{ marginLeft: "20px", marginTop: "18px" }}
                    >
                      <h6>Messages</h6>
                    </div>
                    <div className="d-flex">
                      <div>
                        {props.userChannels.map((channel) => {
                          return (
                            <div
                              key={channel.id}
                              className="selected-channel"
                              style={{
                                background:
                                  props.openedChannel?.id === channel.id
                                    ? "var(--main-blue)"
                                    : "inherit",
                              }}
                            />
                          );
                        })}
                      </div>
                      <Menu>
                        {props.userChannels.map((channel) => (
                          <OverlayTrigger
                            key={channel.id}
                            placement="right"
                            overlay={
                              <Tooltip className="v4-tooltip">
                                Discuss with {channel.title}
                              </Tooltip>
                            }
                          >
                            <MenuItem
                              onClick={() => {
                                props.handleChannelClick(channel);
                                setActiveMenuItem(null);
                              }}
                              style={{ height: "100px", marginLeft: "-7px" }}
                            >
                              <div className="d-flex align-items-center chat-channel-link">
                                <img
                                  style={{ size: "60px" }}
                                  src={channel.icon}
                                  alt="channel"
                                />
                                <div
                                  style={{
                                    position: "absolute",
                                    width: "16px",
                                    height: "16px",
                                    background: getIsOnline(channel.parties)
                                      ? "#25A93A"
                                      : "#707070",
                                    transform: `translateX(44px) translateY(-20px)`,
                                    border: "2px solid white",
                                    borderRadius: "50%",
                                  }}
                                ></div>
                                {props.unreadMessagesArr[channel.id] > 0 && (
                                  <div
                                    style={{
                                      position: "absolute",
                                      width: "16px",
                                      height: "16px",
                                      background: "#2A00FF",
                                      transform: `translateX(44px) translateY(20px)`,
                                      border: "2px solid white",
                                      borderRadius: "50%",
                                      color: "white",
                                      fontSize: "10px",
                                      textAlign: "center",
                                      lineHeight: "13px",
                                    }}
                                  >
                                    {props.unreadMessagesArr[channel.id] > 99
                                      ? 99
                                      : props.unreadMessagesArr[channel.id]}
                                  </div>
                                )}

                                <div>
                                  {!sidebarCollapsed && (
                                    <h6
                                      className={`expanded-menu-label${
                                        props.openedChannel?.id === channel.id
                                          ? "-active"
                                          : ""
                                      }`}
                                      style={{
                                        maxWidth: "120px",
                                        overflow: "hidden",
                                        display: "-webkit-box",
                                        WebkitLineClamp: 3,
                                        WebkitBoxOrient: "vertical",
                                        textOverflow: "ellipsis",
                                        marginLeft: "20px",
                                        whiteSpace: "normal",
                                      }}
                                    >
                                      {channel.title}
                                    </h6>
                                  )}
                                </div>
                              </div>
                            </MenuItem>
                          </OverlayTrigger>
                        ))}
                      </Menu>
                    </div>
                  </>
                )}

                <div>
                  <hr
                    style={{
                      backgroundColor: "#D7D7D7",
                      margin: "14px 20px",
                    }}
                  ></hr>
                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip className="v4-tooltip">Logout</Tooltip>}
                  >
                    <button
                      className="logout-btn"
                      onClick={async () => {
                        await firebaseLogout(userInfo.uid);
                        setUserInfo({});
                        setSelectedProject({});
                        setUserChannels([]);
                        if (auth) {
                          await auth.signOut();
                        }
                        navigate("/");
                      }}
                    >
                      <span className="">
                        <div className="d-flex">
                          <img
                            style={{
                              height: "36px",
                              marginLeft: "12px",
                              marginRight: "42px",
                            }}
                            alt="Logout"
                            src={logoutBtn}
                          />
                          {!sidebarCollapsed && (
                            <div className="d-flex">
                              <h6
                                className="expanded-menu-label"
                                style={{ marginTop: "5px" }}
                              >
                                Logout
                              </h6>
                            </div>
                          )}
                        </div>
                      </span>
                    </button>
                  </OverlayTrigger>
                </div>
              </Sidebar>
              {props.isFunnel && (
                <div
                  className={
                    !props.hideFunnelFilters ? "" : "calendar-cutoff-fix"
                  }
                >
                  <Sidebar
                    style={{
                      overflowX: "visible",
                      overflowY: "auto",
                      position: "fixed",
                      height: "100%",
                      width: !props.hideFunnelFilters ? "0px" : "340px",
                      zIndex: 10,
                      border: "none",
                      borderLeft: !props.hideFunnelFilters
                        ? "1px solid transparent"
                        : "1px solid #E5E5E5",
                      background: "transparent",
                      left: "100px",
                    }}
                    className="hidden-scrollbar transparency-fix"
                    breakPoint="sm"
                    collapsed={!props.hideFunnelFilters}
                    collapsedWidth="0px"
                  >
                    <div
                      style={{
                        paddingLeft: "33px",
                        paddingRight: "33px",
                        paddingBottom: "300px",
                        overflow: "scroll",
                        width: "340px",
                        backgroundColor: "#F7F7F7",
                        overflowY: "scroll",
                        overflowX: "hidden",
                        height: "100%",
                        WebkitOverflowScrolling: "touch",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          overflow: "visible",
                        }}
                      >
                        <div
                          className="fs-20 fw-700 fc-black"
                          style={{ paddingTop: "114px", overflow: "visible" }}
                        >
                          Filters
                        </div>
                        <Button
                          className={`d-flex justify-content-center align-items-center arrow-button 
                              ${!props.hideFunnelFilters ? "d-none" : ""}`}
                          style={{
                            left: !props.hideFunnelFilters ? "0px" : "20px",
                            position: !props.hideFunnelFilters
                              ? "fixed"
                              : "relative",
                            width: "150px",
                          }}
                          onClick={() => {
                            props.setHideFunnelFilters(false);
                          }}
                        >
                          <img
                            src={
                              !props.hideFunnelFilters ? arrowLeft : arrowRight
                            }
                            key={
                              !props.hideFunnelFilters ? arrowLeft : arrowRight
                            }
                          />
                          <div
                            className="fc-black fs-16 fw-700"
                            style={{ width: "100px" }}
                          >
                            Close filters
                          </div>
                        </Button>
                      </div>
                      <FunnelAnalysisSidebarContent
                        selectedProject={selectedProject}
                        userInfo={userInfo}
                        heatmapOrFunnel={heatmapOrFunnel}
                        setHeatmapFunnelFilters={setHeatmapFunnelFilters}
                        setHideFunnelFilters={props.setHideFunnelFilters}
                        heatmapFunnelFilters={heatmapFunnelFilters}
                        heatmapPrefsLoaded={heatmapPrefsLoaded}
                        setHeatmapPrefsLoaded={setHeatmapPrefsLoaded}
                        {...props}
                      />
                    </div>
                  </Sidebar>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="d-flex h-100">
          <Sidebar
            style={{
              height: "100vh",
              zIndex: 2,
              position: "fixed",
              border: "none",
            }}
            backgroundColor="black"
            breakPoint="sm"
            collapsed={sidebarCollapsed}
            collapsedWidth="100px"
          >
            <a href="/">
              <div className="d-flex w-100">
                <img
                  src={luxiferLogoWhite}
                  alt="logo"
                  style={{
                    width: "133.4px",
                    height: "32px",
                    marginLeft: "35.8px",
                    marginTop: "35.8px",
                  }}
                />
              </div>
            </a>
            {auth.currentUser && (
              <>
                <button
                  className="logout-btn-light"
                  onClick={() => {
                    // UserInfo and selectedProject are already empty
                    auth.signOut();
                    navigate("/");
                  }}
                >
                  <span className="">
                    <div className="d-flex">
                      <img
                        style={{
                          height: "36px",
                          marginLeft: "15px",
                          marginRight: "42px",
                        }}
                        alt="Logout"
                        src={logoutBtnWhite}
                      />
                    </div>
                  </span>
                </button>
              </>
            )}
          </Sidebar>
        </div>
      );
    }
  } else if (isMobileView) {
    // Mobile sidebar
    return (
      <div className="mobile-sidebar d-flex align-items-center justify-content-center">
        {menuItemsFiltered.map((item) => {
          return (
            <Link
              className="mobile-sidebar-button"
              onClick={() => setActiveMenuItem(item.name)}
              to={item.link}
              key={item.name}
            >
              <img
                style={{
                  height: "32px",
                }}
                alt={item.name}
                src={
                  item.name === activeMenuItem
                    ? (item?.mobileIconActive ?? item.icon)
                    : (item?.mobileIconInactive ?? item.iconInactive)
                }
              />
            </Link>
          );
        })}
      </div>
    );
  } else if (profileView) {
    return (
      <div
        style={{
          height: "100vh",
          minWidth: hideSidebarText ? "96px" : "340px",
        }}
        className="profile-sidebar"
      >
        <div style={{ height: "71px" }}>
          <a onClick={() => navigate("/")} role="button">
            {!hideSidebarText ? (
              <img
                src={luxiferLogo}
                alt="logo"
                style={{
                  width: "auto",
                  maxWidth: "133.4px",
                  height: "32px",
                  marginTop: "31.5px",
                  marginLeft: "19.53px",
                }}
              />
            ) : (
              <img
                src={luxiferLogoSmall}
                alt="logo"
                style={{
                  width: "auto",
                  maxWidth: "30px",
                  height: "30px",
                  marginTop: "31.5px",
                  marginLeft: "34px",
                }}
              />
            )}
          </a>
        </div>
        <div
          style={{
            height: "40px",
            paddingLeft: hideSidebarText ? "17px" : "20px",
            paddingBottom: hideSidebarText ? "59.78px" : "49.78px",
            paddingTop: hideSidebarText ? "44.4px" : "54.4px",
          }}
          className="d-flex flex-row"
        >
          <img
            src={userInfo?.pfp ?? grayPfp}
            alt="profile pic"
            className="modal-image flex-column"
            style={{
              width: hideSidebarText ? "60px" : "40px",
              height: hideSidebarText ? "60px" : "40px",
            }}
          />
          {!hideSidebarText && (
            <div
              style={{ marginLeft: "19px" }}
              className="flex-column profile-text-container fs-18 fw-500 fc-grey lh-20"
            >
              <p>Connecté en tant que</p>
              <p
                className="fw-700"
                style={{
                  maxWidth: "200px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {userInfo?.firstName ?? "User"}.
              </p>
            </div>
          )}
        </div>

        <div>
          {sidebarItems.map(
            (item, index) =>
              !item.blockedRoles.includes(userInfo.role) && (
                <NavLink
                  style={{
                    height: "32px",
                    marginBottom: "22px",
                    display: "flex",
                    alignItems: "center",
                  }}
                  key={index}
                  className={`checklist-page-item d-flex flex-row profile-sidebar-fix profile-sidebar-link ${
                    selectedPage === item.link
                      ? "custom-profile-active-border"
                      : ""
                  }`}
                  to={item.link}
                >
                  {item?.icon ? (
                    <div
                      className={` ${
                        selectedPage === item.link ? "" : "fc-black"
                      }`}
                    >
                      {item.icon}
                    </div>
                  ) : (
                    <div>
                      {selectedPage === item.link
                        ? item.activeImg
                        : item.inactiveImg}
                    </div>
                  )}

                  {!hideSidebarText && (
                    <div
                      className={` flex-column fs-18 lh-20 ${
                        selectedPage === item.link
                          ? "expanded-menu-label-active"
                          : "expanded-menu-label"
                      }`}
                      style={{
                        display: "block",
                        textAlign: "left",
                        paddingTop: "2px",
                      }}
                    >
                      {item.text}
                    </div>
                  )}
                </NavLink>
              ),
          )}
        </div>
      </div>
    );
  }
}

export default CustomSidebar;
