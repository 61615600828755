import CustomSidebar from "../components/CustomSidebar/CustomSidebar";
import Chatbar from "../components/Chatbar/Chatbar";
import { useState } from "react";

export default function MainLayout({
  userChannels,
  handleChannelClick,
  openedChannel,
  setOpenedChannel,
  setIsChatbarOpen,
  isChatbarOpen,
  children,
  isEmptyBar,
  setSelectedProject,
  setUserInfo,
  activeMenuItem,
  setActiveMenuItem,
  sidebarCollapsed,
  setSidebarCollapsed,
  isUserSettings,
  hideSidebar,
  profileView,
  onlineChats,
  unreadMessagesArr,
  setUserChannels,
  isFunnel,
  hideFunnelFilters,
  setHideFunnelFilters,
  setAdminPage,
  setHeatmapFunnelFilters,
  heatmapFunnelFilters,
  heatmapOrFunnel,
  heatmapPrefsLoaded,
  setHeatmapPrefsLoaded,
}) {
  return (
    <div className={hideSidebar ? "" : "d-flex"}>
      <CustomSidebar
        setSidebarCollapsed={setSidebarCollapsed}
        sidebarCollapsed={sidebarCollapsed}
        setSelectedProject={setSelectedProject}
        userChannels={userChannels}
        handleChannelClick={handleChannelClick}
        openedChannel={openedChannel}
        activeMenuItem={activeMenuItem}
        setActiveMenuItem={setActiveMenuItem}
        isEmptyBar={isEmptyBar}
        setUserInfo={setUserInfo}
        isUserSettings={isUserSettings}
        hideSidebar={hideSidebar}
        profileView={profileView}
        onlineChats={onlineChats}
        unreadMessagesArr={unreadMessagesArr}
        setUserChannels={setUserChannels}
        isFunnel={isFunnel}
        hideFunnelFilters={hideFunnelFilters}
        setHideFunnelFilters={setHideFunnelFilters}
        setAdminPage={setAdminPage}
        setHeatmapFunnelFilters={setHeatmapFunnelFilters}
        heatmapFunnelFilters={heatmapFunnelFilters}
        heatmapOrFunnel={heatmapOrFunnel}
        heatmapPrefsLoaded={heatmapPrefsLoaded}
        setHeatmapPrefsLoaded={setHeatmapPrefsLoaded}
      />
      <div
        className={
          hideSidebar ? "" : `main-parent${hideFunnelFilters ? " reduced" : ""}`
        }
      >
        {children}
      </div>
    </div>
  );
}
