import { useState } from "react";
import { Spinner } from "react-bootstrap";

const RecordingFrame = ({ data }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <div id="widgetIframe">
      {!isLoaded && (
        <div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "100px",
              position: "absolute",
            }}
          >
            <Spinner style={{ height: "70px", width: "70px" }} />
          </div>
        </div>
      )}
      <iframe
        src={`https://analytics.luxifer.app/index.php?module=Widgetize&action=iframe&moduleToWidgetize=HeatmapSessionRecording&actionToWidgetize=replayRecording${data}&token_auth=c512ee37096158190b15952ee65118b7`}
        width="100%"
        height="500px"
        frameBorder="0"
        allowFullScreen
        title="Matomo Session Recording"
        id="matomo-frame"
        name="matomo-frame"
        onLoad={() => setIsLoaded(true)}
        style={{ visibility: isLoaded ? "visible" : "hidden" }}
      ></iframe>
    </div>
  );
};

export default RecordingFrame;
