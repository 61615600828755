import { auth, db } from "../../firebase.js";
import {
  collection,
  doc,
  onSnapshot,
  setDoc,
  updateDoc,
  arrayUnion,
  arrayRemove,
  deleteDoc,
} from "firebase/firestore";
import { useCallback, useContext, useEffect, useState } from "react";
import { IoIosAttach } from "react-icons/io";
import exitIcon from "../../assets/exit.svg";
import "./Chatbar.css";
import { Button, Spinner } from "react-bootstrap";
import { Menu } from "react-pro-sidebar";
import { IsMobileContext, UserInfoContext } from "../../contexts";
import { useDropzone } from "react-dropzone";
import {
  dateReactToFirestore,
  sendAdminMessageNotiication,
  uploadFileToFirestore,
} from "../../helpers";
import documentPreviewIcon from "../../assets/document_preview.svg";
import send from "../../assets/paper-plane.png";
import Modal from "react-modal";
import { BsXCircle } from "react-icons/bs";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { FiArrowLeft } from "react-icons/fi";
import grayPfp from "../../assets/gray.PNG";
import { useMemo, useRef } from "react";

const ChatbarView = (props) => {
  const validPreviewExtensions = [".png", ".jpg", ".jpeg", ".gif", ".svg"];

  const userInfo = useContext(UserInfoContext);
  const [channelMessages, setChannelMessages] = useState([]);
  const [message, setMessage] = useState("");
  const [messageFiles, setMessageFiles] = useState([]);
  const [activeFileDrag, seActiveFileDrag] = useState(false);

  const [channelDoc, setChannelDoc] = useState(null);

  const [userIsTyping, setUserIsTyping] = useState(false);
  const [otherIsTyping, setOtherIsTyping] = useState(false);

  const navigate = useNavigate();

  const [chatBarHeight, setChatBarHeight] = useState(152);
  const [messagesAlreadyLoaded, setMessagesAlreadyLoaded] = useState(false);
  const divRef = useRef(null);

  const [hoverOnMessage, setHoverOnMessage] = useState(null);
  const [messageDropdownOpen, setMessageDropdownOpen] = useState(false);
  const [isEditingMessage, setIsEditingMessage] = useState(false);
  const [idToEdit, setIdToEdit] = useState(null);

  const [replyingTo, setReplyingTo] = useState(null);
  const [replyToPadding, setReplyToPadding] = useState(0);

  // Used to autofocus the textarea programatically
  const textareaRef = useRef(null);

  const [loading, setLoading] = useState(true);

  const [currentTarget, setCurrentTarget] = useState(null);
  const [flipMessageOptions, setFlipMessageOptions] = useState(false);

  const chatRef = useRef(null);

  const [shouldEmailAdmin, setShouldEmailAdmin] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      if (!currentTarget || !chatRef.current || !messageDropdownOpen) return;

      const { top: clickedTop } = currentTarget.getBoundingClientRect();
      const { top: chatTop, bottom: chatBottom } =
        chatRef.current.getBoundingClientRect();
      const relativeTop = clickedTop - chatTop;

      setFlipMessageOptions(
        relativeTop >= 200 && chatBottom - relativeTop - 350 < 0,
      );
    };

    const divElement = chatRef.current;
    if (divElement) {
      divElement.addEventListener("scroll", handleScroll);
      return () => {
        divElement.removeEventListener("scroll", handleScroll);
      };
    }
  }, [currentTarget, messageDropdownOpen]);

  const invisibleAnchorRef = useRef(null);

  useEffect(() => {
    invisibleAnchorRef.current.scrollIntoView({ behavior: "auto" });
  }, [channelMessages]);

  /**
   * This function will be called when the user drops files into the dropzone or selects files via the file input
   * @param acceptedFiles - the files that were dropped or selected
   */
  function queueFiles(acceptedFiles) {
    setMessageFiles((prevState) => [
      ...prevState,
      ...acceptedFiles.map((file) =>
        Object.assign(file, { preview: URL.createObjectURL(file) }),
      ),
    ]);
  }

  const uploadFileViaButton = useCallback((acceptedFiles) => {
    const uploadedFiles = acceptedFiles.target.files;
    if (uploadedFiles.length > 0) queueFiles(Array.from(uploadedFiles));
  }, []);

  /**
   * Handlers for drag and drop into the chatbar.
   */

  const onDrop = useCallback((acceptedFiles) => {
    onDragLeave();
    queueFiles(acceptedFiles);
  }, []);

  const onDragEnter = useCallback(() => {
    seActiveFileDrag(true);
  }, []);

  const onDragLeave = useCallback(() => {
    seActiveFileDrag(false);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDragEnter,
    onDragLeave,
    onDrop,
  });
  /**
   * This will be called when a different channel is selected, and will update the chatbar messages in real time.
   */
  useEffect(() => {
    setLoading(true);
    resetMessage();
    setMessage("");
    if (!props?.openedChannel?.id) {
      return;
    }
    const channelRef = doc(db, "channels", props.openedChannel.id);

    if (channelRef && auth?.currentUser?.uid) {
      updateDoc(channelRef, {
        typingUsers: arrayRemove(auth?.currentUser?.uid),
      });
    }
    let unsubscribeMessages;
    let unsubscribeChannel;
    if (props.userChannels?.length && props.openedChannel) {
      unsubscribeMessages = onSnapshot(
        collection(doc(db, "channels", props.openedChannel.id), "messages"),
        (snapshot) => {
          let messageData = [];
          snapshot.forEach((_message) => {
            messageData.push({ id: _message.id, ..._message.data() });
          });
          messageData.sort((a, b) => b.created - a.created);
          setChannelMessages(messageData);
          setLoading(false);
        },
      );

      unsubscribeChannel = onSnapshot(
        doc(db, "channels", props.openedChannel.id),
        (channelDoc) => {
          setChannelDoc(channelDoc.data());
        },
      );
    }

    // Cleanup function
    return () => {
      if (unsubscribeMessages) unsubscribeMessages();
      if (unsubscribeChannel) unsubscribeChannel();
    };
  }, [props.openedChannel, props.userChannels]);

  useEffect(() => {
    if (channelMessages) {
      updateLastReadTime();
      resetUnreadMessages(auth.currentUser.uid);
    }
  }, [channelMessages]);

  async function updateLastReadTime() {
    if (props?.openedChannel?.id) {
      const channelRef = doc(db, "channels", props.openedChannel.id);
      if (!channelDoc || !channelRef) return;

      // If the map lastRead does not exist, create it
      if (!channelDoc.lastRead) {
        const updatePayload = {
          lastRead: {
            [auth.currentUser.uid]: Date.now(),
          },
        };
        updateDoc(channelRef, updatePayload);
      } else {
        const updatedLastRead = { ...channelDoc.lastRead };
        updatedLastRead[auth.currentUser.uid] = Date.now();
        const updatePayload = { lastRead: updatedLastRead };
        updateDoc(channelRef, updatePayload);
      }
    }
  }

  // When user textarea changes, update the typing indicator
  useEffect(() => {
    if (!props?.openedChannel?.id) return;

    const channelRef = doc(db, "channels", props.openedChannel.id);
    if (!channelDoc || !channelRef) return;

    const isTyping = channelDoc?.typingUsers?.includes(auth.currentUser.uid);

    if (auth.currentUser.uid) {
      if (message && !isTyping) {
        updateDoc(channelRef, {
          typingUsers: arrayUnion(auth?.currentUser?.uid),
        });
      } else if (!message && isTyping) {
        updateDoc(channelRef, {
          typingUsers: arrayRemove(auth?.currentUser?.uid),
        });
      }
    }

    const textarea = document.getElementById("msgcontent");
    if (textarea) {
      textarea.style.height = "auto"; // Temporarily reset height to calculate scrollHeight accurately
      const scrollHeight = textarea.scrollHeight;
      const maxHeight = parseInt(getComputedStyle(textarea).maxHeight);

      let newHeight = scrollHeight;
      if (newHeight > maxHeight) {
        newHeight = maxHeight; // Ensure newHeight does not exceed maxHeight
      }

      textarea.style.height = newHeight + "px"; // Set the new height based on content
      setChatBarHeight(newHeight + 152 - 20); // Adjust chat bar height accordingly
    }

    if (message === "") {
      setIsEditingMessage(false);
      setIdToEdit(null);
    }
  }, [message]);

  // On page load remove typing indicator for user
  useEffect(() => {
    if (props?.openedChannel?.id && auth?.currentUser?.uid) {
      updateDoc(doc(db, "channels", props.openedChannel.id), {
        typingUsers: arrayRemove(auth?.currentUser?.uid),
      });
    }

    // When the user leaves chat, remove the typing indicator for them
    return () => {
      if (props?.openedChannel?.id && auth?.currentUser?.uid) {
        updateDoc(doc(db, "channels", props.openedChannel.id), {
          typingUsers: arrayRemove(auth?.currentUser?.uid),
        });
      }
    };
  }, []);

  useEffect(() => {
    if (props?.openedChannel?.id && channelDoc) {
      setUserIsTyping(channelDoc?.typingUsers?.includes(auth.currentUser.uid));
      setOtherIsTyping(
        channelDoc?.typingUsers?.length === 2 ||
          (channelDoc?.typingUsers?.length === 1 &&
            !channelDoc?.typingUsers?.includes(auth.currentUser.uid)),
      );
    }
  }, [channelDoc]);

  const truncateFileName = (filename) => {
    return filename.length >= 21 ? filename.substring(0, 19) + "..." : filename;
  };

  const BouncingDotsLoader = () => {
    return (
      <>
        <div className="bouncing-loader" style={{ marginTop: "54px" }}>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </>
    );
  };

  const hasLoomURL = (message) => {
    return message.includes("https://www.loom.com/share/");
  };

  const isOnlyLoom = (message) => {
    return (
      message.body.message.includes("https://www.loom.com/share/") &&
      message.body.message.split(/\s+/).length === 1 &&
      message.body.files.length === 0
    );
  };

  const getLoomEmbed = (message) => {
    const words = message.split(/\s+/);
    const loomWord = words.filter((word) =>
      word.startsWith("https://www.loom.com/share/"),
    )[0];
    return loomWord
      ? loomWord.replace(
          "https://www.loom.com/share/",
          "https://www.loom.com/embed/",
        )
      : "";
  };

  const incrementUnreadMessages = async () => {
    if (props?.openedChannel?.id && props?.openedChannel?.parties) {
      const otherUid = props.openedChannel.parties.filter(
        (party) => party !== auth.currentUser.uid,
      )[0];

      const channelRef = doc(db, "channels", props.openedChannel.id);
      if (!channelDoc || !channelRef) return;

      // If the map lastRead does not exist, create it
      if (!channelDoc.newMessagesCount) {
        const updatePayload = {
          newMessagesCount: {
            [otherUid]: 1,
          },
        };
        updateDoc(channelRef, updatePayload);
      } else {
        const updatedNewMessagesCount = { ...channelDoc.newMessagesCount };
        updatedNewMessagesCount[otherUid] =
          (isNaN(updatedNewMessagesCount[otherUid])
            ? 0
            : updatedNewMessagesCount[otherUid]) + 1;
        const updatePayload = { newMessagesCount: updatedNewMessagesCount };
        updateDoc(channelRef, updatePayload);
      }
    }
  };

  const resetUnreadMessages = async (uid) => {
    if (props?.openedChannel?.id) {
      const channelRef = doc(db, "channels", props.openedChannel.id);
      if (!channelDoc || !channelRef) return;
      const updatedNewMessagesCount = {
        ...channelDoc.newMessagesCount,
        [uid]: 0,
      };
      await updateDoc(channelRef, {
        newMessagesCount: updatedNewMessagesCount,
      });
    }
  };

  const updateLatestMessageTime = async () => {
    if (props?.openedChannel?.id && props?.openedChannel?.parties) {
      const channelRef = doc(db, "channels", props.openedChannel.id);
      if (!channelDoc || !channelRef) return;

      const updatePayload = {
        latestMessage: dateReactToFirestore(new Date()),
      };
      updateDoc(channelRef, updatePayload);
    }
  };

  /**
   * This function will be called when the user clicks the send button or presses enter.
   * It will upload the files to firestore and create a new message document.
   */
  const createNewMessage = async () => {
    setChatBarHeight(152);
    const tempMessageFiles = messageFiles;
    const tempMessage = message;

    setMessageFiles([]);
    setMessage("");
    const messagesRef = collection(
      doc(db, "channels", props.openedChannel.id),
      "messages",
    );

    const newDocRef = doc(messagesRef);

    const files = [];
    for (const file of tempMessageFiles) {
      files.push({
        url: await uploadFileToFirestore(
          "channels/" + props.openedChannel.id + "/messages/" + newDocRef.id,
          file,
        ),
        size: file.size,
      });
    }

    const now = Date.now();

    let newMessage = {
      body: {
        message: tempMessage,
        files: files,
      },
      created: now,
      uid: userInfo.uid,
    };

    if (replyingTo && replyingTo.id) {
      newMessage.replyingTo = replyingTo.id;
    }

    await setDoc(newDocRef, newMessage);
    await updateLatestMessageTime();

    if (
      props?.chatPartnerStatus !== "online" &&
      shouldEmailAdmin &&
      userInfo.role !== "admin"
    ) {
      setShouldEmailAdmin(false);
      sendAdminMessageNotiication(userInfo.firstName);
    }
  };

  /**
   * This function will format the size of the file, which will be displayed to the user.
   * @param bytes - the size of the file in bytes, taken from the file object
   * @param decimals - how many decimal places, defaults to 2.
   * @returns {string} - formatted string for the size (e.g. 1.23 MB)
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    const formattedSize = parseFloat(
      (bytes / Math.pow(k, i)).toFixed(decimals),
    );

    return `${formattedSize} ${sizes[i]}`;
  }

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [imgSrc, setImgSrc] = useState(null);

  const handleOpenModal = (imgSrc) => {
    setImgSrc(imgSrc);
    setIsModalOpen(true);
  };

  /**
   * This is the modal that will be displayed when the user clicks on an image in the chatbar.
   * @type {JSX.Element}
   */
  const modalContent = (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={() => setIsModalOpen(false)}
      contentLabel="Image Modal"
      appElement={document.getElementById("root")}
      style={{
        overlay: { zIndex: 10 },
        content: {
          width: "50%",
          height: "75%",
          margin: "auto",
        },
      }}
      className={"custom-modal modal-shape"}
    >
      <button onClick={() => setIsModalOpen(false)} className="icon-button">
        <BsXCircle alt="X button" size="30px" color="#1F1F24" />
      </button>
      <div className="modal-image-container">
        <img src={imgSrc} alt={"image"} />
      </div>
    </Modal>
  );

  /**
   * This function will be called when the user clicks on a file in the chatbar, and will open the file in a new tab.
   * @param file - the file object that was clicked on
   * @returns {(function(): void)|*}
   */
  const handleDownload = (file) => () => {
    const fileExtension = file.url.split(".").pop().toLowerCase().split("?")[0];
    const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp"];
    if (imageExtensions.includes(fileExtension)) {
      handleOpenModal(file.url);
    } else {
      // Regular download
      const anchor = document.createElement("a");
      anchor.href = file.url;
      anchor.target = "_blank";
      anchor.rel = "noopener noreferrer";
      anchor.download = file.name;

      anchor.click();
    }
  };

  const pfp = (img, offset, isOnline) => {
    if (!isOnline) {
      isOnline = false;
    }
    return (
      <div
        style={{
          position: "relative",
        }}
      >
        <img
          src={img ?? grayPfp}
          alt="profile pic"
          style={{
            width: "60px",
            height: "60px",
            minWidth: "60px",
            borderRadius: "50%",
            transform: `translateX(${-offset}px)`,
          }}
        />
        <div
          style={{
            position: "absolute",
            width: "16px",
            height: "16px",
            background: isOnline ? "#25A93A" : "#707070",
            transform: `translateX(calc(${-offset}px + 44px)) translateY(-60px)`,
            border: "2px solid white",
            borderRadius: "50%",
          }}
        ></div>
      </div>
    );
  };

  // Return 1 if chat partner, -1 if user, 0 otherwise
  const calcMessageClumpPfp = (sender, index) => {
    if (sender) {
      if (
        channelMessages[index + 1]?.uid !== (userInfo?.uid ?? "") ||
        index === channelMessages.length - 1
      ) {
        return -1;
      }
    }
    if (!sender) {
      if (
        channelMessages[index + 1]?.uid === (userInfo?.uid ?? "") ||
        index === channelMessages.length - 1
      ) {
        return 1;
      }
    }
    return 0;
  };

  const calcMessageClumpTimestamp = (sender, index) => {
    if (sender) {
      if (
        channelMessages[index - 1]?.uid !== (userInfo?.uid ?? "") ||
        index === 0
      ) {
        return -1;
      }
    }
    if (!sender) {
      if (
        channelMessages[index - 1]?.uid === (userInfo?.uid ?? "") ||
        index === 0
      ) {
        return 1;
      }
    }
    return 0;
  };

  const renderIsTyping = (img, name) => {
    return (
      <>
        <div className="fw-700 fs-14 lh-196 d-flex align-items-center">
          {img}
          <div className="truncate-name">{name}</div>
          <div style={{ marginLeft: "0.25em" }}>is typing</div>
        </div>
        <div
          className="d-flex align-items-center"
          style={{
            marginLeft: "20px",
            marginRight: "20px",
          }}
        >
          <BouncingDotsLoader />
        </div>
      </>
    );
  };

  const renderBothTyping = () => {
    return (
      <>
        <div className="fw-700 fs-14 lh-196 d-flex align-items-center">
          {pfp(userInfo?.pfp, 20, true)}
          {pfp(props?.openedChannel?.icon, 20, true)}
          <div className="truncate-name">{userInfo?.firstName},</div>
          <div style={{ marginLeft: "0.25em" }} className="truncate-name">
            {props?.openedChannel?.title},
          </div>
          <div style={{ marginLeft: "0.25em" }}>are typing</div>
        </div>
        <div
          className="d-flex align-items-center"
          style={{
            marginLeft: "20px",
            marginRight: "20px",
          }}
        >
          <BouncingDotsLoader />
        </div>
      </>
    );
  };

  function ReplyMessage({ replyingTo, userInfo }) {
    if (!replyingTo) return null;

    const sender = replyingTo.uid === (userInfo?.uid ?? "");

    return (
      <div
        onClick={() => {
          const element = document.getElementById(replyingTo.id);
          if (element) {
            element.scrollIntoView({ behavior: "smooth" });
          }
        }}
        className="d-flex"
        style={{ marginBottom: "12px" }}
      >
        <div
          className={`message-reply ${
            sender ? "message-recipient" : "message-author"
          }`}
          style={{
            boxShadow: sender
              ? "0px 0px 2px 2px white"
              : "0px 0px 2px 2px white",
          }}
        >
          <div>
            {replyingTo.body.files.length !== 0 && (
              <div>
                {replyingTo.body.files.map((file, idx) => {
                  if (idx > 0) {
                    return null;
                  }
                  const filenameWithoutParams = file.url.replace(/\?.*$/, "");
                  return (
                    <div
                      key={idx}
                      className={`send-chatbar-media-container ${
                        sender ? "media-recipient" : "media-author"
                      }`}
                      style={{ cursor: "pointer" }}
                    >
                      <div className="preview-div">
                        <img
                          alt={"file preview"}
                          src={
                            validPreviewExtensions.some((extension) =>
                              filenameWithoutParams.endsWith(extension),
                            )
                              ? file.url
                              : documentPreviewIcon
                          }
                        />
                      </div>
                      <div
                        className={`no-url${sender ? "-recipient" : "-author"}`}
                      >
                        <div className="information-div">
                          {truncateFileName(
                            decodeURI(
                              filenameWithoutParams.replace(/^.*(%2F|\/)/, ""),
                            ),
                          )}
                          <h3>{formatBytes(file.size)}</h3>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
            <div
              className="message-reply-text"
              style={{ color: sender ? "black" : "white" }}
            >
              {Array.isArray(replyingTo.body.message) ? (
                <div>
                  {replyingTo.body.message.map((item, index) => (
                    <span key={index}>{item} </span>
                  ))}
                </div>
              ) : (
                <div style={{ whiteSpace: "nowrap" }}>
                  {replyingTo.body.message}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  const userTypingIndicator = useMemo(
    () => renderIsTyping(pfp(userInfo?.pfp, 20, true), userInfo?.firstName),
    [userInfo],
  );

  const otherTypingIndicator = useMemo(
    () =>
      renderIsTyping(
        pfp(props?.openedChannel?.icon, 20, true),
        props?.openedChannel?.title,
      ),
    [props.openedChannel],
  );

  const bothTypingIndicator = useMemo(() => renderBothTyping(), [userInfo]);

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const weekday = date.toLocaleDateString("en-US", { weekday: "long" });
    const day = date.toLocaleDateString("en-US", { day: "numeric" });
    const month = date.toLocaleDateString("en-US", { month: "short" });
    const time = date.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });

    return `${weekday} ${day} ${month}, ${time}`;
  };

  const resetMessage = () => {
    setReplyToPadding(0);
    setIdToEdit(null);
    setReplyingTo(null);
    setMessageDropdownOpen(null);
    setHoverOnMessage(null);
    setIsEditingMessage(false);
  };

  const handleMessageEditClick = (message) => {
    resetMessage();
    setMessage("");
    setIsEditingMessage(true);
    setIdToEdit(message.id);
    if (textareaRef.current) {
      textareaRef.current.focus();
    }
    if (message.body.message.length < 1000) {
      if (Array.isArray(message.body.message)) {
        setMessage(message.body.message.join("\n"));
      } else {
        setMessage(message.body.message);
      }
    }
  };

  const handleSaveEditedMessage = () => {
    if (idToEdit) {
      if (props.openedChannel.id && idToEdit) {
        updateDoc(
          doc(
            collection(db, "channels", props.openedChannel.id, "messages"),
            idToEdit,
          ),
          { "body.message": message },
        );
      }
    }
  };

  const handleMessageDeleteClick = (id) => {
    resetMessage();
    setMessage("");

    if (props.openedChannel.id && id) {
      deleteDoc(
        doc(collection(db, "channels", props.openedChannel.id, "messages"), id),
      );
    }
  };

  const handleMessageReplyClick = (message) => {
    resetMessage();
    setMessage("");
    if (textareaRef.current) {
      textareaRef.current.focus();
    }

    // Find the message in channelMessages with the same id as message.id
    const foundMessage = channelMessages.find((m) => m.id === message.id);
    if (foundMessage) {
      // Creating a soft copy of foundMessage
      let messageCopy = { ...foundMessage, body: { ...foundMessage.body } };

      let replyToPadding = 76;
      if (messageCopy.body.files && messageCopy.body.files.length > 0) {
        replyToPadding += 62;
      }
      if (
        messageCopy.body.message === "" &&
        messageCopy.body.files.length === 1
      ) {
        replyToPadding -= 18;
      }

      if (messageCopy.body.files.length > 1) {
        messageCopy.body.message = `+${
          messageCopy.body.files.length - 1
        } more files ${messageCopy.body.message}`;
      }

      setReplyingTo(messageCopy);
      setReplyToPadding(replyToPadding);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      const isDropdownClick = event.target.closest(
        ".message-options, .hover-box",
      );

      if (!isDropdownClick && messageDropdownOpen !== null) {
        setMessageDropdownOpen(null);
        setHoverOnMessage(null);
        return;
      }
    };

    if (messageDropdownOpen !== null) {
      document.addEventListener("click", handleClickOutside);
    }

    return () => document.removeEventListener("click", handleClickOutside);
  }, [messageDropdownOpen]);

  return (
    <div>
      {isModalOpen && modalContent}
      <div
        style={{
          height: "100%",
          width: "100%",
          background: "white",
          border: "none",
          marginTop: "-85px",
        }}
      >
        <div
          style={{
            position: "absolute",
            bottom: `${chatBarHeight + replyToPadding + 48}px`,
            width: "100%",
            left: "0",
            transition: "0.3s",
          }}
        >
          <hr></hr>
        </div>

        <Menu
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            width: "100%",
            justifyContent: "flex-end",
            overflow: "visible",
          }}
        >
          <div
            style={{
              direction: "ltr",
            }}
          >
            <Link to={"/"}>
              <div
                style={{
                  width: "158px",
                  height: "24px",
                  textAlign: "center",
                  marginTop: "37px",
                  marginLeft: "-16px",
                }}
                className="fs-14 fw-700 fc-black"
                onClick={() => {
                  props.setActiveMenuItem("dashboard");
                  props.handleChannelClick("null");
                }}
              >
                <FiArrowLeft
                  color="#000"
                  size="24px"
                  style={{ marginRight: "10px", marginBottom: "2px" }}
                />
                Back to Dashboard
              </div>
            </Link>
            <div style={{ marginLeft: "-10px", marginTop: "23px" }}>
              <hr></hr>
            </div>

            <div
              className="fw-700 fs-24 lh-324"
              style={{ marginTop: "50px", marginLeft: "-16px" }}
            >
              Message
            </div>
            <div {...getRootProps()} className="d-flex flex-column">
              {activeFileDrag ? (
                <div className="file-drop-chat-overlay">
                  <div className="file-drop-full-page-overlay__text">
                    Drop anywhere to upload
                  </div>
                </div>
              ) : null}

              <div
                ref={chatRef}
                style={{
                  marginTop: "5px",
                  maxHeight: `calc(100vh - ${
                    230 + chatBarHeight + replyToPadding
                  }px)`,
                  minHeight: `calc(100vh - ${
                    230 + chatBarHeight + replyToPadding
                  }px)`,
                  overflowY: "scroll",
                  overflowX: "hidden",

                  marginLeft: "-100px",
                  paddingLeft: "100px",
                  paddingRight: "66px",
                  marginRight: "-80px",
                  paddingBottom: "50px",
                  transition: "0.3s",
                }}
              >
                <div
                  ref={divRef}
                  style={{
                    maxWidth: "1020px",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column-reverse",
                    height: "100%",
                  }}
                >
                  <div ref={invisibleAnchorRef} id="invisible-anchor"></div>

                  {loading ? (
                    <div
                      style={{
                        height: "100%",
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Spinner
                        style={{
                          height: "70px",
                          width: "70px",
                          marginTop: "20%",
                        }}
                      />
                    </div>
                  ) : (
                    channelMessages.map((message, i) => {
                      const sender = message.uid === (userInfo?.uid ?? "");
                      const showPfp = calcMessageClumpPfp(sender, i);
                      const showTimestamp = calcMessageClumpTimestamp(
                        sender,
                        i,
                      );
                      const loom = hasLoomURL(message.body.message);
                      const onlyLoom = loom && isOnlyLoom(message);
                      const replyOf =
                        channelMessages.find(
                          (m) => m.id === message.replyingTo,
                        ) ?? null;
                      return (
                        <div key={i} id={message.id}>
                          {!onlyLoom && (
                            <div
                              className={`justify-content-${
                                sender ? "start" : "end"
                              } message-container d-flex`}
                              style={{ overflow: "visible" }}
                              key={message.id}
                            >
                              {showPfp == -1 && pfp(userInfo?.pfp, 20, true)}
                              <div>
                                <div
                                  onClick={() => {
                                    setHoverOnMessage(message.id);
                                  }}
                                  onMouseEnter={() => {
                                    if (!hoverOnMessage) {
                                      setHoverOnMessage(message.id);
                                    }
                                  }}
                                  onMouseLeave={() => {
                                    if (!messageDropdownOpen) {
                                      setHoverOnMessage(null);
                                    }
                                  }}
                                  className={`message ${
                                    sender
                                      ? "message-recipient"
                                      : "message-author"
                                  }`}
                                  style={{
                                    marginLeft: showPfp == -1 ? "0px" : "60px",
                                    marginRight: showPfp == 1 ? "0px" : "60px",
                                  }}
                                >
                                  {hoverOnMessage === message.id && (
                                    <div
                                      style={{ position: "relative" }}
                                      onClick={(event) => {
                                        setMessageDropdownOpen(message.id);
                                        setCurrentTarget(event.currentTarget);
                                        const { top: clickedTop } =
                                          event.currentTarget.getBoundingClientRect();
                                        const {
                                          top: chatTop,
                                          bottom: chatBottom,
                                        } =
                                          chatRef.current.getBoundingClientRect();
                                        const relativeTop =
                                          clickedTop - chatTop;

                                        setFlipMessageOptions(
                                          relativeTop >= 200 &&
                                            chatBottom - relativeTop - 350 < 0,
                                        );
                                      }}
                                    >
                                      <div className="hover-box">
                                        <div className="dots">
                                          <span></span>
                                          <span></span>
                                          <span></span>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                  {messageDropdownOpen === message.id && (
                                    <div
                                      style={{
                                        position: "relative",
                                      }}
                                    >
                                      <div
                                        style={{
                                          top:
                                            flipMessageOptions && sender
                                              ? "-172px"
                                              : "32px",
                                        }}
                                        className={`${
                                          sender
                                            ? "message-options"
                                            : "message-options-flip"
                                        } fs-16 fc-black lh-216 fw-500`}
                                      >
                                        {sender && (
                                          <>
                                            <div
                                              onClick={() =>
                                                handleMessageEditClick(message)
                                              }
                                              className="message-options-item"
                                            >
                                              <i className="fa-regular fa-pen fs-20"></i>
                                              <span
                                                style={{ paddingLeft: "13px" }}
                                              >
                                                Edit
                                              </span>
                                            </div>
                                            <div
                                              onClick={() =>
                                                handleMessageDeleteClick(
                                                  message.id,
                                                )
                                              }
                                              className="message-options-item"
                                            >
                                              <i className="fa-regular fa-trash fs-20"></i>
                                              <span
                                                style={{ paddingLeft: "15px" }}
                                              >
                                                Delete
                                              </span>
                                            </div>
                                          </>
                                        )}

                                        <div
                                          onClick={() => {
                                            handleMessageReplyClick(message);
                                          }}
                                          className="message-options-item"
                                        >
                                          <i className="fa-regular fa-reply fs-20"></i>
                                          <span style={{ paddingLeft: "12px" }}>
                                            Reply
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                  <ReplyMessage
                                    replyingTo={replyOf}
                                    userInfo={userInfo}
                                  />
                                  {message.body.files.length !== 0 && (
                                    <div>
                                      {message.body.files.map((file, idx) => {
                                        const filenameWithoutParams =
                                          file.url.replace(/\?.*$/, "");
                                        return (
                                          <div
                                            key={idx}
                                            className={`send-chatbar-media-container ${
                                              sender
                                                ? "media-recipient"
                                                : "media-author"
                                            }`}
                                            onClick={handleDownload(file)}
                                            style={{ cursor: "pointer" }}
                                          >
                                            <div className="preview-div">
                                              <img
                                                alt={"file preview"}
                                                src={
                                                  validPreviewExtensions.some(
                                                    (extension) =>
                                                      filenameWithoutParams.endsWith(
                                                        extension,
                                                      ),
                                                  )
                                                    ? file.url
                                                    : documentPreviewIcon
                                                }
                                              />
                                            </div>
                                            <div
                                              className={`no-url${
                                                sender
                                                  ? "-recipient"
                                                  : "-author"
                                              }`}
                                            >
                                              <div className="information-div">
                                                {truncateFileName(
                                                  decodeURI(
                                                    filenameWithoutParams.replace(
                                                      /^.*(%2F|\/)/, // Removes any firestore encoded characters from the filename
                                                      "",
                                                    ),
                                                  ),
                                                )}

                                                <h3>
                                                  {formatBytes(file.size)}
                                                </h3>
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  )}
                                  {Array.isArray(message.body.message) ? (
                                    message.body.message.map((item, index) => (
                                      <div key={index}>{item}</div>
                                    ))
                                  ) : (
                                    <div style={{ whiteSpace: "pre-wrap" }}>
                                      {message.body.message}
                                    </div>
                                  )}
                                </div>
                              </div>
                              {showPfp == 1 &&
                                pfp(
                                  props?.openedChannel?.icon,
                                  -20,
                                  props?.chatPartnerStatus === "online",
                                )}
                            </div>
                          )}

                          <div>
                            {loom && (
                              <div
                                className={`justify-content-${
                                  sender ? "start" : "end"
                                } message-container d-flex`}
                                style={{ overflow: "visible" }}
                                key={message.id}
                              >
                                {showPfp == -1 && pfp(userInfo?.pfp, 20, true)}
                                <div
                                  style={{
                                    width: "270px",
                                    height: "180px",
                                    marginLeft: !sender
                                      ? "0px"
                                      : showPfp == -1
                                        ? "0px"
                                        : "60px",
                                    marginRight: sender
                                      ? "0px"
                                      : showPfp == 1
                                        ? "0px"
                                        : "60px",
                                    marginTop: "12px",
                                  }}
                                >
                                  <iframe
                                    style={{ width: "270px", height: "180px" }}
                                    className={`justify-content-${
                                      sender ? "start" : "end"
                                    } rounded-iframe`}
                                    src={getLoomEmbed(message.body.message)}
                                    title="YouTube Video"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                  ></iframe>
                                </div>
                              </div>
                            )}
                          </div>
                          <div>
                            {(showTimestamp == 1 || showTimestamp == -1) && (
                              <div
                                style={{
                                  textAlign: sender ? "start" : "end",
                                  paddingLeft: !sender ? "0px" : "60px",
                                  marginRight: sender ? "0px" : "60px",
                                  marginTop: "12px",
                                }}
                                className="fw-500 fs-14 lh-189 fc-grey"
                              >
                                {formatTimestamp(message.created)}
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })
                  )}
                </div>
              </div>
              <div
                style={{
                  position: "absolute",
                  bottom: "28px",
                  pointerEvents: "none",
                }}
              >
                <div
                  className="p-0"
                  style={{
                    height: "50px",
                    marginLeft: "0px",
                    pointerEvents: "none",
                  }}
                >
                  {(userIsTyping || otherIsTyping) && (
                    <div
                      className="align-items-center"
                      style={{
                        display: "inline-flex",
                        pointerEvents: "none",
                        background: "white",
                        borderRadius: "16px",
                        height: "50px",
                        transform: `translateY(${
                          -(chatBarHeight + replyToPadding) + 152 - 32
                        }px)`,
                        transition: "transform 0.3s",
                      }}
                    >
                      {userIsTyping && !otherIsTyping && userTypingIndicator}
                      {otherIsTyping && !userIsTyping && otherTypingIndicator}
                      {userIsTyping && otherIsTyping && bothTypingIndicator}
                    </div>
                  )}
                </div>
                <div
                  style={{
                    background: "white",
                    height: "184px",
                    paddingTop: "0.1px",
                    width: "900px",
                    transform: "translateY(3px) translateX(-20px)",
                  }}
                >
                  <div
                    style={{
                      marginTop: "32px",
                      marginLeft: "64px",
                      minWidth: "850px",
                      maxWidth: "900px",
                      position: "absolute",
                      bottom: "0",
                      height: `${chatBarHeight + replyToPadding ?? 0}px`,
                      minHeight: "152px",
                      pointerEvents: "auto",
                      transform: "translateX(20px)",
                    }}
                    className="send-chatbar"
                  >
                    <div
                      style={{
                        position: "absolute",
                        transform: "translateX(-83px) translateY(-20px)",
                      }}
                    >
                      {pfp(userInfo?.pfp, 20, true)}
                    </div>
                    <ReplyMessage replyingTo={replyingTo} userInfo={userInfo} />
                    <div className="align-items-center d-flex">
                      <input {...getInputProps()} />
                      <textarea
                        ref={textareaRef}
                        rows="1"
                        value={message}
                        placeholder="Start typing your message here...."
                        id="msgcontent"
                        name="msgcontent"
                        className="chatbox-input"
                        onChange={(e) => {
                          if (e.target.value.length < 1000) {
                            setMessage(e.target.value);
                          }
                        }}
                        onKeyDown={async (e) => {
                          if (
                            e.key === "Enter" &&
                            !e.shiftKey &&
                            (message || messageFiles.length !== 0)
                          ) {
                            e.preventDefault();
                            if (isEditingMessage) {
                              setIsEditingMessage(false);
                              setIdToEdit(null);
                              setMessage("");
                              handleSaveEditedMessage();
                            } else {
                              await createNewMessage();
                              resetMessage();
                              incrementUnreadMessages();
                            }
                          }
                        }}
                      />
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        bottom: "22px",
                      }}
                    >
                      <button
                        className="send-chatbar-button2 fw-700 fs-16 lh-2371"
                        onClick={async () => {
                          if (isEditingMessage) {
                            setIsEditingMessage(false);
                            setIdToEdit(null);
                            setMessage("");
                            handleSaveEditedMessage();
                          } else {
                            await createNewMessage();
                            resetMessage();
                            incrementUnreadMessages();
                          }
                        }}
                        disabled={
                          /^\s*$/.test(message) && messageFiles.length === 0
                        }
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {isEditingMessage ? "Edit" : "Send"}
                          {isEditingMessage ? (
                            <i
                              style={{ marginLeft: "16px" }}
                              className="fa-regular fa-pen fs-20"
                            ></i>
                          ) : (
                            <img
                              src={send}
                              alt="Paper plane"
                              style={{ height: "20px", marginLeft: "12px" }}
                              className=""
                            ></img>
                          )}
                        </div>
                      </button>
                      {isEditingMessage && (
                        <button
                          style={{ height: "50px", marginLeft: "10px" }}
                          className="btn-secondary fw-700 fs-16 lh-2371"
                          onClick={() => {
                            resetMessage();
                            setMessage("");
                          }}
                          disabled={
                            /^\s*$/.test(message) && messageFiles.length === 0
                          }
                        >
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            Cancel
                          </div>
                        </button>
                      )}
                      {replyingTo && (
                        <button
                          style={{ height: "50px", marginLeft: "10px" }}
                          className="btn-secondary fw-700 fs-16 lh-2371"
                          onClick={() => {
                            resetMessage();
                            setMessage("");
                          }}
                        >
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            Cancel
                          </div>
                        </button>
                      )}

                      <input // all filetypes allowed
                        type="file"
                        id="attachFile"
                        style={{ display: "none" }}
                        onChange={uploadFileViaButton}
                      />

                      {!isEditingMessage && (
                        <IoIosAttach
                          role="button"
                          onClick={() =>
                            document.getElementById("attachFile").click()
                          }
                          size="23px"
                          style={{
                            marginLeft: "28px",
                            marginTop: "13.5px",
                            position: "absolute",
                          }}
                        />
                      )}

                      <div
                        style={{
                          minHeight: "11px",
                          marginBottom: "32px",
                          position: "absolute",
                          bottom: "-40px",
                          left: replyingTo ? "290px" : "200px",
                        }}
                        className="d-flex flex-row"
                      >
                        {messageFiles.map((file, idx) => {
                          const isFile = !validPreviewExtensions.some(
                            (extension) => file.name.endsWith(extension),
                          );
                          return (
                            <div
                              key={idx}
                              className="send-chatbar-image-container"
                            >
                              <img
                                alt={file.name}
                                height="54px"
                                width="54px"
                                src={
                                  isFile ? documentPreviewIcon : file.preview
                                }
                              />
                              {isFile && (
                                <div className="send-chatbar-image-container-text">
                                  <h5>{file.name}</h5>
                                </div>
                              )}
                              <Button
                                onClick={() => {
                                  setMessageFiles((prevState) =>
                                    prevState.filter((_, i) => i !== idx),
                                  );
                                }}
                              >
                                X
                              </Button>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Menu>
      </div>
      {props.isChatbarOpen && (
        <Button
          className="d-flex align-items-center justify-content-center exit-button"
          onClick={() => props.setIsChatbarOpen(false)}
        >
          <img
            style={{
              size: "12px",
            }}
            src={exitIcon}
            alt="X"
          />
        </Button>
      )}
    </div>
  );
};

export default function Chatbar(props) {
  const navigate = useNavigate();
  const isMobileView = useContext(IsMobileContext);
  if (!isMobileView) {
    if (!props.openedChannel && props.room !== "admin") {
      navigate("/");
    }
    return (
      <div>
        <div
          style={{
            width: "100%",
            height: "100%",
          }}
        >
          <ChatbarView
            userChannels={props.userChannels}
            openedChannel={props.openedChannel}
            isChatbarOpen={props.isChatbarOpen}
            setIsChatbarOpen={props.setIsChatbarOpen}
            setActiveMenuItem={props.setActiveMenuItem}
            handleChannelClick={props.handleChannelClick}
            chatPartnerStatus={props.chatPartnerStatus}
          />
        </div>
      </div>
    );
  }
}
