import React, { useEffect, useState } from "react";
import "./FunnelAnalysisSidebarContent.css";
import CustomDropdown from "../CustomDropdown/CustomDropdown";
import { MdCheckCircle } from "react-icons/md";
import { GiCircle } from "react-icons/gi";
import { components } from "react-select";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import CustomCalendar from "../CustomCalendar/CustomCalendar";
import { OverlayTrigger, Tooltip, Spinner } from "react-bootstrap";
import {
  getMatomoHeatmapDropdownFields,
  saveHeatmapFiltersToFirestore,
  getHeatmapFiltersFromFirestore,
} from "../../helpers";

export default function FunnelAnalysisSidebarContent({
  userInfo,
  selectedProject,
  heatmapOrFunnel,
  setHeatmapFunnelFilters,
  heatmapFunnelFilters,
  heatmapPrefsLoaded,
  setHeatmapPrefsLoaded,
  ...props
}) {
  const [selectedDateText, setSelectedDateText] = useState("7 day range");
  const today = new Date();
  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(today.getDate() - 7);

  const [dateRange, setDateRange] = useState([sevenDaysAgo, today]);
  const [startDate, endDate] = dateRange;

  const [selectedDate, setSelectedDate] = useState(null);
  const [datepickerOpen, setDatepickerOpen] = useState(false);

  const [goalOptions, setGoalOptions] = useState([
    { label: "Conversion", value: "c" },
    { label: "Purchase", value: "c" },
  ]);
  const [tagOptions, setTagOptions] = useState([]);

  const [goal, setGoal] = useState(null);
  const [tag, setTag] = useState(null);
  const [pathType, setPathType] = useState("success");
  const [heatmap, setHeatmap] = useState(false);
  const [clicks, setClicks] = useState(false);
  const [scroll, setScroll] = useState(false);
  const [hesitations, setHesitations] = useState(false);
  const [insights, setInsights] = useState(false);
  const [frustrations, setFrustrations] = useState(false);
  const [phone, setPhone] = useState(false);
  const [desktop, setDesktop] = useState(true);
  const [tablet, setTablet] = useState(false);
  const [unknownDevice, setUnknownDevice] = useState(false);
  const [country, setCountry] = useState(null);

  const [goalCollapsed, setGoalCollapsed] = useState(false);
  const [quickLearnCollapsed, setQuickLearnCollapsed] = useState(false);
  const [segmentsCollapsed, setSegmentsCollapsed] = useState(false);

  const [goalOptionsOpen, setGoalOptionsOpen] = useState(false);
  const [tagOptionsOpen, setTagOptionsOpen] = useState(false);

  const [countryOptions, setCountryOptions] = useState([
    { label: "Belgium", value: "Belgium" },
    { label: "France", value: "France" },
    { label: "Canada", value: "Canada" },
    { label: "Germany", value: "Germany" },
  ]);
  const [countryOptionsOpen, setCountryOptionsOpen] = useState(false);

  const [duration, setDuration] = useState(null);
  const [durationOptionsOpen, setDurationOptionsOpen] = useState(false);
  const [durationOptions, setDurationOptions] = useState([
    { label: "All", value: null },
    { label: "0~30 seconds", value: "0~30" },
    { label: "30~60 seconds", value: "30~60" },
    { label: "1~5 minutes", value: "60~300" },
    { label: "5~30 minutes", value: "300~1800" },
    { label: "Over 30 minutes", value: "1800~999999" },
  ]);

  const [referrer, setReferrer] = useState(null);
  const [referrerOptionsOpen, setReferrerOptionsOpen] = useState(false);
  const [referrerOptions, setReferrerOptions] = useState([
    { label: "Direct", value: "direct" },
    { label: "Search", value: "search" },
    { label: "External Website", value: "website" },
  ]);

  const [formUse, setFormUse] = useState(null);
  const [formUseOptionsOpen, setFormUseOptionsOpen] = useState(false);
  const [formUseOptions, setFormUseOptions] = useState([
    { label: "No activity", value: "No activity" },
    { label: "Started", value: "Started" },
    {
      label: "Did not submit",
      value: "Did not submit",
    },
    { label: "Submitted", value: "Submitted" },
  ]);

  const [deviceBrand, setDeviceBrand] = useState(null);
  const [deviceBrandOptionsOpen, setDeviceBrandOptionsOpen] = useState(false);
  const [deviceBrandOptions, setDeviceBrandOptions] = useState([]);

  const [browser, setBrowser] = useState(null);
  const [browserOptionsOpen, setBrowserOptionsOpen] = useState(false);
  const [browserOptions, setBrowserOptions] = useState([]);

  const [resolution, setResolution] = useState(null);
  const [resolutionOptionsOpen, setResolutionOptionsOpen] = useState(false);
  const [resolutionOptions, setResolutionOptions] = useState([]);

  const [city, setCity] = useState(null);
  const [cityOptionsOpen, setCityOptionsOpen] = useState(false);
  const [cityOptions, setCityOptions] = useState([]);
  const [countryCitiesMap, setCountryCitiesMap] = useState({});

  const [localTime, setLocalTime] = useState(null);
  const [localTimeOptionsOpen, setLocalTimeOptionsOpen] = useState(false);
  const [behaviorDisplay, setbehaviorDisplay] = useState(false);

  const [registrationDate, setRegistrationDate] = useState(null);

  useEffect(() => {
    setHeatmapPrefsLoaded(false);
    setTagOptions(selectedProject?.goals);
    setTag(selectedProject?.goals?.[0] ?? null);
    try {
      populateMatomoSidebarOptions();
    } catch (error) {}

    if (userInfo?.uid && selectedProject) {
      const pid = selectedProject.value ?? selectedProject.id;
      loadHeatmapPrefs(userInfo.uid, pid);
    }

    if (selectedProject?.registrationDate) {
      const firestoreTimestamp = selectedProject.registrationDate;
      if (firestoreTimestamp?.seconds) {
        const convertedDate = new Date(firestoreTimestamp.seconds * 1000);
        setRegistrationDate(convertedDate);
      }
    }
  }, [selectedProject]);

  async function loadHeatmapPrefs(uid, pid) {
    const savedPrefs = await getHeatmapFiltersFromFirestore(uid, pid);
    if (savedPrefs) {
      const filters = {
        tag: savedPrefs.tag,
        pathType: savedPrefs.pathType,
        heatmap: savedPrefs.heatmap,
        scroll: savedPrefs.scroll,
        hesitations: savedPrefs.hesitations,
        insights: savedPrefs.insights,
        frustrations: savedPrefs.frustrations,
        phone: savedPrefs.phone,
        desktop: savedPrefs.desktop,
        tablet: savedPrefs.tablet,
        country: savedPrefs.country,
        duration: savedPrefs.duration,
        localTime: savedPrefs.localTime,
        browser: savedPrefs.browser,
        referrer: savedPrefs.referrer,
        deviceBrand: savedPrefs.deviceBrand,
        resolution: savedPrefs.resolution,
        formUse: savedPrefs.formUse,
        city: savedPrefs.city,
      };

      setTag(savedPrefs.tag);
      setPathType(savedPrefs.pathType);
      setHeatmap(savedPrefs.heatmap);
      setScroll(savedPrefs.scroll);
      setHesitations(savedPrefs.hesitations);
      setInsights(savedPrefs.insights);
      setFrustrations(savedPrefs.frustrations);
      setPhone(savedPrefs.phone);
      setDesktop(savedPrefs.desktop);
      setTablet(savedPrefs.tablet);
      setCountry(savedPrefs.country);
      setDuration(savedPrefs.duration);
      setLocalTime(savedPrefs.localTime);
      setBrowser(savedPrefs.browser);
      setReferrer(savedPrefs.referrer);
      setDeviceBrand(savedPrefs.deviceBrand);
      setResolution(savedPrefs.resolution);
      setFormUse(savedPrefs.formUse);
      setCity(savedPrefs.city);
      filters.startDate = startDate;
      filters.endDate = endDate;

      if (
        !savedPrefs.heatmap &&
        !savedPrefs.frustrations &&
        !savedPrefs.scroll &&
        !savedPrefs.hesitations
      ) {
        setHeatmap(true);
        filters.heatmap = true;
      }

      setHeatmapFunnelFilters(filters);
    }
    setHeatmapPrefsLoaded(true);
  }

  function uniqueBehaviorToggleHandler(b, state) {
    setFrustrations(b.label === "Frustrations" && state);
    setScroll(b.label === "Scroll" && state);
    setHesitations(b.label === "Hesitations" && state);
    setHeatmap(b.label === "Heatmap & clicks" && state);
  }

  const generateTimeOptions = () => {
    const options = [];
    options.push({ label: "All", value: null });
    for (let i = 0; i < 24; i++) {
      const start = i.toString().padStart(2, "0") + ":00";
      const end = ((i + 1) % 24).toString().padStart(2, "0") + ":00";
      options.push({
        label: `${start}~${end}`,
        value: `${i}~${i + 1 < 24 ? i + 1 : 0}`,
      });
    }
    return options;
  };
  const [localTimeOptions, setLocalTimeOptions] = useState(
    generateTimeOptions(),
  );

  const populateCountryOptions = async () => {
    const country_info = await fetch(
      "https://countriesnow.space/api/v0.1/countries",
    );
    const country_data = await country_info.json();
    setCountryOptions(
      country_data.data.map((country) => {
        return {
          value: country.iso2,
          label: country.country,
        };
      }),
    );

    const countryCitiesMapTemp = {};
    for (const { country, cities } of country_data.data) {
      countryCitiesMapTemp[country] = cities.map((city) => {
        return { label: city, value: city };
      });
    }

    setCountryCitiesMap(countryCitiesMapTemp);
  };

  useEffect(() => {
    const cityOptions = [];
    if (!country) {
      return;
    }
    for (const c of country) {
      if (c?.label) {
        cityOptions.push(...countryCitiesMap[c.label]);
      }
    }
    setCityOptions(cityOptions.slice(0, 2000));
  }, [country]);

  const createCustomMultiValue = (type) => (props) => {
    return <CustomMultiValue {...props} items={type} />;
  };

  async function populateMatomoSidebarOptions() {
    let fields;
    try {
      fields = await getMatomoHeatmapDropdownFields({
        params: {
          siteId: selectedProject.matomoId,
        },
      });
    } catch (error) {
      return;
    }

    if (fields.deviceBrands && fields.deviceBrands.length > 0) {
      const options = fields.deviceBrands.map((field) => ({
        label: field,
        value: field,
      }));
      setDeviceBrandOptions(options);
    }
    if (fields.browsers && fields.browsers.length > 0) {
      const options = fields.browsers.map((field) => ({
        label: field,
        value: field,
      }));
      setBrowserOptions(options);
    }
    if (fields.resolutions && fields.resolutions.length > 0) {
      const options = fields.resolutions.map((field) => ({
        label: field,
        value: field,
      }));
      setResolutionOptions(options);
    }
  }

  useEffect(() => {
    populateCountryOptions();
  }, []);

  const getBehaviorDisplay = () => {
    if (heatmapOrFunnel === "funnel") {
      return "none";
    } else if (heatmapOrFunnel === "heatmap") {
      return "flex";
    }
    return "block"; // Default value if neither condition is met
  };

  async function saveFiltersToFirestoreHelper(filters) {
    const filteredFilters = Object.entries(filters)
      .filter(
        ([key, value]) =>
          value !== null &&
          value !== undefined &&
          key !== "desktop" &&
          key !== "phone" &&
          key !== "tablet" &&
          key !== "startDate" &&
          key !== "endDate",
      )
      .reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {});

    filteredFilters.deviceType = desktop
      ? "desktop"
      : phone
        ? "phone"
        : "tablet";

    const pid = selectedProject.value ?? selectedProject.id;

    if (userInfo?.uid && pid) {
      await saveHeatmapFiltersToFirestore(filteredFilters, userInfo.uid, pid);
    }
  }

  useEffect(() => {
    const filters = {
      startDate,
      endDate,
      goal,
      tag,
      pathType,
      heatmap,
      clicks,
      scroll,
      hesitations,
      insights,
      frustrations,
      phone,
      desktop,
      tablet,
      country,
      unknownDevice,
      duration,
      localTime,
      browser,
      referrer,
      deviceBrand,
      resolution,
      formUse,
      city,
    };
    setHeatmapFunnelFilters(filters);
    if (heatmapPrefsLoaded && tag) {
      saveFiltersToFirestoreHelper(filters);
    }
  }, [
    startDate,
    endDate,
    goal,
    tag,
    pathType,
    heatmap,
    clicks,
    scroll,
    hesitations,
    insights,
    frustrations,
    phone,
    desktop,
    tablet,
    country,
    unknownDevice,
    duration,
    localTime,
    browser,
    referrer,
    deviceBrand,
    resolution,
    formUse,
    city,
  ]);

  const CustomMultiValue = ({ data, items }) => {
    const additionalSelected = items.length - 2;
    return (
      items[0]?.value === data.value && (
        <div>
          {items.slice(0, 2).map((option, index) => (
            <span key={option.value}>
              {option.label}
              {index < items.length - 1 ? ", " : null}
            </span>
          ))}
          {items.length > 2 ? `+ ${additionalSelected} others` : null}
        </div>
      )
    );
  };

  const checkCircle = (props) => (
    <components.Option {...props}>
      <div style={{ display: "flex" }}>
        {props.isSelected ? (
          <MdCheckCircle size={24} color="var(--main-blue)" />
        ) : (
          <GiCircle size={24} color="var(--main-blue)" />
        )}
        <h5
          style={{
            marginBottom: "0",
            marginLeft: "10px",
          }}
          className="vhc"
        >
          {props.data.label}
        </h5>
      </div>
    </components.Option>
  );

  const behaviors = [
    {
      label: "Heatmap & clicks",
      state: heatmap,
      setState: setHeatmap,
      color: "#2A00FF",
    },
    // {
    //   label: "Clicks / Touchs",
    //   state: clicks,
    //   setState: setClicks,
    //   color: "#0f0c22",
    // },
    {
      label: "Frustrations",
      state: frustrations,
      setState: setFrustrations,
      color: "#27DAB7",
    },
    {
      label: "Scroll",
      state: scroll,
      setState: setScroll,
      color: "#2A00FF",
    },
    {
      label: "Hesitations",
      state: hesitations,
      setState: setHesitations,
      color: "#EB0D35",
    },
    {
      label: "Insights",
      state: insights,
      setState: setInsights,
      color: "#FFC900",
    },
  ];

  const Dropdown = ({
    isMulti = false,
    hideSelectedOptions = false,
    closeMenuOnSelect = false,
    blurInputOnSelect = false,
    isClearable = false,
    unstyled = true,
    placeholder,
    options,
    onChange,
    value,
    menuOpenState,
    setMenuOpenState,
    components,
  }) => (
    <div style={{ marginTop: "20px" }}>
      <CustomDropdown
        isMulti={isMulti}
        hideSelectedOptions={hideSelectedOptions}
        closeMenuOnSelect={closeMenuOnSelect}
        blurInputOnSelect={blurInputOnSelect}
        isClearable={isClearable}
        unstyled={unstyled}
        placeholder={placeholder}
        options={options}
        onChange={onChange}
        value={value ?? null}
        menuOpenState={menuOpenState}
        setMenuOpenState={setMenuOpenState}
        components={components}
      />
    </div>
  );

  const dropdownConfigs = [
    {
      isMulti: true,
      placeholder: "Country",
      options: countryOptions,
      onChange: setCountry,
      value: country,
      menuOpenState: countryOptionsOpen,
      setMenuOpenState: setCountryOptionsOpen,
      components: {
        MultiValue: createCustomMultiValue(country),
        Option: checkCircle,
      },
    },
    {
      isMulti: true,
      placeholder: "City (Select a country first)",
      options: cityOptions,
      onChange: setCity,
      value: city,
      menuOpenState: cityOptionsOpen,
      setMenuOpenState: setCityOptionsOpen,
      components: {
        MultiValue: createCustomMultiValue(city),
        Option: checkCircle,
      },
    },
    {
      placeholder: "Visit duration",
      options: durationOptions,
      onChange: setDuration,
      value: duration,
      menuOpenState: durationOptionsOpen,
      setMenuOpenState: setDurationOptionsOpen,
    },
    {
      placeholder: "Local time of entrance",
      options: localTimeOptions,
      onChange: setLocalTime,
      value: localTime,
      menuOpenState: localTimeOptionsOpen,
      setMenuOpenState: setLocalTimeOptionsOpen,
    },
    {
      isMulti: true,
      placeholder: "Browser",
      options: browserOptions,
      onChange: setBrowser,
      value: browser,
      menuOpenState: browserOptionsOpen,
      setMenuOpenState: setBrowserOptionsOpen,
      components: {
        MultiValue: createCustomMultiValue(browser),
        Option: checkCircle,
      },
    },
    {
      isMulti: true,
      placeholder: "Referrer",
      options: referrerOptions,
      onChange: setReferrer,
      value: referrer,
      menuOpenState: referrerOptionsOpen,
      setMenuOpenState: setReferrerOptionsOpen,
      components: {
        MultiValue: createCustomMultiValue(referrer),
        Option: checkCircle,
      },
    },
    {
      isMulti: true,
      placeholder: "Device brand",
      options: deviceBrandOptions,
      onChange: setDeviceBrand,
      value: deviceBrand,
      menuOpenState: deviceBrandOptionsOpen,
      setMenuOpenState: setDeviceBrandOptionsOpen,
      components: {
        MultiValue: createCustomMultiValue(deviceBrand),
        Option: checkCircle,
      },
    },
    {
      isMulti: true,
      placeholder: "Viewport resolution",
      options: resolutionOptions,
      onChange: setResolution,
      value: resolution,
      menuOpenState: resolutionOptionsOpen,
      setMenuOpenState: setResolutionOptionsOpen,
      components: {
        MultiValue: createCustomMultiValue(resolution),
        Option: checkCircle,
      },
    },
    {
      isMulti: true,
      placeholder: "Activity on forms",
      options: formUseOptions,
      onChange: setFormUse,
      value: formUse,
      menuOpenState: formUseOptionsOpen,
      setMenuOpenState: setFormUseOptionsOpen,
      components: {
        MultiValue: createCustomMultiValue(formUse),
        Option: checkCircle,
      },
    },
  ];

  const DropdownList = () => (
    <>
      <div className="segment-funnel-dropdown-colours">
        {dropdownConfigs.map((config, index) => (
          <Dropdown key={index} {...config} />
        ))}
      </div>
    </>
  );

  const content = [
    {
      title: "Goals",
      content: (
        <div className="custom-funnel-dropdown-colours">
          <div style={{ marginTop: "20px" }}>
            <CustomDropdown
              unstyled
              placeholder="Tag"
              options={tagOptions}
              onChange={(e) => {
                setTag(e);
              }}
              value={tag}
              menuOpenState={tagOptionsOpen}
              setMenuOpenState={setTagOptionsOpen}
            />
          </div>
        </div>
      ),
      collapsible: true,
      collapsed: goalCollapsed,
      setCollapsed: setGoalCollapsed,
    },
    {
      title: "Quick learn",
      content: (
        <div>
          <div className="w-100">
            <OverlayTrigger
              placement="right"
              overlay={
                <Tooltip className="v4-tooltip-tall">
                  Represents the path where the highest quantity of users
                  achieved the goal.
                </Tooltip>
              }
            >
              <button
                className={`fw-500
              ${pathType === "success" ? "btn-primary" : "btn-quaternary"}`}
                style={{
                  marginTop: "20px",
                  height: "44px",
                  border: "2px solid #2a00ff",
                }}
                onClick={() => setPathType("success")}
              >
                Most successful path
              </button>
            </OverlayTrigger>
          </div>
          <div className="w-100">
            <OverlayTrigger
              placement="right"
              overlay={
                <Tooltip className="v4-tooltip-tall">
                  Represents the path where the lowest quantity of users
                  achieved the goal.
                </Tooltip>
              }
            >
              <button
                className={`fw-500
              ${pathType === "failure" ? "btn-primary" : "btn-quaternary"}`}
                style={{
                  marginTop: "10px",
                  height: "44px",
                  border: "2px solid #2a00ff",
                }}
                onClick={() => setPathType("failure")}
              >
                Most failure path
              </button>
            </OverlayTrigger>
          </div>
          {/* <div className="w-100">
            <button
              className={`fw-500
              ${pathType === "ROI" ? "btn-primary" : "btn-quaternary"}`}
              style={{
                marginTop: "10px",
                height: "44px",
                border: "2px solid #2a00ff",
              }}
              onClick={() => setPathType("ROI")}
            >
              Best ROI path
            </button>
          </div> */}
        </div>
      ),
      collapsible: true,
      collapsed: quickLearnCollapsed,
      setCollapsed: setQuickLearnCollapsed,
    },
    {
      title: "Behaviors",
      content: (
        <>
          {behaviors.map((b, i) => (
            <div
              key={i}
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "25px",
              }}
            >
              <div className="fs-16 fw-500 lh-216">{b.label}</div>
              <div>
                <button
                  type="button"
                  onClick={() => {
                    b.setState((prevState) => {
                      const newState = !prevState;
                      if (b.label !== "Insights") {
                        uniqueBehaviorToggleHandler({ ...b, state: newState });
                      }
                      return newState;
                    });
                  }}
                  className="behavior-toggle align-items-center fw-700"
                  style={{ backgroundColor: b.state ? b.color : "#707070" }}
                >
                  <div
                    className={
                      b.state
                        ? "behavior-toggle-circle-on"
                        : "behavior-toggle-circle-off"
                    }
                  ></div>
                </button>
              </div>
            </div>
          ))}
        </>
      ),
    },
    {
      title: "Devices",
      content: (
        <>
          <div style={{ display: "flex", marginTop: "17px" }}>
            <button
              className={`vhc funnel-filter-toggle-btn-${
                phone ? "active" : "inactive"
              }`}
              onClick={() => {
                if (!phone) {
                  setPhone(true);
                  setDesktop(false);
                  setTablet(false);
                }
              }}
            >
              <i className="fa-regular fa-mobile fs-20"></i>
            </button>
            <button
              className={`vhc funnel-filter-toggle-btn-${
                desktop ? "active" : "inactive"
              }`}
              onClick={() => {
                if (!desktop) {
                  setPhone(false);
                  setDesktop(true);
                  setTablet(false);
                }
              }}
            >
              <i className="fa-regular fa-laptop fs-20"></i>
            </button>
            <button
              className={`vhc funnel-filter-toggle-btn-${
                tablet ? "active" : "inactive"
              }`}
              onClick={() => {
                if (!tablet) {
                  setPhone(false);
                  setDesktop(false);
                  setTablet(true);
                }
              }}
            >
              <i className="fa-regular fa-tablet fs-20"></i>
            </button>
            {/* <button
              className={`vhc funnel-filter-toggle-btn-${
                unknownDevice ? "active" : "inactive"
              }`}
              onClick={() => setUnknownDevice(!unknownDevice)}
            >
              <i className="fa-regular fa-message-question fs-20"></i>
            </button> */}
          </div>
        </>
      ),
    },
    {
      title: "Segments",
      content: <DropdownList />,
      collapsible: true,
      collapsed: segmentsCollapsed,
      setCollapsed: setSegmentsCollapsed,
    },
  ];

  function formatDateRangeForFunnel(startDate, endDate) {
    if (!startDate || !endDate) return "Selecting...";
    const start = new Date(startDate);
    const end = new Date(endDate);
    const options = { day: "numeric", month: "long", year: "numeric" };
    const startFormatted = start.toLocaleDateString("en-EN", options);
    const endFormatted = end.toLocaleDateString("en-EN", options);
    let [startMonth, startDay] = startFormatted.split(" ");
    let [endMonth, endDay, endYear] = endFormatted.split(" ");
    startMonth =
      startMonth.length > 4 ? startMonth.slice(0, 3) + "." : startMonth;
    endMonth = endMonth.length > 4 ? endMonth.slice(0, 3) + "." : endMonth;

    const sameYear = start.getFullYear() === end.getFullYear();
    if (sameYear) {
      return `${startMonth} ${startDay} - ${endMonth} ${endDay} ${endYear}`;
    } else {
      return `${startMonth} ${startDay} ${start.getFullYear()} - ${endMonth} ${endDay} ${endYear}`;
    }
  }

  if (!heatmapPrefsLoaded) {
    return (
      <div
        style={{
          height: "80%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner style={{ height: "70px", width: "70px" }} />
      </div>
    );
  }

  return (
    <div style={{ overflow: "visible" }}>
      <div className="d-flex">
        <div className="funnel-daterange fs-14 fw-500 ls-20 fc-black word-wrapper">
          {selectedDateText}
        </div>
      </div>

      <div style={{ overflow: "visible" }}>
        <CustomCalendar
          maxDate={new Date()}
          minDate={registrationDate}
          setIsDatePickerOpen={setDatepickerOpen}
          datePickerOpen={datepickerOpen}
          startDate={startDate}
          endDate={endDate}
          customInput={
            <div
              className="funnel-datepicker-btn fs-16 fw-700 fc-blue lh-20"
              style={{ minWidth: "273px" }}
            >
              {formatDateRangeForFunnel(startDate, endDate)}
              {datepickerOpen ? (
                <FiChevronUp alt="chevron up" color="#2A00FF" size="24px" />
              ) : (
                <FiChevronDown alt="chevron up" color="#2A00FF" size="24px" />
              )}
            </div>
          }
          onChange={(update) => {
            setDateRange(update);
            const days =
              Math.floor((update[1] - update[0]) / (1000 * 60 * 60 * 24)) + 1;
            if (days > 0) {
              setSelectedDateText(`${days} day range`);
            }
          }}
        ></CustomCalendar>
      </div>

      {content.map((item, index) => (
        <div
          key={index}
          style={{
            display: index === 2 ? getBehaviorDisplay() : "block",
          }}
        >
          <div className="FA-container">
            <button
              className="unstyled fc-black fs-16 fw-700 fc-black lh-216"
              onClick={() => {
                if (item.collapsible) {
                  item.setCollapsed(!item.collapsed);
                }
              }}
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div> {item.title}</div>
              <div>
                {item.collapsible ? (
                  item.collapsed ? (
                    <i className="fa-solid fa-plus fs-20"></i>
                  ) : (
                    <i className="fa-solid fa-minus fs-20"></i>
                  )
                ) : null}
              </div>
            </button>
            {!item.collapsed && <>{item.content}</>}
          </div>
        </div>
      ))}
    </div>
  );
}
