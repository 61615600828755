import { useEffect, useState, useMemo } from "react";
import { auth, db } from "../../firebase";
import {
  doc,
  updateDoc,
  deleteDoc,
  getDocs,
  collection,
  getDoc,
  query,
  where,
  onSnapshot,
} from "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import {
  dateFirestoreToReact,
  formatDateMDY,
  getAllProjects,
  userProjectsPromise,
  modifyDropdowns,
  enqueueAnalysis,
  verifyInsight,
  getHardcodedInsightFields,
  getUxErrorOptions,
  getUxErrorLabelAndIcon,
  getSeverityOptions,
  getFixEaseOptions,
  getSeverityOption,
  getFixEaseOption,
  getCardOptions,
  fixAiFixEaseSeverity,
  deleteInsightsFastDoc,
  getFastInsightsWithPid,
  dateReactToFirestore,
} from "../../helpers";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import CustomInput from "../CustomInput/CustomInput";
import grayPfp from "../../assets/gray.PNG";
import CustomDropdown from "../CustomDropdown/CustomDropdown";
import { Spinner } from "react-bootstrap";
import { BsXCircle } from "react-icons/bs";
import "./AdminManageInsights.css";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";

export default function Admin(props) {
  const [user, loading] = useAuthState(auth);
  const [insights, setInsights] = useState([]);
  const [filteredInsights, setFilteredInsights] = useState([]);

  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const navigate = useNavigate();

  const [projectFilter, setProjectFilter] = useState("");

  const defaultFilter = { value: "All", label: "All" };
  const [selectedProject, setSelectedProject] = useState(null);
  const [isProjectMenuOpen, setIsProjectMenuOpen] = useState(false);

  const [selectedPage, setSelectedPage] = useState(defaultFilter);
  const [isPageMenuOpen, setIsPageMenuOpen] = useState(false);

  const [selectedDate, setSelectedDate] = useState(defaultFilter);
  const [isDateMenuOpen, setIsDateMenuOpen] = useState(false);

  const [selectedStatus, setSelectedStatus] = useState(defaultFilter);
  const [isStatusMenuOpen, setIsStatusMenuOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const filterNames = ["Project", "Page", "Date", "Status"];

  const [verifierModalOpen, setVerifierModalOpen] = useState(false);
  const [selectedInsight, setSelectedInsight] = useState(null);

  // Start insight modal fields==================================================

  const [insightFieldsLoading, setInsightFieldsLoading] = useState(false);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [uxErrorType, setUxErrorType] = useState(null);
  const [severity, setSeverity] = useState(null);
  const [easeToFix, setEaseToFix] = useState(null);
  const [recommendation, setRecommendation] = useState("");
  const [uxRule, setUxRule] = useState("");
  const [metric, setMetric] = useState(null);

  const uxErrorOptions = getUxErrorOptions();
  const [isErrorTypeMenuOpen, setIsErrorTypeMenuOpen] = useState(false);

  const severityOptions = getSeverityOptions();
  const [isSeverityMenuOpen, setIsSeverityMenuOpen] = useState(false);

  const fixEaseOptions = getFixEaseOptions();
  const [isEaseToFixMenuOpen, setIsEaseToFixMenuOpen] = useState(false);

  const metricOptions = getCardOptions();
  const [isMetricMenuOpen, setIsMetricMenuOpen] = useState(false);

  const [originalInsight, setOriginalInsight] = useState(null);

  const [showErrorDetails, setShowErrorDetails] = useState(false);
  const [errorDetails, setErrorDetails] = useState([]);
  const [MRAID, setMRAID] = useState([]);

  // End insight modal fields====================================================

  function resetInsightModalFields() {
    let i = originalInsight;

    if (i.uxErrorType) {
      i.uxErrorType = i.uxErrorType.map((obj) => {
        if (!obj.label) {
          return {
            ...obj,
            label: getUxErrorLabelAndIcon(obj.value),
          };
        }
        return obj;
      });
    }

    setTitle(i.title ?? "");
    setDescription(i.description ?? "");
    setUxErrorType(i.uxErrorType ?? null);
    setSeverity(getSeverityOption(i.severity));
    setEaseToFix(getFixEaseOption(i.fixEase));
    setRecommendation(i.recommendation ?? "");
    setUxRule(i.uxRule ?? "");
    setMetric(i.metric ?? null);
    setMRAID(i.MRAID ?? null);

    setSelectedInsight(i);
  }

  useEffect(() => {
    if (props.adminPage) {
      props.setAdminPage(props.adminPage);
    }
    window.scrollTo(0, 0);
  }, [props.adminPage]);

  const onToggleMenu = (state, menuType) => {
    const index = filterNames.indexOf(menuType);
    if (index !== -1) {
      modifyDropdowns(index, "isMenuOpen", state, setDropdowns);
    }
  };

  const CustomMultiValue = ({ data }) => {
    return (
      selectedInsight?.uxErrorType &&
      selectedInsight?.uxErrorType[0].value === data.value && (
        <div>
          {selectedInsight?.uxErrorType.slice(0, 2).map((option, index) => (
            <span key={option.value}>
              {option.label}
              {index < selectedInsight?.uxErrorType.length - 1 ? ", " : null}
            </span>
          ))}
          {selectedInsight?.uxErrorType.length > 2
            ? `+ ${selectedInsight?.uxErrorType.length - 2} other${
                selectedInsight?.uxErrorType.length === 3 ? "" : "s"
              }`
            : null}
        </div>
      )
    );
  };

  const insightElementsCard = useMemo(() => {
    if (!errorDetails.length) {
      return null;
    }

    return (
      <div className="insight-rule-card" style={{ paddingTop: "0px" }}>
        <div
          className="d-flex align-items-center"
          onClick={() => setShowErrorDetails(!showErrorDetails)}
          style={{
            paddingTop: "24px",
            paddingBottom: "24px",
            marginBottom: "-24px",
          }}
        >
          <i className="fa-regular fa-triangle-exclamation fs-20"></i>
          <div className="fs-12 fw-900 lh-245" style={{ marginLeft: "10px" }}>
            ERROR DETAILS
          </div>

          <div
            style={{ flexGrow: 1 }}
            className="d-flex align-items-center justify-content-end"
          >
            {showErrorDetails ? (
              <FiChevronUp alt="chevron up" color="#707070" size="24px" />
            ) : (
              <FiChevronDown alt="chevron down" color="#707070" size="24px" />
            )}
          </div>
        </div>
        <div
          className="fs-16 fw-400 lh-2695"
          style={{
            marginTop: "10px",
            width: "620px",
            overflowWrap: "break-word",
            wordWrap: "break-word",
            display: showErrorDetails ? "block" : "none",
          }}
        >
          {errorDetails.map((element, index) => (
            <div
              key={index}
              className="error-element"
              style={{ width: "489px" }}
            >
              <i
                onClick={async () => {
                  try {
                    await navigator.clipboard.writeText(element);
                    toast.success("Copied to clipboard!");
                  } catch (err) {
                    toast.error("Failed to copy text: " + err);
                  }
                }}
                className="fa-regular fa-copy fs-20 lh--3 fc-white"
                style={{ float: "right" }}
              ></i>
              <div style={{ width: "420px" }} className="fc-white">
                {element
                  ? element
                      .replace(/</g, "&lt;")
                      .replace(/>/g, "&gt;")
                      .split(/(&lt;[\s\S]*?&gt;)/)
                      .map((part, index) =>
                        /&lt;[\s\S]*?&gt;/.test(part) ? (
                          <span key={index} className="fc-green2">
                            {part.replace(/&lt;/g, "<").replace(/&gt;/g, ">")}
                          </span>
                        ) : (
                          <span key={index}>{part}</span>
                        ),
                      )
                  : "This insight has no associated element"}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }, [errorDetails, showErrorDetails]);

  const [dropdowns, setDropdowns] = useState([
    {
      name: "Project",
      options: [defaultFilter],
      value: selectedProject,
      isMenuOpen: isProjectMenuOpen,
    },
    {
      name: "Page",
      options: [defaultFilter],
      value: selectedPage,
      isMenuOpen: isPageMenuOpen,
    },
    {
      name: "Date",
      options: [
        { label: "All", value: "All" },
        { label: "Today", value: "Today" },
        {
          label: "This Week",
          value: "This Week",
        },
        {
          label: "This Month",
          value: "This Month",
        },
        {
          label: "This Year",
          value: "This Year",
        },
      ],
      value: selectedDate,
      isMenuOpen: isDateMenuOpen,
    },
    {
      name: "Status",
      options: [
        { label: "All", value: "All" },
        { label: "Validated", value: "Validated" },
        { label: "Not validated", value: "Not yet validated" },
        {
          label: "Missing AI rec",
          value: "Missing AI recommendation",
        },
      ],
      value: selectedStatus,
      isMenuOpen: isStatusMenuOpen,
    },
  ]);

  useEffect(() => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // start of today

    const weekAgo = new Date();
    weekAgo.setDate(today.getDate() - 7); // start of the week

    const monthAgo = new Date();
    monthAgo.setMonth(today.getMonth() - 1); // start of the month

    const yearAgo = new Date();
    yearAgo.setFullYear(today.getFullYear() - 1); // start of the year

    // Filter the table on change of filter selection

    const filteredInsights = insights.filter((r) => {
      if (!r) {
        return false;
      }

      let dateFilter = false;
      if (selectedDate.value === "All") {
        dateFilter = true;
      } else {
        const recordDate = dateFirestoreToReact(r.createdAt || r.date);
        switch (selectedDate.label) {
          case "Today":
            dateFilter = recordDate.getTime() >= today.getTime();
            break;
          case "This Week":
            dateFilter = recordDate.getTime() >= weekAgo.getTime();
            break;
          case "This Month":
            dateFilter = recordDate.getTime() >= monthAgo.getTime();
            break;
          case "This Year":
            dateFilter = recordDate.getTime() >= yearAgo.getTime();
            break;
          default:
            break;
        }
      }

      const statusFilter =
        selectedStatus.value === "All" ||
        r.status === selectedStatus.value ||
        (!r.status && selectedStatus.value === "in-progress");

      const pageFilter =
        selectedPage.value === "All" || r.url === selectedPage.value;

      return dateFilter && statusFilter && pageFilter;
    });

    setFilteredInsights(filteredInsights);
  }, [selectedDate.value, selectedStatus.value, selectedPage.value, insights]);

  const onSelect = (option, menuType) => {
    switch (menuType) {
      case "Project":
        setIsLoading(true);
        setSelectedProject(option);
        modifyDropdowns(0, "value", option, setDropdowns);
        break;
      case "Page":
        setSelectedPage(option);
        modifyDropdowns(1, "value", option, setDropdowns);
        break;
      case "Date":
        setSelectedDate(option);
        modifyDropdowns(2, "value", option, setDropdowns);
        break;
      case "Status":
        setSelectedStatus(option);
        modifyDropdowns(3, "value", option, setDropdowns);
        break;

      default:
        break;
    }
  };

  const handleFilterChange = (value) => {
    setProjectFilter(value);
    setFilteredUsers(
      users.filter(
        (u) =>
          u?.firstName?.toLowerCase().includes(value.toLowerCase()) ||
          u?.lastName?.toLowerCase().includes(value.toLowerCase()) ||
          u?.projects?.some((p) =>
            p.name.toLowerCase().includes(value.toLowerCase()),
          ),
      ),
    );
  };

  useEffect(() => {
    props.setActiveMenuItem("insights");
    if (selectedProject?.value) {
      setIsLoading(true);
      setSelectedPage(defaultFilter);
      modifyDropdowns(1, "value", defaultFilter, setDropdowns);
      modifyDropdowns(1, "options", [defaultFilter], setDropdowns);
      fetchInsights(selectedProject.value);
    }
  }, [selectedProject]);

  async function buildProjectsDropdown() {
    const projects = await getAllProjects();
    if (projects.length === 0) {
      return;
    }

    const projectList = projects.map((i) => ({
      value: i.id,
      label: i.name,
    }));

    setDropdowns((prevDropdowns) => [
      {
        ...prevDropdowns[0],
        options: projectList,
      },
      ...prevDropdowns.slice(1),
    ]);
  }

  useEffect(() => {
    buildProjectsDropdown();
  }, []);

  async function fetchInsights(pid) {
    setIsLoading(true);
    const insights = await getFastInsightsWithPid(pid);
    setInsights(insights);
    setFilteredInsights(insights);
    setIsLoading(false);

    // build insight page dropdowns
    if (insights.length === 0) {
      return;
    }
    const pageList = insights
      .filter((i) => i && i.url)
      .reduce((unique, i) => {
        let urlLabel = i.url;
        if (!i.url.startsWith("http://") && !i.url.startsWith("https://")) {
          urlLabel = `https://${i.url}`;
        }

        let urlObj;
        try {
          urlObj = new URL(urlLabel);
        } catch (error) {
          urlObj = { pathname: "Invalid url" };
        }
        let label = urlObj.pathname;

        if (label === "/") {
          label = "Homepage";
        }

        return unique.findIndex((x) => x.label === label) < 0
          ? [...unique, { value: i.url, label: label }]
          : unique;
      }, []);

    modifyDropdowns(1, "options", [defaultFilter, ...pageList], setDropdowns);
  }

  async function fetchUsersWithProjects() {
    const usersQuery = query(
      collection(db, "users"),
      where("role", "==", "client"),
    );
    const usersSnapshot = await getDocs(usersQuery);
    const users = await Promise.all(
      usersSnapshot.docs.map(async (doc) => {
        const projects = await userProjectsPromise(doc.id);
        projects.map((p) => {
          if (p.analysisState !== 100 && p.analysisState !== -1) {
            watchProject(p.value);
          }
        });

        return {
          id: doc.id,
          projects,
          ...doc.data(),
        };
      }),
    );
    setUsers(users);
    setFilteredUsers(users);
  }

  const deleteInsight = async (trueId) => {
    deleteInsightsFastDoc(trueId); // Finds fast doc based on true id
    const insightRef = doc(db, "insights", trueId);
    await deleteDoc(insightRef);

    toast.success("Insight deleted successfully", {
      autoClose: 10,
      position: "bottom-right",
    });

    // Update the state
    setFilteredInsights(
      filteredInsights.filter((insight) => insight?.trueId !== trueId),
    );
    setInsights(insights.filter((insight) => insight?.trueId !== trueId));
  };

  const deleteRulesCheckResult = async (trueId, toastDisplay = "show") => {
    await deleteInsightsFastDoc(trueId); // Finds fast doc based on true id
    const insightRef = doc(db, "rulesChecksResults", trueId);
    await deleteDoc(insightRef);

    if (toastDisplay === "show") {
      toast.success("Insight deleted successfully", {
        autoClose: 10,
        position: "bottom-right",
      });
    }

    // Update the state
    setFilteredInsights(
      filteredInsights.filter((insight) => insight?.trueId !== trueId),
    );
    setInsights(insights.filter((insight) => insight?.trueId !== trueId));
  };

  const refreshInsights = () => {
    buildProjectsDropdown();
    fetchUsersWithProjects();

    if (selectedProject?.value) {
      fetchInsights(selectedProject.value);
    }
  };

  useEffect(() => {
    if (user) {
      refreshInsights();
    }
  }, [user]);

  async function verifyInsightHandler(rulesCheck) {
    let i = {
      ...rulesCheck,
      ...(title ? { title } : {}),
      ...(description ? { description } : {}),
      ...(uxErrorType ? { uxErrorType } : {}),
      ...(severity ? { severity: severity.value } : {}),
      ...(easeToFix ? { fixEase: easeToFix.value } : {}),
      ...(recommendation ? { recommendation } : {}),
      ...(uxRule ? { uxRule } : {}),
      ...(metric ? { metric: metric.value } : {}),
      ...(MRAID ? { MRAID } : {}),
    };
    delete i.project;
    const newInsight = await verifyInsight(i);
    return newInsight;
  }

  const [unsubscribes, setUnsubscribes] = useState([]);
  const watchProject = (projectId) => {
    const projectRef = doc(db, "projects", projectId);

    const unsubscribe = onSnapshot(projectRef, (doc) => {
      if (doc.exists()) {
        const updatedProject = { ...doc.data(), value: projectId };
        // Update the project in the filteredUsers state
        setFilteredUsers((prevFilteredUsers) => {
          return prevFilteredUsers.map((user) => {
            return {
              ...user,
              projects: user.projects.map((project) => {
                return project.value === projectId ? updatedProject : project;
              }),
            };
          });
        });

        // Update the project in the users state
        setUsers((prevUsers) => {
          return prevUsers.map((user) => {
            return {
              ...user,
              projects: user.projects.map((project) => {
                return project.value === projectId ? updatedProject : project;
              }),
            };
          });
        });
      }
    });

    // Add the unsubscribe function to the array of unsubscribes
    setUnsubscribes((prevUnsubscribes) => [...prevUnsubscribes, unsubscribe]);

    // Return a cleanup function
    return () => unsubscribe();
  };

  useEffect(() => {
    if (!auth?.currentUser && unsubscribes.length > 0) {
      // Unsubscribe all when the user signs out
      unsubscribes.forEach((unsubscribe) => unsubscribe());
      setUnsubscribes([]);
    }
  }, [auth, unsubscribes]);

  function setProjectStateStarted(id) {
    updateDoc(doc(db, "projects", id), {
      analysisState: 1,
    });
    setUsers((prevUsers) => {
      return prevUsers.map((user) => {
        return {
          ...user,
          projects: user.projects.map((project) => {
            return project.value === id
              ? { ...project, analysisState: 0 }
              : project;
          }),
        };
      });
    });
    setFilteredUsers((prevUsers) => {
      return prevUsers.map((user) => {
        return {
          ...user,
          projects: user.projects.map((project) => {
            return project.value === id
              ? { ...project, analysisState: 0 }
              : project;
          }),
        };
      });
    });
  }

  async function populateInsightFields(iFast) {
    setInsightFieldsLoading(true);
    const id = iFast.trueId; // Rules check id
    let insightsSnapshot = await getDoc(doc(db, "rulesChecksResults", id));
    const insight = insightsSnapshot.data();

    let i = {
      rcrId: id,
      fastId: iFast.id,
      project: iFast.project,
      ...insight,
      ...(iFast.source && iFast.source === "hardCoded"
        ? getHardcodedInsightFields(insight.ruleId)
        : {}),
    };

    if (i.source === "IBM") {
      i.uxErrorType = [{ value: "accessibilityAndConformity" }];
    }

    if (i?.source && i?.source === "IBM") {
      i.description = insight.description;
      i.recommendation = insight.recommendation;
      i.fixEase = fixAiFixEaseSeverity(insight.aiFixEase) ?? "";
      i.severity = fixAiFixEaseSeverity(insight.aiSeverity) ?? "";
      i.uxRule = insight.aiUxRule ?? "";
    }

    setOriginalInsight(i);
    if (i.uxErrorType) {
      i.uxErrorType = i.uxErrorType.map((obj) => {
        if (!obj.label) {
          return {
            ...obj,
            label: getUxErrorLabelAndIcon(obj.value),
          };
        }
        return obj;
      });
    }

    setTitle(i.title ?? "");
    setDescription(
      Array.isArray(i.description) ? i.description.join("\n\n") : i.description,
    );
    setUxErrorType(i.uxErrorType ?? null);
    setSeverity(getSeverityOption(i.severity));
    setEaseToFix(getFixEaseOption(i.fixEase));
    setRecommendation(
      Array.isArray(i.recommendation)
        ? i.recommendation.join("\n\n")
        : i.recommendation,
    );
    setUxRule(i.uxRule ?? "");
    setMetric(i.metric ?? null);
    setErrorDetails(i.element ?? []);
    setSelectedInsight(i);
    setInsightFieldsLoading(false);
    setMRAID(i.MRAID ?? null);
  }

  return (
    <div>
      <ToastContainer />
      <Modal
        isOpen={verifierModalOpen}
        contentLabel="Verify insight modal"
        className="verifier-modal modal-shape2"
        appElement={document.getElementById("root") || undefined}
        style={{
          overlay: {
            background: "rgb(42, 0, 255, 0.7)",
            backdropFilter: "blur(8px)",
            zIndex: 10,
            transform: "translate3d(0, 0, 0)",
            backfaceVisibility: "hidden",
            WebkitBackfaceVisibility: "hidden",
            willChange: "transform",
            perspective: 1000,
          },
        }}
      >
        {insightFieldsLoading ? (
          <div
            style={{
              display: "flex",
              height: "500px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner style={{ height: "150px", width: "150px" }} />
          </div>
        ) : (
          <div className="verifier-modal-content">
            <button
              style={{ marginLeft: "-142.5px", position: "absolute" }}
              className="d-flex justify-content-center align-items-center delete-confirm-x-btn"
              onClick={() => setVerifierModalOpen(false)}
            >
              <BsXCircle alt="X button" size="32px" color="#0F0C22" />
            </button>
            <div className="vhc">
              <img
                src={selectedInsight?.project?.imageLink}
                className="img-keep-ratio"
                style={{
                  width: "38px",
                  height: "38px",
                  marginTop: "27px",
                  marginBottom: "16px",
                }}
                alt="projectimg"
              />
            </div>
            <div className="vhc">
              <div className="fs-24 fw-500 lh-336 fc-black">
                Insight #{selectedInsight?.num}
              </div>
            </div>
            <div className="fs-18 fw-500 lh-224 fc-grey">
              <div className="vhc">
                Correct the AI result to be as accurate as possible.
              </div>
              <div className="vhc">But don’t change if it is good.</div>
            </div>
            <div
              className="underline fs-16"
              style={{ marginTop: "10px", marginBottom: "32px" }}
            >
              <a href={selectedInsight?.url} target="_blank" rel="noreferrer">
                <p className="vhc">Check the related page online</p>
                <p className="vhc">{selectedInsight?.url}</p>
              </a>
            </div>

            <CustomInput
              type="text"
              placeholder={"Title"}
              onChange={(e) => setTitle(e.target.value)}
              valueState={title}
            />
            <CustomInput
              isTextArea
              type="text"
              placeholder={"Description"}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
              valueState={description}
            />

            {insightElementsCard}
            <div
              className="insight-input"
              style={{
                marginTop: "6px",
              }}
            >
              <CustomDropdown
                isMulti
                hideSelectedOptions={false}
                options={uxErrorOptions}
                onChange={(e) => {
                  setUxErrorType(e);
                  setSelectedInsight((prevObs) => {
                    return {
                      ...prevObs,
                      uxErrorType: e,
                    };
                  });
                }}
                value={uxErrorType ?? null}
                placeholder="UX Error Type"
                unstyled
                closeMenuOnSelect={false}
                blurInputOnSelect={false}
                isClearable={false}
                menuOpenState={isErrorTypeMenuOpen}
                setMenuOpenState={setIsErrorTypeMenuOpen}
                components={{
                  MultiValue: CustomMultiValue,
                }}
              />
            </div>
            <div
              className="insight-input"
              style={{
                marginTop: "6px",
              }}
            >
              <CustomDropdown
                unstyled
                placeholder="Severity"
                options={severityOptions}
                onChange={(e) => {
                  setSeverity(e);
                }}
                value={severity}
                menuOpenState={isSeverityMenuOpen}
                setMenuOpenState={setIsSeverityMenuOpen}
              />
            </div>
            <div
              className="insight-input"
              style={{
                marginTop: "6px",
              }}
            >
              <CustomDropdown
                unstyled
                placeholder="Ease to fix"
                options={fixEaseOptions}
                onChange={(e) => {
                  setEaseToFix(e);
                }}
                value={easeToFix}
                menuOpenState={isEaseToFixMenuOpen}
                setMenuOpenState={setIsEaseToFixMenuOpen}
              />
            </div>

            <div>
              <CustomInput
                isTextArea
                type="text"
                placeholder="Recommendation"
                onChange={(e) => setRecommendation(e.target.value)}
                valueState={recommendation}
              />
            </div>

            <CustomInput
              isTextArea
              type="text"
              placeholder="TO NOT DIE STUPID"
              onChange={(e) => setUxRule(e.target.value)}
              valueState={uxRule}
            />

            <div
              className="insight-input"
              style={{
                marginTop: "6px",
              }}
            >
              <CustomDropdown
                unstyled
                placeholder="Link to Performance Metric"
                options={metricOptions}
                onChange={(e) => {
                  setMetric(e);
                }}
                value={metric}
                menuOpenState={isMetricMenuOpen}
                setMenuOpenState={setIsMetricMenuOpen}
              />
            </div>

            <button
              type="button"
              onClick={() => {
                toast.success("Insight verified");
                setIsLoading(true);
                setVerifierModalOpen(false);

                verifyInsightHandler(selectedInsight).then((newInsight) => {
                  const newId = newInsight.id; // Create an insight doc and return the ID
                  const insightsFastDoc = doc(
                    db,
                    "insightsFast",
                    selectedInsight.fastId,
                  );
                  updateDoc(insightsFastDoc, {
                    num: newInsight.num,
                    title: newInsight.title,
                    status: "Validated",
                    trueId: newId,
                    verifyDate: dateReactToFirestore(new Date()),
                  }).then(() => {
                    deleteRulesCheckResult(
                      selectedInsight.rcrId,
                      "silent",
                    ).then(() => {
                      if (selectedProject?.value) {
                        fetchInsights(selectedProject.value);
                      }
                    });
                  });
                });
              }}
              style={{ marginTop: "32px", marginBottom: "16px" }}
              className=" btn-primary align-items-center fw-700 w-100"
            >
              Validate
            </button>
            <button
              type="button"
              style={{ marginBottom: "51px" }}
              onClick={() => {
                resetInsightModalFields();
              }}
              className=" btn-secondary align-items-center fw-700 w-100"
            >
              Back to original feedback
            </button>
          </div>
        )}
      </Modal>

      <div className="fs-24 fw-700 lh-243 mb-3 fc-black">
        Admin panel - Insights
      </div>
      <div className="fw-500 fs-16 lh-20 fc-grey">Requests sent by the AI.</div>
      <div className="fw-500 fs-16 lh-20 fc-grey">
        Double check to validate or improve the quality of the result.
      </div>

      <div className="mt-3 mb-5 fs-16">
        <button
          onClick={() => {
            props.setAdminPage("manage");
            refreshInsights();
          }}
          style={{ height: "45px" }}
          className={
            props.adminPage === "manage" ? "btn-primary" : "btn-secondary"
          }
        >
          Reload & view insights
        </button>

        <button
          onClick={() => props.setAdminPage("add")}
          style={{ height: "45px" }}
          className={
            props.adminPage === "add"
              ? "btn-primary ms-3"
              : "btn-secondary ms-3"
          }
        >
          Analyze site
        </button>
        {false && (
          <button
            onClick={async () => {
              const isConfirmed = window.confirm("Are you sure?");
              if (isConfirmed) {
              }
            }}
            style={{ height: "45px" }}
            className={
              props.adminPage === "add"
                ? "btn-primary ms-3"
                : "btn-secondary ms-3"
            }
          >
            QA
          </button>
        )}
      </div>

      {props.adminPage === "manage" ? (
        <>
          {isLoading ? (
            <div
              style={{
                display: "flex",
              }}
            >
              <Spinner style={{ height: "70px", width: "70px" }} />
            </div>
          ) : (
            <>
              <div className="d-flex" style={{ marginBottom: "50px" }}>
                {dropdowns.map((dropdown, i) => (
                  <div
                    key={i}
                    className={`dropdown-filter-width-${
                      i == 0 || i == 1 ? "200" : "127"
                    }`}
                  >
                    <CustomDropdown
                      options={dropdown.options}
                      onChange={(option) => onSelect(option, dropdown.name)}
                      placeholder={dropdown.name}
                      value={dropdown.value}
                      unstyled
                      setMenuOpenState={(res) =>
                        onToggleMenu(res, dropdown.name)
                      }
                      menuOpenState={dropdown.isMenuOpen}
                    />
                  </div>
                ))}
              </div>
              {!selectedProject ? (
                <h2>Please select a project</h2>
              ) : filteredInsights.length === 0 ? (
                <h2>No insights found with selected filters</h2>
              ) : (
                <div className="row fs-16 fc-back lh-24 fw-700 mb-4 vhc fvc">
                  <div className="col-1">Project</div>
                  <div className="col-1">Insights</div>
                  <div className="col-4">Topic</div>
                  <div className="col-2">Date</div>
                  <div className="col-2">Status</div>
                  <div className="col-2"></div>
                </div>
              )}

              <div>
                {filteredInsights.map(
                  (insight, index) =>
                    insight && (
                      <div key={index} className="grey-hover">
                        <hr className="m-0"></hr>
                        <div className="row fc-black fw-500 fs-16 pt-3 pb-3 vhc fvc">
                          <div className="col-1">
                            <img
                              className="img-keep-ratio"
                              src={insight?.project?.imageLink}
                              style={{ width: "38px", height: "38px" }}
                              alt="projectimg"
                            />
                          </div>
                          <div className="col-1">
                            Insight #{insight?.num ?? "X"}
                          </div>
                          <div className="col-4">{insight?.title}</div>
                          <div className="col-2 fc-grey">
                            {formatDateMDY(
                              dateFirestoreToReact(
                                insight?.createdAt || insight?.date,
                              ),
                            )}
                          </div>
                          <div
                            className="col-2"
                            style={{
                              color:
                                insight?.status === "Validated"
                                  ? "#25A93A"
                                  : "#707070",
                            }}
                          >
                            {insight?.status === "Validated" &&
                            insight?.verifyDate ? (
                              <>
                                {insight?.status}
                                <div className="fw-700">
                                  {formatDateMDY(
                                    dateFirestoreToReact(insight?.verifyDate),
                                  ) || ""}
                                </div>
                              </>
                            ) : (
                              (insight?.status ?? "Missing status")
                            )}
                          </div>
                          <div className="col-2 text-end">
                            {insight?.status === "Validated" ? (
                              <button
                                className="btn-secondary fw-700 fs-16"
                                style={{ marginRight: "10px" }}
                                onClick={() => {
                                  props.setSelectedProject(insight?.project);
                                  navigate("/insights/view", {
                                    state: {
                                      selectedInsightAdmin: insight?.trueId,
                                    },
                                  });
                                }}
                              >
                                Check
                              </button>
                            ) : insight?.status === "Not yet validated" ||
                              insight?.status ===
                                "Missing AI recommendation" ? (
                              <button
                                className="btn-secondary fw-700 fs-16"
                                style={{ marginRight: "10px" }}
                                onClick={() => {
                                  populateInsightFields(insight);
                                  setVerifierModalOpen(true);
                                }}
                              >
                                Check
                              </button>
                            ) : null}
                            <button
                              className="btn-secondary fw-700 fs-16"
                              style={{ marginRight: "10px" }}
                              onClick={async () => {
                                if (insight?.status === "Validated") {
                                  await deleteInsight(insight?.trueId);
                                } else {
                                  await deleteRulesCheckResult(insight?.trueId);
                                }
                              }}
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    ),
                )}
              </div>
            </>
          )}
        </>
      ) : props.adminPage === "add" ? (
        <>
          <div className="row mb-4 w-100 vhc">
            <div className="col-2">
              <CustomInput
                placeholder="Filter by project or user name"
                containerClassName="observation-text-input-container half-width"
                inputClassNames="w-100"
                className={projectFilter === "" ? "active" : ""}
                valueState={projectFilter}
                onChange={(e) => handleFilterChange(e.target.value)}
                value={projectFilter}
              />
            </div>
            <div className="col-10"> </div>
          </div>
          <div>
            {filteredUsers.map((u, index) => (
              <div key={index}>
                <hr className="m-0"></hr>
                <div className="d-flex mt-3 mb-3">
                  <div>
                    <img
                      src={u?.pfp ?? grayPfp}
                      style={{
                        width: "60px",
                        height: "60px",
                        marginRight: "25px",
                      }}
                      alt="client img"
                    />
                  </div>
                  <div className="fs-18 fw-700 vhc">
                    {u?.firstName ?? u?.personData?.firstName ?? "No Name"}
                    <br></br>
                    {u?.lastName ?? u?.personData?.lastName ?? ""}
                  </div>
                </div>
                {u.projects.map(
                  (p, index) =>
                    p && (
                      <div key={index} className="grey-hover">
                        <div className="row pt-3 pb-3 vhc fvc">
                          <div
                            className="col-8"
                            style={{ paddingLeft: "30px" }}
                          >
                            <img
                              className="img-keep-ratio"
                              src={p?.imageLink ?? grayPfp}
                              style={{
                                width: "38px",
                                height: "38px",
                                marginRight: "25px",
                              }}
                              alt="proj img"
                            />
                            {p?.name}
                          </div>
                          <div className="col-4 text-end">
                            <span
                              className={`fs-18 fc-white fw-500 mr-10 ${
                                p?.analysisState === -1
                                  ? "bg-red"
                                  : p?.analysisState === 100
                                    ? "bg-green"
                                    : !p?.analysisState
                                      ? "transparent"
                                      : "bg-yellow"
                              }`}
                              style={{
                                padding: "12px",
                                minHeight: "40px",
                                borderRadius: "8px",
                              }}
                            >
                              {p?.analysisState === -1 ? (
                                "Error"
                              ) : p?.analysisState === 100 ? (
                                "Done"
                              ) : (
                                <>{Math.round(p?.analysisState)}%</>
                              )}
                            </span>
                            {(p?.analysisState === -1 ||
                              p?.analysisState === 100 ||
                              !p?.analysisState) && (
                              <button
                                className="btn-secondary fw-700 fs-16"
                                style={{ marginRight: "10px" }}
                                onClick={async () => {
                                  toast.success("Analysis started!");
                                  setProjectStateStarted(p?.value);
                                  watchProject(p?.value);
                                  enqueueAnalysis(p?.value);
                                }}
                              >
                                Analyze site
                              </button>
                            )}

                            <button
                              className="btn-secondary fw-700 fs-16"
                              style={{ marginRight: "10px" }}
                              onClick={() => {
                                navigate("/create-report/admin", {
                                  state: { project: p },
                                });
                              }}
                            >
                              Add New Insight
                            </button>
                          </div>
                        </div>
                      </div>
                    ),
                )}
              </div>
            ))}
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
}
