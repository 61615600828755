import "./CreateProject.css";
import MainLayout from "../MainLayout";
import CustomCarousel from "../../components/CustomCarousel/CustomCarousel";
import React, { useCallback, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import {
  uploadFileToFirestore,
  userExists,
  getUserInformation,
  paymentSquare2,
  getUserProjects,
  backToDashboard,
  HTTPSurlIsValid,
  AddOrganizationModal,
  getClientOrganizations,
  changeProjectOrg,
  addMatomoWebsite,
} from "../../helpers";
import { FiArrowRight, FiPlus, FiX } from "react-icons/fi";
import { HiPlus } from "react-icons/hi";
import CustomInput from "../CustomInput/CustomInput";
import { collection, doc, setDoc, updateDoc, getDoc } from "firebase/firestore";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { auth, db } from "../../firebase";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ShareAnalyticsScript from "../ShareAnalyticsScript/ShareAnalyticsScript";

import PaymentModal from "../PaymentModal/PaymentModal";
import { toast, ToastContainer } from "react-toastify";
import CustomDropdown from "../CustomDropdown/CustomDropdown";

function CreateProject() {
  const navigate = useNavigate();

  const [index, setIndex] = useState(0);
  const [projectStatus, setProjectStatus] = useState("live");
  const [projectId, setProjectId] = useState("");
  const [cachedPropertyId, setCachedPropertyId] = useState("");
  const [isSSO, setIsSSO] = useState(false);
  const [ssoError, setSSOError] = useState();
  const [logo, setLogo] = useState();

  const [cachedProjectId, setCachedProjectId] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [userLinks, setUserLinks] = useState([""]);
  const [projectName, setProjectName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [selectedImage, setSelectedImage] = useState("");
  const [imagePreview, setImagePreview] = useState("");
  const [loading, setLoading] = useState(false);
  const [links, setLinks] = useState([]);

  const [errMsg, setErrMsg] = useState("");

  const [copyFocused, setCopyFocused] = useState(false);

  const [pagesAnalyzed, setPagesAnalyzed] = useState(1);
  const [maxPagesAnalyzed, setMaxPagesAnalyzed] = useState(1);
  const [userLinksValid, setUserLinksValid] = useState(false);

  const [paymentModalIsOpen, setPaymentModalIsOpen] = useState(false);
  const [paymentPlan, setPaymentPlan] = useState(null);

  const [userInfo, setUserInfo] = useState({});

  const [userProjectsCount, setUserProjectsCount] = useState(null);
  const [maxUserProjectsCount, setMaxUserProjectsCount] = useState(0);

  const [platformOptions, setPlatformOptions] = useState([
    {
      label: "Matomo",
      value: "matomo",
    },
    { label: "Google analytics", value: "GA4" },
  ]);

  const [cookieOptions, setcookieOptions] = useState([
    { label: "None", value: "none" },
    { label: "OneTrust", value: "onetrust" },
  ]);

  const [cookie, setCookie] = useState(cookieOptions[0]);
  const [cookieOpen, setCookieOpen] = useState(false);

  const [platform, setPlatform] = useState(platformOptions[0]);
  const [platformOpen, setPlatformOpen] = useState(false);

  const [industryOptions] = useState([
    {
      label: (
        <>
          <i className="fa-regular fa-rectangle-ad mr-8"></i>
          Media
        </>
      ),
      value: "media",
    },
    {
      label: (
        <>
          <i className="fa-regular fa-washing-machine mr-8"></i>
          Retail
        </>
      ),
      value: "retail",
    },
    {
      label: (
        <>
          <i className="fa-regular fa-code mr-8"></i>
          Software
        </>
      ),
      value: "software",
    },
    {
      label: (
        <>
          <i className="fa-regular fa-tree-city mr-8"></i>
          Government & Administration
        </>
      ),
      value: "government_and_administration",
    },
    {
      label: (
        <>
          <i className="fa-regular fa-user-bounty-hunter mr-8"></i>
          Head Hunter/ Jobs
        </>
      ),
      value: "headhunter_jobs",
    },
    {
      label: (
        <>
          <i className="fa-regular fa-ban mr-8"></i>
          N/A
        </>
      ),
      value: "",
    },
  ]);

  const [industry, setIndustry] = useState(null);
  const [industryOpen, setIndustryOpen] = useState(false);

  const [matomoUrl, setMatomoUrl] = useState("");
  const [matomoId, setMatomoId] = useState("");
  const [matomoApiToken, setMatomoApiToken] = useState("");

  const [newOrgName, setNewOrgName] = useState("");
  const [addOrgModalIsOpen, setAddOrgModalIsOpen] = useState(false);
  const [orgOptions, setOrgOptions] = useState([]);

  const [selectedOrg, setSelectedOrg] = useState(null);
  const [orgMenuIsOpen, setOrgMenuIsOpen] = useState(false);
  const [dynamicScript, setDynamicScript] = useState("");
  const [codeFromOneTrust, setCodeFromOneTrust] = useState("");
  const [siteid, setSiteid] = useState("-1");

  useEffect(() => {
    if (codeFromOneTrust) {
      setDynamicScript(
        `Your script with the OneTrust code: ${codeFromOneTrust}`,
      );
    } else {
      setDynamicScript("Your script without OneTrust code");
    }
  }, [codeFromOneTrust]);

  useEffect(() => {
    setErrMsg("");
  }, [platform]);

  useEffect(() => {
    // As of Organizations implementation, invited users cannot create projects
    if (userInfo && ["owner", "editor", "reader"].includes(userInfo.role)) {
      navigate("/");
    }
  }, [userInfo]);

  useEffect(() => {
    if (auth?.currentUser?.uid) {
      getUserInformation().then((userInfo) => {
        if (userInfo) {
          setUserInfo(userInfo);
          setPaymentModalIsOpen(!userInfo?.paymentPlan);
          if (userInfo?.paymentPlan) {
            setPaymentPlan(userInfo.paymentPlan);
          }
          getUserProjects(userInfo).then((projects) => {
            setUserProjectsCount(projects.length);
          });
          getClientOrganizations(auth?.currentUser?.uid).then((orgs) => {
            setOrgOptions(
              orgs.map((org) => ({ label: org.name, value: org.id })),
            );
          });
        } else {
          setUserProjectsCount(0);
        }

        if (userInfo && userInfo.awaitingApproval) {
          navigate("/");
        }
      });
    }
  }, [auth.currentUser]);

  useEffect(() => {
    switch (paymentPlan) {
      case "start":
        setMaxPagesAnalyzed(3);
        setMaxUserProjectsCount(3);
        break;
      case "ultra":
        setMaxPagesAnalyzed(99);
        setMaxUserProjectsCount(99);
        break;
      case "corporate":
        setMaxPagesAnalyzed(99);
        setMaxUserProjectsCount(99);
        break;
      default:
        setMaxPagesAnalyzed(1);
        setMaxUserProjectsCount(1);
        break;
    }
  }, [paymentPlan]);

  /**
   * Handlers for copying the google analytics email.
   */
  const handleFocus = useCallback(() => {
    setCopyFocused(true);
  }, [setCopyFocused]);

  const handleBlur = useCallback(() => {
    setCopyFocused(false);
  }, [setCopyFocused]);

  function changeLinks(i, value, mode) {
    let newLinks;
    if (mode === "edit") {
      newLinks = [...userLinks];
      newLinks[i] = value;
    }

    if (mode === "delete") {
      newLinks = userLinks.filter((_, index) => index !== i);
    }

    if (!newLinks) return;

    setUserLinks(newLinks);

    let formatsValid = true;
    let domainsMatch = true;
    let hasDuplicate = false;
    let errorMessage = "";
    let duplicateMessage = "";

    formatsValid = newLinks.every(
      (link) => link === "" || HTTPSurlIsValid(link),
    );

    if (newLinks[0] && formatsValid) {
      try {
        const mainUrl = new URL(
          newLinks[0].startsWith("http")
            ? newLinks[0]
            : `https://${newLinks[0]}`,
        );

        const mainDomain = mainUrl.hostname.replace(/^www\./, "");

        domainsMatch = newLinks.slice(1).every((link) => {
          if (link === "") return true;
          try {
            const linkUrl = new URL(
              link.startsWith("http") ? link : `https://${link}`,
            );

            const linkDomain = linkUrl.hostname.replace(/^www\./, "");

            return linkDomain === mainDomain;
          } catch (e) {
            return false;
          }
        });

        if (!domainsMatch) {
          formatsValid = false;
        }
      } catch (e) {
        domainsMatch = false;
        errorMessage = "The main URL is not in a valid format.";
      }
    }

    const nonEmptyLinks = newLinks.filter((link) => link !== "");

    const normalizedLinks = nonEmptyLinks.map((link) => {
      try {
        const url = new URL(link.startsWith("http") ? link : `https://${link}`);
        return (
          url.hostname.replace(/^www\./, "") +
          url.pathname +
          url.search +
          url.hash
        );
      } catch (e) {
        return link;
      }
    });

    const uniqueLinks = new Set(normalizedLinks);

    if (uniqueLinks.size !== nonEmptyLinks.length) {
      hasDuplicate = true;
      duplicateMessage = "Duplicate URLs are not allowed.";
    }

    if (!formatsValid) {
      errorMessage = "One or more URLs are invalid.";
    }

    setErrMsg(errorMessage || duplicateMessage);
    setUserLinksValid(
      formatsValid &&
        domainsMatch &&
        !hasDuplicate &&
        newLinks.every((link) => link !== ""),
    );
  }

  // Upload the google analytics ID to the project.
  const updateProjectLinksGA4 = async () => {
    await updateDoc(doc(db, "projects", cachedProjectId), {
      googleAnalyticsId: projectId,
      platform: "GA4",
    });
    navigate("/", {
      state: {
        justCreatedProject: true,
        justCreatedProjectEnqueue: true,
        logo: logo ?? "",
        analysisId: cachedProjectId ?? "",
      },
    });
  };

  const completeProjectCreation = async () => {
    if (userInfo && userInfo.needsToCreateProject) {
      const docRef = doc(db, "users", userInfo.uid);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        await updateDoc(docRef, {
          needsToCreateProject: false,
        });
      }
    }
    navigate("/", {
      state: {
        justCreatedProject: true,
        justCreatedProjectEnqueue: true,
        logo: logo ?? "",
        analysisId: cachedProjectId ?? "",
      },
    });
  };

  /**
   * Uploads the user's project to Firestore
   * @returns {Promise<void>}
   */
  const uploadProjectToFirestore = () => {
    return new Promise(async (resolve, reject) => {
      try {
        if (cachedProjectId) return resolve(null);
        setLoading(true);
        const newDocRef = doc(collection(db, "projects"));

        setCachedProjectId(newDocRef.id);

        if (!userLinks[0].endsWith("/")) userLinks[0] += "/";

        // Make URLs safe
        let cleanedLinks = userLinks.map((link) => {
          if (!link.startsWith("http://") && !link.startsWith("https://")) {
            return `https://${link}`;
          }
          return link;
        });

        let rootUrl = cleanedLinks[0];
        let otherUrls = cleanedLinks.slice(1);

        const matomoRes = await addMatomoWebsite({
          rootUrl: rootUrl,
          name: projectName,
        });

        const siteId = !matomoRes ? -1 : matomoRes;
        setSiteid(siteId);

        // Create the project document in the database
        await setDoc(newDocRef, {
          registrationDate: new Date(),
          name: projectName,
          rootUrl: rootUrl,
          otherUrls: otherUrls,
          clientId: auth.currentUser.uid,
          analysisState: 0,
          awaitingAnalysis: true,
          orgId: selectedOrg.value,
          platform: "matomo",
          matomoId: siteId,
          validMatomo: siteId > 0 ? 1 : -1,
          ...(industry ? { industry: industry } : {}),
        });

        // Upload & update project image
        let imageLink =
          "https://firebasestorage.googleapis.com/v0/b/ux-pro.appspot.com/o/users%2Fblack-logo.png?alt=media&token=e05881ad-fe52-4cbb-9cb5-0e366b03a8f6";

        if (selectedImage !== "") {
          imageLink = await uploadFileToFirestore(
            "projects/" + newDocRef.id,
            selectedImage,
          );
        }

        setLogo(imageLink);

        await updateDoc(newDocRef, {
          imageLink: imageLink,
        });

        // Clear the SSO error & unset loading
        setSSOError();
        setLoading(false);

        await changeProjectOrg(newDocRef.id, selectedOrg.value, null);

        resolve({ id: newDocRef.id, img: imageLink });
      } catch (e) {
        toast.error(
          "An error occurred. Please let support know the name of the project.",
        );
        setLoading(false);
        reject(e);
      }
    });
  };

  const [userInDb, setUserInDb] = useState(false);

  // Handle project image upload
  useEffect(() => {
    if (!selectedImage) {
      setImagePreview(undefined);
      return;
    }

    const imageUrl = URL.createObjectURL(selectedImage);
    setImagePreview(imageUrl);

    // Free memory whenever this component is unmounted
    return () => URL.revokeObjectURL(imageUrl);
  }, [selectedImage]);

  const userInDBFunc = async () => {
    if (!auth.currentUser) return;
    setUserInDb(await userExists());
  };

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        userInDBFunc();
        return;
      } else {
        // V2 create project flow requires signed in user. Redirect to login if no user.
        navigate("/");
      }

      setIsSSO(false);
      setUserInDb(false);
    });
    userInDBFunc();
  }, []);

  const onSelectImage = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedImage(undefined);
      return;
    }

    setSelectedImage(e.target.files[0]);
  };

  const googleSignIn = async () => {
    try {
      const provider = new GoogleAuthProvider();
      await signInWithPopup(auth, provider);
      setIsSSO(true);
    } catch (e) {}

    setSSOError();
  };

  const paymentPlanRendering = useCallback(
    () =>
      paymentPlan || userInfo?.paymentPlan ? (
        <div className="payment-square-wrapper">
          {paymentSquare2(
            paymentPlan || userInfo?.paymentPlan,
            setPaymentModalIsOpen,
          )}
        </div>
      ) : null,
    [paymentSquare2, paymentPlan, userInfo?.paymentPlan],
  );

  /**
   * These are the carousel elements
   * @type {[{duration: number, stepName: string, subtitle: string, title: string, content: React.JSX.Element},{duration: number, subtitleWidth: string, stepName: string, subtitle: string, title: string, content: React.JSX.Element},{duration: number, stepName: string, subtitle: string, title: string, content: React.JSX.Element},...[{duration: number, stepName: string, subtitle: string, title: string, content: React.JSX.Element}]|*[]]}
   */
  const carouselElements = [
    {
      title: "Provide the main links to analyze",
      stepName: "Provide links",
      duration: 2,
      subtitle:
        "Enter the main URL(s) you want to analyse. You can only add URLs that are linked to your organisation's main domain.",
      className: "provide-links-container",
      contentWidth: userProjectsCount !== null ? "100%" : "504px",
      subtitleWidth: "449px",
      sidebar: <></>,
      content: (
        <>
          {userProjectsCount !== null ? (
            <>
              {userProjectsCount < maxUserProjectsCount ? (
                <>
                  <div>
                    <div
                      className="my-4 w-100 d-flex flex-column"
                      style={{ padding: "0px 1px" }}
                    >
                      {userLinks.map((link, i) => (
                        <div style={{ position: "relative", width: "504px" }}>
                          <CustomInput
                            key={i}
                            type="text"
                            placeholder={
                              i < 1
                                ? "Main domain of the project"
                                : "Other urls"
                            }
                            onChange={(e) => {
                              e.target.value = e.target.value.replace(
                                /^https?:\/\//,
                                "",
                              );
                              changeLinks(i, e.target.value, "edit");
                            }}
                            valueState={userLinks[i]}
                            isurlinput={"true"}
                          />
                          {i > 0 && (
                            <button
                              onClick={() => {
                                changeLinks(i, null, "delete");
                                setPagesAnalyzed(pagesAnalyzed - 1);
                              }}
                              type="button"
                              style={{
                                position: "absolute",
                                right: "-5px",
                                top: "10px",
                                backgroundColor: "transparent",
                              }}
                            >
                              <i className="fa-solid fa-xmark fs-20 fc-black"></i>
                            </button>
                          )}
                        </div>
                      ))}
                    </div>

                    {!userLinksValid && userLinks[0] && (
                      <div>
                        {errMsg && (
                          <div
                            className="password-mismatch-text-on"
                            style={{ marginTop: "-15px", marginLeft: "5px" }}
                          >
                            {errMsg}
                          </div>
                        )}
                      </div>
                    )}
                    <div
                      style={{
                        marginTop: errMsg ? "0px" : "-15px",
                        marginLeft: "5px",
                        color: "#6c757d",
                      }}
                    >
                      All URLs must belong to the same domain.
                    </div>
                    {pagesAnalyzed < maxPagesAnalyzed && (
                      <button
                        type="button"
                        variant="btn-secondary"
                        onClick={() => {
                          setUserLinks([...userLinks, ""]);
                          setUserLinksValid(false);
                          setPagesAnalyzed(pagesAnalyzed + 1);
                        }}
                        className="d-flex align-items-center btn-tertiary my-4"
                        style={{ width: "172px" }}
                        disabled={
                          !userLinks[userLinks.length - 1] ||
                          /^\s*$/.test(userLinks[0])
                        }
                      >
                        <HiPlus style={{ marginRight: "14px" }} />
                        Add another link
                      </button>
                    )}
                  </div>
                  <div
                    className="create-project-footer  d-flex justify-content-end my-4"
                    style={{ width: "504px" }}
                  >
                    <button
                      type="button"
                      onClick={() => setIndex(1)}
                      className="btn-primary d-flex align-items-center create-project-continue-btn"
                      disabled={userLinks.length === 0 || !userLinksValid}
                    >
                      Continue
                      <FiArrowRight
                        size="17.78px"
                        style={{
                          marginLeft: "12px",
                          marginTop: "2px",
                        }}
                      />
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <div className="fs-20">
                    You have reached the maximum number of projects for your
                    payment plan.
                  </div>
                  <div style={{ width: "160px" }}>
                    {backToDashboard(navigate)}
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              <Spinner style={{ width: "25px", height: "25px" }} />
            </>
          )}
        </>
      ),
    },
    {
      title: "Give crunchy details to your project",
      stepName: "Project infos",
      duration: 2,
      subtitle:
        "Give your project an identity by choosing a name and an illustrative image.",
      content: (
        <div style={{ height: "720px" }}>
          <div>
            <div
              className="w-100 d-flex flex-column"
              style={{
                padding: "0px 1px",
                marginTop: "32px",
                marginBottom: "32px",
              }}
            >
              <h4 className="fw-500 fs-18 lh-243">Project name</h4>
              <h5 className="project-name-caption s16-medium">
                Give your project a clear name. You may have several projects in
                the same company.
              </h5>
              <CustomInput
                valueState={projectName}
                type="text"
                placeholder="Give your project a name"
                onChange={(e) => setProjectName(e.target.value)}
                disabled={loading}
              />
            </div>

            <div>
              <div className="fs-18 fw-500 lh-243 mb-7">
                Organization of your project
              </div>
              <h5 className="project-name-caption s16-medium">
                This is the company your project belongs to
              </h5>
              <div
                style={{ minHeight: "80px", marginTop: "12px" }}
                className="fs-16 fw-500 lh-20 profile-text-container fc-grey lc-023 extend-left"
              >
                <div
                  className="no-margins-or-padding"
                  style={{ marginBottom: "0px" }}
                >
                  <div style={{ marginTop: "12px", width: "504px" }}>
                    <CustomDropdown
                      onChange={(option) => {
                        setSelectedOrg(option);
                      }}
                      value={selectedOrg}
                      placeholder={"Name of your organization"}
                      unstyled
                      options={orgOptions}
                      menuOpenState={orgMenuIsOpen}
                      setMenuOpenState={(e) => setOrgMenuIsOpen(e)}
                    />
                  </div>
                </div>

                <button
                  onClick={(e) => {
                    e.preventDefault();
                    if (userInfo.role !== "client") {
                      toast.error("Invited users cannot create Organizations");
                      return;
                    }
                    setAddOrgModalIsOpen(true);
                  }}
                  style={{
                    marginTop: "20.2px",
                    height: "44px",
                    width: "100px",
                    marginRight: "-20px",
                  }}
                  className="btn-secondary align-items-center"
                >
                  <div className="mp-btn-fix">Add new</div>
                </button>
              </div>
            </div>

            <div
              className="mt-6 w-100"
              style={{ marginTop: "32px", marginBottom: "32px" }}
            >
              <h4 className="fw-500 fs-18 lh-243">Industry</h4>
              <h5
                style={{
                  marginTop: "16px",
                  marginBottom: "16px",
                  width: "449px",
                }}
                className="project-name-caption s16-medium"
              >
                Select the industry your project belongs to. This will be used
                in metric calculations.
              </h5>
              <div
                className="no-margins-or-padding"
                style={{ marginBottom: "0px", color: "#1f1f24" }}
              >
                <CustomDropdown
                  options={industryOptions}
                  onChange={(option) =>
                    setIndustry(option.value === "" ? null : option.value)
                  }
                  value={
                    industryOptions.find((opt) => opt.value === industry) ||
                    null
                  }
                  placeholder="Select an industry"
                  unstyled
                  menuOpenState={industryOpen}
                  setMenuOpenState={(state) => setIndustryOpen(state)}
                />
              </div>
            </div>
            <div
              className="mb-4 w-100"
              style={{ padding: "0px 1px", marginTop: "32px" }}
            >
              <h4 className="fw-500 fs-18 lh-243">
                Logo / picture of your project
              </h4>
              <h5
                style={{
                  marginTop: "16px",
                  marginBottom: "16px",
                  width: "449px",
                }}
                className="project-name-caption s16-medium"
              >
                To be sure to have a good image, use jpeg or jpg of min 150x150
                px.
              </h5>

              {!selectedImage && (
                <>
                  <button
                    type="button"
                    className="btn-tertiary d-flex align-items-center my-3"
                    onClick={() =>
                      document.getElementById("project-image-upload").click()
                    }
                  >
                    <FiPlus className="me-3" size="18px" />
                    Upload image
                  </button>
                  <input
                    type="file"
                    id="project-image-upload"
                    style={{ display: "none" }}
                    onChange={onSelectImage}
                    accept=".jpeg, .jpg"
                  />
                </>
              )}
              {selectedImage && (
                <>
                  <button
                    type="button"
                    className="btn-tertiary d-flex align-items-center my-3"
                    disabled={loading}
                  >
                    <img
                      src={imagePreview}
                      alt="Project"
                      className="project-image-preview me-3"
                    />
                    <div className="project-image-name">
                      {selectedImage.name}
                    </div>
                    <FiX
                      className="ms-3"
                      size="18px"
                      onClick={() => setSelectedImage()}
                    />
                  </button>
                </>
              )}
            </div>
          </div>

          <div
            className="w-100 d-flex justify-content-end my-4"
            style={{ paddingBottom: "50px" }}
          >
            {!loading && (
              <button
                type="button"
                className="btn-secondary me-3"
                onClick={() => setIndex(index - 1)}
              >
                Back
              </button>
            )}
            <button
              type="button"
              onClick={async () => {
                uploadProjectToFirestore().then(() => {
                  setIndex(2);
                });
              }}
              className="btn-primary d-flex align-items-center create-project-continue-btn"
              disabled={
                loading ||
                [projectName].some((field) => /^\s*$/.test(field)) ||
                !selectedOrg
              }
            >
              {loading ? (
                <Spinner style={{ width: "25px", height: "25px" }} />
              ) : (
                <>
                  {projectStatus === "live" ? "Continue" : "Finish"}
                  <FiArrowRight
                    size="17.78px"
                    style={{ marginLeft: "12px", marginTop: "2px" }}
                  />
                </>
              )}
            </button>
          </div>
        </div>
      ),
    },
    ...(projectStatus === "live"
      ? [
          {
            title: "API Code",
            stepName: "API Code",
            duration: 2,
            subtitle:
              "To track events on your website, we need to install our script on your website.",
            content:
              siteid === "-1" || siteid === -1 ? (
                <>
                  <h3>
                    There was an error setting up analytics. Please reach out to
                    support with the name of your project.
                  </h3>
                  <div
                    className="col d-flex justify-content-end my-4"
                    style={{ width: "600px" }}
                  >
                    <button
                      type="button"
                      className="btn-secondary me-3"
                      onClick={() => setIndex(index - 1)}
                    >
                      Back
                    </button>
                    <button
                      type="button"
                      onClick={async () => {
                        if (cachedProjectId && logo) {
                          await completeProjectCreation();
                        }
                      }}
                      disabled={loading || !cachedProjectId || !logo}
                      className="btn-primary d-flex align-items-center"
                    >
                      Finish
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <div>
                    <div style={{ marginTop: "16px" }}>
                      <div
                        className="no-margins-or-padding"
                        style={{ marginBottom: "0px" }}
                      >
                        {/* <div style={{ marginTop: "32px", width: "504px" }}>
                          <CustomDropdown
                            unstyled
                            placeholder="Type of cookie consent"
                            options={cookieOptions}
                            onChange={(option) => {
                              setCookie(option);
                            }}
                            value={cookie}
                            menuOpenState={cookieOpen}
                            setMenuOpenState={(state) => {
                              setCookieOpen(state);
                            }}
                          />
                        </div>*/}
                      </div>

                      <div>
                        {cookie.value === "onetrust" && (
                          <>
                            <div>
                              <CustomInput
                                valueState={codeFromOneTrust}
                                type="text"
                                placeholder="Code from your OneTrust"
                                onChange={(e) =>
                                  setCodeFromOneTrust(e.target.value)
                                }
                                checkmark={!!codeFromOneTrust}
                              />
                            </div>
                            {!codeFromOneTrust && (
                              <div
                                style={{
                                  color: "red",
                                  fontSize: "14px",
                                  marginTop: "8px",
                                }}
                              >
                                Please enter your OneTrust code to proceed.
                              </div>
                            )}
                            <div
                              style={{
                                fontWeight: "500",
                                textDecoration: "underline",
                                textUnderlineOffset: "4px",
                                fontSize: "16px",
                                color: "#0000FF",
                                marginTop: "10px",
                              }}
                            >
                              <a
                                href="https://my.onetrust.com/s/article/UUID-8102e851-d860-d465-d8d6-b1d636d68eb9?language=en_US"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                How to find the ID for a group of cookies ?
                              </a>
                            </div>
                          </>
                        )}
                        <div
                          style={{ marginTop: "50px", marginBottom: "16px" }}
                          className="fw-700 fs-20 lh-243 txt-b"
                        >
                          Implement
                        </div>
                        <ShareAnalyticsScript
                          oneTrustCode={codeFromOneTrust}
                          type={cookie.value}
                          siteid={siteid}
                        />
                      </div>
                    </div>
                  </div>

                  <div
                    className="col d-flex justify-content-end my-4"
                    style={{ width: "600px" }}
                  >
                    <button
                      type="button"
                      className="btn-secondary me-3"
                      onClick={() => setIndex(index - 1)}
                    >
                      Back
                    </button>
                    <button
                      type="button"
                      onClick={async () => {
                        if (cachedProjectId && logo) {
                          await completeProjectCreation();
                        }
                      }}
                      disabled={loading || !cachedProjectId || !logo}
                      className="btn-primary d-flex align-items-center"
                    >
                      Finish
                    </button>
                  </div>
                </>
              ),
          },
        ]
      : []),
  ];

  return (
    <MainLayout isEmptyBar>
      <ToastContainer />
      <AddOrganizationModal
        isOpen={addOrgModalIsOpen}
        newOrgName={newOrgName}
        setNewOrgName={setNewOrgName}
        setAddOrgModalIsOpen={setAddOrgModalIsOpen}
        setOrgOptions={setOrgOptions}
        setSelectedOrg={setSelectedOrg}
      />
      <PaymentModal
        isOpen={paymentModalIsOpen}
        setIsOpen={setPaymentModalIsOpen}
        setPaymentPlan={setPaymentPlan}
        closable={userInfo?.paymentPlan ? true : false}
        {...(userInfo ? { userInfo, setUserInfo } : {})}
      />
      <div style={{ paddingLeft: "190px" }}>
        <Form>
          <CustomCarousel elements={carouselElements} index={index} />
        </Form>
      </div>
    </MainLayout>
  );
}

export default CreateProject;
