import "./Personas.css";
import { Spinner } from "react-bootstrap";
import { useState, useEffect } from "react";
import { fetchSingleFlagIcon } from "../../helpers";
import trendUp from "../../assets/trend-up.png";
import trendDown from "../../assets/trend-down.png";

export function PersonaSummary({ selectedPersona, loading }) {
  const [keyNumbers, setKeyNumbers] = useState([]);

  async function processKeyNumbers() {
    const country = selectedPersona["country_most_common_<lambda>"] ?? null;
    let flagIcon = "";

    if (country) {
      flagIcon = await fetchSingleFlagIcon(country);
    }

    const countryHtml = flagIcon ? (
      <div>
        <img
          src={`https://flagcdn.com/16x12/${flagIcon}.png`}
          width="15"
          height="10"
          style={{ marginBottom: "6px" }}
          alt={country}
        />{" "}
        <span>{country}</span>
      </div>
    ) : (
      <span>{country}</span>
    );

    setKeyNumbers([
      {
        title: "Sessions",
        data: selectedPersona.n_visitors,
        ofTotal: (
          <div>
            <img
              src={trendUp}
              width="15.24"
              height="10"
              style={{ marginBottom: "3px" }}
              alt="trendline"
            />{" "}
            {`${selectedPersona.percentage_visitors}% of total`}
          </div>
        ),
      },
      {
        title: "Main country",
        data: <div>{countryHtml}</div>,
      },
    ]);
  }

  useEffect(() => {
    if (selectedPersona) {
      processKeyNumbers();
    }
  }, [selectedPersona]);

  if (loading) {
    return (
      <div className="hc mt-100">
        <Spinner style={{ height: "70px", width: "70px" }} />
      </div>
    );
  }

  if (selectedPersona) {
    return (
      <div>
        <div className="mt-50 mb-100">
          <div className="persona-summary fw-500 fs-16 lh-20 fc-black">
            <div className="fc-grey fs-14 fw-500">Persona summary</div>
            <div className="mt-16 persona-summary-text fw-400 wrapword">
              {selectedPersona.gpt_summary}
            </div>
          </div>
        </div>

        <div className="mb-100">
          <div className="fw-700 fs-20 fc-black mb-20">Key Numbers</div>
          <div className="key-number-container">
            {keyNumbers.map((keyNumber, index) => (
              <div key={index} className="key-number-box">
                <div className="key-number-title fs-16 lh-224 fc-black fw-500">
                  {keyNumber.title}
                </div>
                <div className="key-number-data fs-18 lh-252 fc-black2 fw-900">
                  {keyNumber.data}
                </div>
                <div className="key-number-of-total fs-14 lh-196 fc-grey fw-500 mt-5">
                  {keyNumber.ofTotal}
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="fs-20 vhc mt-100">Work in progress!</div>
      </div>
    );
  } else {
    return <div className="fs-20 vhc mt-100">No persona selected</div>;
  }
}
