import MainLayout from "../MainLayout";
import { auth, db } from "../../firebase";
import { useEffect, useState, useMemo } from "react";
import { UserInfoContext } from "../../contexts";
import "./Profile.css";
import "../UxChecklist/UxChecklist.css";
import CustomInput from "../CustomInput/CustomInput";
import CustomDropdown from "../CustomDropdown/CustomDropdown";
import { FiArrowLeft, FiX } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import {
  getUserInformation,
  uploadFileToFirestore,
  getUserProjects,
  deleteUserDataGCP,
  saveNewUserEmail,
  updateOrDeleteOrgMember,
  removeUserFromAllOrgs,
  getCountryOptions,
} from "../../helpers";
import { Spinner, Tooltip, OverlayTrigger } from "react-bootstrap";
import {
  doc,
  updateDoc,
  getDocs,
  query,
  collection,
  where,
  deleteDoc,
  getDoc,
} from "firebase/firestore";
import {
  updateEmail,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import Modal from "react-modal";
import { BsXCircle } from "react-icons/bs";
import LUXI from "../../assets/LUXI.png";

function ProfileContent({
  setUserInfo,
  userInfo,
  user,
  setDeleteUserConfirmModalIsOpen,
  password,
  setPassword,
  recentlyChangedEmail,
  setRecentlyChangedEmail,
  setSavingToDb,
  savingToDb,
  isGoogleAccount,
}) {
  const [firstName, setFirstName] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [email, setEmail] = useState("");

  const [emailPassword, setEmailPassword] = useState("");

  const [firstNameValid, setFirstNameValid] = useState(true);
  const [jobTitleValid, setJobTitleValid] = useState(true);
  const [emailValid, setEmailValid] = useState(true);

  const [country, setCountry] = useState(null);
  const [city, setCity] = useState(null);
  const [language, setLanguage] = useState(null);

  const [isCountryOpen, setIsCountryOpen] = useState(false);
  const [isLanguageOpen, setIsLanguageOpen] = useState(false);
  const [isCityOpen, setIsCityOpen] = useState(false);

  const [countryOptions, setCountryOptions] = useState([]);
  const [languageOptions, setLanguageOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [countryCitiesMap, setCountryCitiesMap] = useState({});

  const [showAboutSaveBtn, setShowAboutSaveBtn] = useState(false);
  const [showEmailSaveBtn, setShowEmailSaveBtn] = useState(false);
  const [showLocationSaveBtn, setShowLocationSaveBtn] = useState(false);
  const [showLanguageSaveBtn, setShowLanguageSaveBtn] = useState(false);

  const [showDeleteAccount, setShowDeleteAccount] = useState(false);

  const [selectedImage, setSelectedImage] = useState("");
  const [imagePreview, setImagePreview] = useState("");

  const [didPfpChange, setDidPfpChange] = useState(false);

  const saveToDb = async (fields) => {
    setSavingToDb(true);
    const userDoc = doc(db, "users", auth.currentUser.uid);
    await updateDoc(userDoc, fields).then(() => {
      setSavingToDb(false);
      toast.success("Saved!");
    });
  };

  const handleAboutSaveClick = async () => {
    let imageLink =
      "https://firebasestorage.googleapis.com/v0/b/ux-pro.appspot.com/o/users%2FLUXI.png?alt=media&token=869c5321-74db-4f0e-a67d-690c30d8e985";

    if (didPfpChange) {
      if (selectedImage) {
        imageLink = await uploadFileToFirestore(
          "users/" + auth.currentUser.uid,
          selectedImage,
        );
      }

      await saveToDb({
        firstName: firstName,
        jobTitle: jobTitle,
        pfp: imageLink,
      });
      setUserInfo({
        ...userInfo,
        firstName: firstName,
        jobTitle: jobTitle,
        pfp: imageLink,
      });
      setSelectedImage(selectedImage);
    } else {
      await saveToDb({
        firstName: firstName,
        jobTitle: jobTitle,
      });
      setUserInfo({
        ...userInfo,
        firstName: firstName,
        jobTitle: jobTitle,
      });
    }
    setDidPfpChange(false);
  };

  const handleEmailSaveClick = async () => {
    const currentEmail = recentlyChangedEmail ?? user.email;
    // New email is just "email"
    setSavingToDb(true);
    signInWithEmailAndPassword(auth, currentEmail, emailPassword)
      .then((cred) => {
        if (currentEmail === email) {
          toast.warn("You entered the same email. Please try again.");
          setSavingToDb(false);
          return;
        }
        updateEmail(cred.user, email)
          .then(() => {
            saveNewUserEmail(userInfo.uid, email);
            setSavingToDb(false);
            toast.success("Email updated successfully!");
            setRecentlyChangedEmail(email);
            setUserInfo({
              ...userInfo,
              email: email,
            });
          })
          .catch((error) => {
            if (error.code === "auth/email-already-in-use") {
              toast.error("Email already in use. Please try again.");
            }
            setSavingToDb(false);
          });
      })
      .catch((error) => {
        if (error.code === "auth/wrong-password") {
          toast.error(
            "Wrong password. Please try again. If you are a google user, you cannot change email.",
            { autoClose: 10000 },
          );
        } else {
          toast.error("An error occurred. Please try again.");
        }
        setSavingToDb(false);
      });
  };

  const handleLocationSaveClick = () => {
    saveToDb({
      country: country.value,
      city: city.value,
    });
  };
  const handleLanguageSaveClick = () => {
    saveToDb({
      language: language.value,
    });
  };

  // Validation handlers
  const onFirstNameChangeHandler = (value) => {
    setFirstNameValid(value.length > 0);
    setFirstName(value);
    setShowAboutSaveBtn(true);
  };

  const onJobTitleChangeHandler = (value) => {
    setJobTitleValid(value.length > 0);
    setJobTitle(value);
    setShowAboutSaveBtn(true);
  };

  const onEmailChangeHandler = (value) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    setEmailValid(value.length > 0 && emailPattern.test(value));
    setEmail(value);
    setShowEmailSaveBtn(true);
  };

  useEffect(() => {
    setFirstName(userInfo?.firstName ?? "");
    setJobTitle(userInfo?.jobTitle ?? "");
    setEmail(userInfo?.email || user?.email || "");
    setSelectedImage(userInfo?.pfp || "");

    if (userInfo?.country && !country) {
      const country = countryOptions?.find(
        (option) => option?.value === userInfo?.country,
      );

      setCountry(country || null);
    }
    if (userInfo?.language && language != userInfo?.language) {
      const lang = languageOptions?.find(
        (option) =>
          option?.value === userInfo?.language ||
          option?.label === userInfo?.language ||
          option?.languageCode === userInfo?.language,
      );

      setLanguage(lang || "en");
    } else {
      const lang = languageOptions?.find(
        (option) =>
          option?.value === "en" ||
          option?.label === "en" ||
          option?.languageCode === "en",
      );

      setLanguage(lang || "");
    }

    const city = cityOptions?.find(
      (option) => option?.value === userInfo?.city,
    );
    setCity(city || null);
  }, [userInfo, cityOptions, country, countryOptions]);

  useEffect(() => {
    setCityOptions(countryCitiesMap[country?.value]);
  }, [country]);

  function countryCodeToFlag(countryCode) {
    return (
      countryCode
        .toUpperCase()
        .replace(/./g, (char) =>
          String.fromCodePoint(char.charCodeAt(0) + 127397),
        ) + "  "
    );
  }

  const getCountriesAndCities = async () => {
    const country_info = await fetch(
      "https://countriesnow.space/api/v0.1/countries",
    );

    const country_data = await country_info.json();
    setCountryOptions(getCountryOptions(country_data.data));

    const countryCitiesMapTemp = {};
    for (const { country, cities } of country_data.data) {
      countryCitiesMapTemp[country] = cities.map((city) => {
        return { label: city, value: city };
      });
    }

    setCountryCitiesMap(countryCitiesMapTemp);
  };

  const getLanguages = async () => {
    setLanguageOptions([
      {
        label: (
          <div className="country-dropdown-option">
            <img
              src={`https://flagcdn.com/16x12/gb.png`}
              width="16"
              height="15"
              alt={"british flag"}
            />
            <span> {"English British"}</span>
          </div>
        ),
        value: "English British",
        languageCode: "en",
      },
    ]);
  };

  useEffect(() => {
    getCountriesAndCities();
    getLanguages();
  }, []);

  const onSelect = (option, menuType) => {
    switch (menuType) {
      case "country":
        setShowLocationSaveBtn(true);
        setCountry(option);
        setCity(null); // If country changes, cannot set city from previous country so reset.
        break;
      case "city":
        setShowLocationSaveBtn(true);
        setCity(option);
        break;
      case "language":
        setShowLanguageSaveBtn(true);
        setLanguage(option);
        break;
      default:
        break;
    }
  };

  // Handle pfp image upload
  useEffect(() => {
    if (!selectedImage) {
      setImagePreview("");
      return;
    }

    let imageUrl;

    if (
      typeof selectedImage === "string" &&
      (selectedImage.startsWith("http://") ||
        selectedImage.startsWith("https://"))
    ) {
      imageUrl = selectedImage;
    } else {
      imageUrl = URL.createObjectURL(selectedImage);
    }
    setImagePreview(imageUrl);

    // Free memory whenever this component is unmounted
    return () => URL.revokeObjectURL(imageUrl);
  }, [selectedImage]);

  const onSelectImage = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedImage(undefined);
      return;
    }

    setSelectedImage(e.target.files[0]);
    setShowAboutSaveBtn(true);
    setDidPfpChange(true);
  };

  const languageSection = useMemo(() => {
    return (
      <div
        style={{
          paddingBottom: "50px",
        }}
        className="extend-left"
        onMouseLeave={
          savingToDb ? () => {} : () => setShowLanguageSaveBtn(false)
        }
      >
        <div
          style={{
            width: "72px",
            minHeight: "24px",
          }}
          className="fs-18 fw-700 lh-243"
        >
          Language
        </div>
        <div
          style={{ minHeight: "20px", marginTop: "12px" }}
          className="fs-16 fw-500 lh-20 profile-text-container fc-grey lc-023"
        >
          <p>
            Choose your prefered language for your all your products results
          </p>
        </div>
        <div>
          <div
            style={{
              minHeight: "46px",
              marginTop: "24.17px",
            }}
            className="profile-width-input"
          >
            <div className="row">
              <CustomDropdown
                options={languageOptions}
                onChange={(option) => onSelect(option, "language")}
                value={language}
                placeholder="Language"
                unstyled
                menuOpenState={isLanguageOpen}
                setMenuOpenState={setIsLanguageOpen}
              />
            </div>
          </div>

          {showLanguageSaveBtn && (
            <button
              onClick={handleLanguageSaveClick}
              disabled={savingToDb}
              style={{
                marginTop: "20.2px",
                height: "44px",
                width: "66px",
              }}
              className="btn-primary d-flex align-items-center"
            >
              {savingToDb ? (
                <Spinner style={{ width: "25px", height: "25px" }} />
              ) : (
                <>Save</>
              )}
            </button>
          )}
        </div>
      </div>
    );
  }, [
    showLanguageSaveBtn,
    isLanguageOpen,
    savingToDb,
    language,
    languageOptions,
  ]);

  return (
    <div style={{ height: "100vh", display: "flex", flexGrow: 1 }}>
      <div className="profile-main-content">
        <div style={{ marginLeft: "50.36px" }}>
          <div style={{ marginTop: "35.5px" }}>
            <a href={"/"}>
              <div
                style={{
                  width: "158px",
                  height: "24px",
                  textAlign: "center",
                }}
                className="fs-14 fw-700 fc-black"
              >
                <FiArrowLeft
                  color="#000"
                  size="24px"
                  style={{ marginRight: "10px", marginBottom: "2px" }}
                />
                Back to Dashboard
              </div>
            </a>
            <div
              style={{ width: "194px", height: "24px", marginTop: "61.55px" }}
              className="fs-24 fw-700 lh-243 "
            >
              Account & Profile
            </div>
            <div className="extend-left">
              <div
                style={{
                  width: "86px",
                  height: "24px",
                  marginTop: "68.78px",
                }}
                className="fs-18 fw-700"
              >
                About you
              </div>
              <div
                style={{
                  maxWidth: "504px",
                  marginTop: "12px",
                  paddingBottom: "64px",
                }}
              >
                <div
                  style={{ minHeight: "80px" }}
                  className="fs-16 fw-500 lh-20 profile-text-container fc-grey lc-023"
                >
                  <p> It is always nicer to have a name on a face.</p>
                  <p> But for now we don’t know yours.</p>
                  <p>
                    This name will appear on your Dashboard and communication
                  </p>
                  <p> channels.</p>
                </div>

                <div
                  style={{
                    minHeight: "108px",
                    marginTop: "12px",
                  }}
                >
                  <CustomInput
                    placeholder="First name"
                    containerClassName="observation-text-input-container profile-width-input"
                    inputClassNames="profile-width-input"
                    className={firstName === "" ? "active" : ""}
                    valueState={firstName}
                    onChange={(e) => onFirstNameChangeHandler(e.target.value)}
                    value={firstName}
                    maxLength={50}
                  />
                  {!firstNameValid && (
                    <div className="password-mismatch-text-on">
                      First name cannot be empty
                    </div>
                  )}
                  <CustomInput
                    placeholder="Job title"
                    containerClassName="observation-text-input-container profile-width-input"
                    inputClassNames="profile-width-input"
                    className={jobTitle === "" ? "active" : ""}
                    valueState={jobTitle}
                    onChange={(e) => onJobTitleChangeHandler(e.target.value)}
                    value={jobTitle}
                    maxLength={50}
                  />
                  {!jobTitleValid && (
                    <div className="password-mismatch-text-on">
                      Job title cannot be empty
                    </div>
                  )}
                  <div style={{ marginTop: "24.29px" }} className="fs-16">
                    {!selectedImage && (
                      <div>
                        <button
                          type="button"
                          className="change-pfp-btn-profile column-fixed"
                          onClick={() =>
                            document
                              .getElementById("project-image-upload")
                              .click()
                          }
                        >
                          <img
                            src={LUXI}
                            alt="Project"
                            className="profile-pfp-image-preview"
                          />
                          <div
                            className="column-flex"
                            style={{ marginLeft: "14px" }}
                          >
                            Select a picture for your profile
                            <p className="fc-grey p-m0">
                              By default, you will have a nice picture of LUXI
                            </p>
                          </div>
                        </button>
                        <input
                          type="file"
                          id="project-image-upload"
                          style={{ display: "none" }}
                          onChange={onSelectImage}
                          accept="image/*"
                        />
                      </div>
                    )}
                    {selectedImage && (
                      <div>
                        <button
                          type="button"
                          disabled={savingToDb}
                          className="change-pfp-btn-profile column-fixed"
                        >
                          <img
                            src={imagePreview}
                            alt="Project"
                            className="profile-pfp-image-preview"
                          />
                          <div
                            className="column-flex"
                            style={{ marginLeft: "14px" }}
                          >
                            Select a picture for your profile
                            <p className="fc-grey p-m0">
                              By default, you will have a nice picture of LUXI
                            </p>
                          </div>
                          <FiX
                            className="ms-3"
                            size="18px"
                            onClick={() => {
                              setSelectedImage("");
                              setShowAboutSaveBtn(true);
                              setDidPfpChange(true);
                            }}
                          />
                        </button>
                      </div>
                    )}
                  </div>
                </div>
                {showAboutSaveBtn && (
                  <button
                    onClick={handleAboutSaveClick}
                    disabled={!firstNameValid || !jobTitleValid || savingToDb}
                    style={{
                      marginTop: "20.2px",
                      height: "44px",
                      width: "66px",
                    }}
                    className="btn-primary d-flex align-items-center"
                  >
                    {savingToDb ? (
                      <Spinner style={{ width: "25px", height: "25px" }} />
                    ) : (
                      <>Save</>
                    )}
                  </button>
                )}
              </div>
            </div>
            <div
              style={{
                paddingBottom: "64px",
              }}
              className="extend-left"
              onMouseLeave={
                savingToDb
                  ? () => {}
                  : () => {
                      setShowEmailSaveBtn(false);
                    }
              }
            >
              <div
                style={{
                  width: "128px",
                  height: "24px",
                }}
                className="fs-18 fw-700 lh-243"
              >
                Email address
              </div>
              <div
                style={{ minHeight: "20px", marginTop: "12px" }}
                className="fs-16 fw-500 lh-20 fc-grey lc-023"
              >
                Your current email address is{" "}
                <span style={{ color: "var(--main-blue)" }}>
                  {userInfo?.email || user.email}
                </span>
              </div>
              <div>
                {isGoogleAccount && (
                  <div style={{ minHeight: "60px", marginTop: "14.17px" }}>
                    <OverlayTrigger
                      key="top"
                      placement="top"
                      overlay={
                        <Tooltip id={`tooltip-top`}>
                          Google users cannot change their email. Please contact
                          support.
                        </Tooltip>
                      }
                    >
                      <div className="profile-width-input">
                        <CustomInput
                          placeholder={email}
                          containerClassName="observation-text-input-container profile-width-input"
                          inputClassNames="profile-width-input disabled-input"
                          className={""}
                          valueState={""}
                          onChange={(e) => onEmailChangeHandler(e.target.value)}
                          value={""}
                          disabled={isGoogleAccount}
                        />
                      </div>
                    </OverlayTrigger>
                  </div>
                )}

                {!isGoogleAccount && (
                  <div style={{ minHeight: "60px", marginTop: "14.17px" }}>
                    <div className="profile-width-input">
                      <CustomInput
                        placeholder="Professional email address"
                        containerClassName="observation-text-input-container profile-width-input"
                        inputClassNames="profile-width-input"
                        className={email === "" ? "active" : ""}
                        valueState={email}
                        onChange={(e) => onEmailChangeHandler(e.target.value)}
                        value={email}
                        disabled={isGoogleAccount}
                      />
                    </div>
                    {!emailValid && (
                      <div className="password-mismatch-text-on">
                        Invalid email format
                      </div>
                    )}
                  </div>
                )}

                {showEmailSaveBtn && (
                  <>
                    {!isGoogleAccount && (
                      <>
                        <div
                          style={{ minHeight: "20px", marginTop: "20px" }}
                          className="fs-16 fw-500 lh-20 profile-text-container fc-grey lc-023"
                        >
                          <p>
                            To perform this action you must enter your password.
                          </p>
                        </div>
                        <div style={{ minHeight: "60px", marginTop: "5px" }}>
                          <form>
                            <CustomInput
                              placeholder="Password"
                              type="password"
                              containerClassName="observation-text-input-container profile-width-input"
                              inputClassNames="profile-width-input"
                              className={emailPassword === "" ? "active" : ""}
                              valueState={emailPassword}
                              onChange={(e) => setEmailPassword(e.target.value)}
                              value={emailPassword}
                            />
                          </form>
                        </div>
                        <button
                          onClick={handleEmailSaveClick}
                          disabled={!emailValid || savingToDb || !emailPassword}
                          style={{
                            marginTop: "20.2px",
                            height: "44px",
                            width: "66px",
                          }}
                          className="btn-primary d-flex align-items-center"
                        >
                          {savingToDb ? (
                            <Spinner
                              style={{ width: "25px", height: "25px" }}
                            />
                          ) : (
                            <>Save</>
                          )}
                        </button>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
            <div
              style={{
                paddingBottom: "50px",
              }}
              className="extend-left"
              onMouseLeave={
                savingToDb ? () => {} : () => setShowLocationSaveBtn(false)
              }
            >
              <div
                style={{
                  width: "72px",
                  minHeight: "24px",
                }}
                className="fs-18 fw-700 lh-243"
              >
                Location
              </div>
              <div
                style={{ minHeight: "20px", marginTop: "12px" }}
                className="fs-16 fw-500 lh-20 profile-text-container fc-grey lc-023"
              >
                <p>
                  This parameter help us to identify the closest support for
                  you.
                </p>
              </div>
              <div>
                <div
                  style={{
                    minHeight: "46px",
                    marginTop: "24.17px",
                  }}
                  className="profile-width-input"
                >
                  <div className="row">
                    <CustomDropdown
                      options={countryOptions}
                      onChange={(option) => onSelect(option, "country")}
                      value={country}
                      placeholder="Country"
                      unstyled
                      menuOpenState={isCountryOpen}
                      setMenuOpenState={setIsCountryOpen}
                    />
                  </div>
                  <div
                    className="row"
                    style={{
                      marginTop: "2px", // This will turn into 12
                    }}
                  >
                    <CustomDropdown
                      options={cityOptions}
                      onChange={(option) => onSelect(option, "city")}
                      value={city}
                      placeholder="City"
                      unstyled
                      menuOpenState={isCityOpen}
                      setMenuOpenState={setIsCityOpen}
                    />
                  </div>
                </div>

                {showLocationSaveBtn && (
                  <button
                    onClick={handleLocationSaveClick}
                    disabled={savingToDb}
                    style={{
                      marginTop: "20.2px",
                      height: "44px",
                      width: "66px",
                    }}
                    className="btn-primary d-flex align-items-center"
                  >
                    {savingToDb ? (
                      <Spinner style={{ width: "25px", height: "25px" }} />
                    ) : (
                      <>Save</>
                    )}
                  </button>
                )}
              </div>
            </div>

            {languageSection}

            <div
              style={{
                paddingBottom: "50px",
              }}
              className="extend-left"
              onMouseLeave={
                savingToDb
                  ? () => {}
                  : () => {
                      setShowDeleteAccount(false);
                    }
              }
            >
              <div
                style={{ width: "157px", height: "24px" }}
                className="fs-18 fw-700 lh-243"
              >
                Delete my account
              </div>
              <div
                style={{
                  minHeight: "20px",
                  maxWidth: "660px",
                  marginTop: "22px",
                }}
                className="fs-16 fw-500 lh-20 profile-text-container fc-grey lc-023"
              >
                <p>This is a permanent and irreversible action.</p>
                <p>All data linked to your projects will be deleted.</p>
              </div>

              {showDeleteAccount && (
                <>
                  {!isGoogleAccount && (
                    <>
                      <div
                        style={{ minHeight: "20px", marginTop: "20px" }}
                        className="fs-16 fw-500 lh-20 profile-text-container fc-grey lc-023"
                      >
                        <p>
                          To perform this action you must enter your password.
                        </p>
                      </div>
                      <div style={{ minHeight: "60px", marginTop: "5px" }}>
                        <form>
                          <CustomInput
                            placeholder="Password"
                            type="password"
                            containerClassName="observation-text-input-container profile-width-input"
                            inputClassNames="profile-width-input"
                            className={password === "" ? "active" : ""}
                            valueState={password}
                            onChange={(e) => setPassword(e.target.value)}
                            value={password}
                          />
                        </form>
                      </div>
                    </>
                  )}
                </>
              )}

              <button
                type="button"
                onClick={() => {
                  showDeleteAccount || isGoogleAccount
                    ? setDeleteUserConfirmModalIsOpen(true)
                    : setShowDeleteAccount(true);
                }}
                style={{
                  marginTop: "40.6px",
                  height: "44px",
                  width: "130px",
                  marginBottom: "92.43px",
                }}
                className="btn-primary d-flex align-items-center"
              >
                Delete account
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default function Profile({ userInfo, setUserInfo, ...props }) {
  const [hasUserInfo, setHasUserInfo] = useState(false);
  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();

  const [password, setPassword] = useState("");
  const [recentlyChangedEmail, setRecentlyChangedEmail] = useState(null);
  const [savingToDb, setSavingToDb] = useState(false);

  const [deleteUserConfirmModalIsOpen, setDeleteUserConfirmModalIsOpen] =
    useState(false);
  const [confirmDelete, setConfirmDelete] = useState("");
  const [deleteValid, setDeleteValid] = useState(false);
  const [isGoogleAccount, setIsGoogleAccount] = useState(false);

  const handleDeleteConfirmTextChange = (value) => {
    setConfirmDelete(value);
    setDeleteValid(value === "DELETE");
  };

  const deleteReportsInsightsActions = async (projectId) => {
    const reportsQuery = query(
      collection(db, "reports"),
      where("projectId", "==", projectId),
    );
    // Get all reports of the project
    const reportsSnapshot = await getDocs(reportsQuery);
    const reportPromises = reportsSnapshot.docs.map(async (reportDoc) => {
      const reportId = reportDoc.id;
      const insightsQuery = collection(db, "reports", reportId, "insights");

      // Get all insights of the report
      const insightsSnapshot = await getDocs(insightsQuery);

      const insightPromises = insightsSnapshot.docs.map(async (insightDoc) => {
        const insightId = insightDoc.id;

        // Get the all actions of the insight
        const actionsQuery = collection(
          db,
          "reports",
          reportId,
          "insights",
          insightId,
          "actions",
        );
        const actionsSnapshot = await getDocs(actionsQuery);

        const actionPromises = actionsSnapshot.docs.map((actionDoc) =>
          deleteDoc(
            doc(
              db,
              "reports",
              reportId,
              "insights",
              insightId,
              "actions",
              actionDoc.id,
            ),
          ),
        );

        // Wait for all actions to be deleted before deleting the insight
        await Promise.all(actionPromises);
        await deleteDoc(doc(db, "reports", reportId, "insights", insightId));
      });

      // Wait for all insights to be deleted before deleting the report
      await Promise.all(insightPromises);
      await deleteDoc(doc(db, "reports", reportId));
    });

    // Wait for all reports to be deleted before deleting the project
    await Promise.all(reportPromises);
  };

  async function deleteUserInsightsAndInsightsFast(projectId) {
    const deleteFromCollection = async (collectionName) => {
      const querySnapshot = await getDocs(
        query(
          collection(db, collectionName),
          where("projectId", "==", projectId),
        ),
      );
      await Promise.all(querySnapshot.docs.map((doc) => deleteDoc(doc.ref)));
    };

    await Promise.all([
      deleteFromCollection("insights"),
      deleteFromCollection("insightsFast"),
    ]);
  }

  const handleDeleteAccount = async () => {
    setSavingToDb(true);
    const currentEmail = recentlyChangedEmail ?? user.email;

    if (!isGoogleAccount) {
      try {
        await signInWithEmailAndPassword(auth, currentEmail, password);
      } catch (error) {
        toast.error("Incorrect password. Account deletion aborted.");
        setSavingToDb(false);
        return;
      }
    }
    const projectIds = [];
    if (userInfo.role === "client") {
      // Get all projects of the user
      const projectsSnapshot = await getDocs(
        query(
          collection(db, "projects"),
          where("clientId", "==", auth.currentUser.uid),
        ),
      );
      const projectPromises = projectsSnapshot.docs.map(async (projectDoc) => {
        const projectId = projectDoc.id;
        projectIds.push(projectId);
        // await deleteReportsInsightsActions(projectId); // Delete legacy data
        await deleteUserInsightsAndInsightsFast(projectId);
        await deleteDoc(doc(db, "projects", projectId));
        await Promise.all(projectPromises);
      });
    } else {
      await removeUserFromAllOrgs(userInfo.uid);
    }

    // Delete chatrooms, messages, notifications, metrics, rulescheckresults
    // Don't wait for response
    deleteUserDataGCP({ uid: userInfo.uid, pids: projectIds });

    // Wait for all projects to be deleted before deleting the user
    // Final deletion, do this last
    await deleteDoc(doc(db, "users", auth.currentUser.uid)).then(() => {
      if (isGoogleAccount) {
        const provider = new GoogleAuthProvider();
        signInWithPopup(auth, provider).then((cred) => {
          cred.user
            .delete()
            .then(function () {
              navigate("/");
            })
            .catch(function (error) {
              setSavingToDb(false);
            });
        });
      } else {
        signInWithEmailAndPassword(auth, currentEmail, password).then(
          (cred) => {
            cred.user
              .delete()
              .then(function () {
                navigate("/");
              })
              .catch(function (error) {
                setSavingToDb(false);
              });
          },
        );
      }
    });
  };

  const handleConfirmDeleteAccountBtnClick = () => {
    handleDeleteAccount();
  };

  useEffect(() => {
    const fetchUserInformation = async () => {
      if (user) {
        setHasUserInfo(true);
        getUserInformation().then((info) => {
          setUserInfo(info);
          setIsGoogleAccount(info?.isGoogleAccount);
        });
      }
    };
    if (!hasUserInfo) fetchUserInformation();
  }, [user]);

  useEffect(() => {
    if (
      !loading &&
      (!user || (user && userInfo && ["designer"].includes(userInfo.role)))
    ) {
      navigate("/");
    }
  }, [loading, userInfo, user]);

  /**
   * If the page is loading, or user info is loading, the Spinning wheel will be shown.
   */
  if (loading || (user && !userInfo?.role)) {
    return (
      <div
        style={{
          height: "100vh",
          width: "100vw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner style={{ height: "70px", width: "70px" }} />
      </div>
    );
  }

  if (user) {
    return (
      <>
        <ProfileContent
          userInfo={userInfo}
          user={user}
          setUserInfo={setUserInfo}
          setDeleteUserConfirmModalIsOpen={setDeleteUserConfirmModalIsOpen}
          password={password}
          setPassword={setPassword}
          recentlyChangedEmail={recentlyChangedEmail}
          setRecentlyChangedEmail={setRecentlyChangedEmail}
          setSavingToDb={setSavingToDb}
          savingToDb={savingToDb}
          isGoogleAccount={isGoogleAccount}
        >
          <ToastContainer />
        </ProfileContent>
        <Modal
          isOpen={deleteUserConfirmModalIsOpen}
          contentLabel="Delete project modal"
          className="delete-confirm-modal custom-modal modal-shape2"
          appElement={document.getElementById("root")}
          style={{
            overlay: {
              background: "rgb(42, 0, 255, 0.7)",
              backdropFilter: "blur(8px)",
              zIndex: 10,
            },
          }}
        >
          <div>
            <button
              className="d-flex justify-content-center align-items-center delete-confirm-x-btn"
              onClick={() => setDeleteUserConfirmModalIsOpen(false)}
            >
              <BsXCircle alt="X button" size="32px" color="#0F0C22" />
            </button>
          </div>
          <div className="text-center mt-3">
            <h3 className="mb-4">You are deleting your account</h3>
            <h5 className="mb-4">
              Enter “DELETE” to permanently delete your account.
            </h5>
          </div>

          <div className="d-flex justify-content-center align-items-center">
            <CustomInput
              placeholder="DELETE"
              containerClassName="observation-text-input-container delete-width-input"
              inputClassNames="delete-width-input"
              className={confirmDelete === "" ? "active" : ""}
              valueState={confirmDelete}
              onChange={(e) => handleDeleteConfirmTextChange(e.target.value)}
              value={confirmDelete}
            />
          </div>
          <div className="password-mismatch-text-on d-flex justify-content-center align-items-center">
            {!deleteValid &&
              confirmDelete !== "" &&
              'You did not enter "DELETE" correctly.'}
          </div>
          <div
            style={{ paddingBottom: "30px" }}
            className="row justify-content-center mb-5 d-flex align-items-center"
          >
            <button
              className="delete-confirm-btn btn-primary"
              onClick={handleConfirmDeleteAccountBtnClick}
              disabled={!deleteValid || savingToDb}
            >
              {savingToDb ? (
                <Spinner style={{ width: "25px", height: "25px" }} />
              ) : (
                <span style={{ color: "white" }}>Confirm</span>
              )}
            </button>
          </div>
        </Modal>
      </>
    );
  }
}
