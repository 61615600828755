import React from "react";
import { useState } from "react";
import { Button } from "react-bootstrap";

const CustomNavbar = ({
  tabItems,
  centered = false,
  hasHr = true,
  changeOnClick = true,
}) => {
  const [activeTab, setActiveTab] = useState(tabItems[0].name);

  return (
    <>
      <div
        className={centered ? "d-flex vhc" : "d-flex"}
        style={{ paddingBottom: "7px" }}
      >
        {tabItems.map((item) => {
          return (
            <div key={item.name}>
              <Button className="tab-item" key={item.name}>
                <div
                  onClick={() => {
                    item.onClick && item.onClick();
                    if (changeOnClick) {
                      setActiveTab(item.name);
                    }
                  }}
                  className={
                    activeTab === item.name
                      ? "fw-700 fc-blue fs-16"
                      : "fw-500 fc-black fs-16"
                  }
                  to={item.link}
                >
                  {item.label ?? item.name}
                </div>
              </Button>
              <div
                className="tab-bar-insights"
                style={{
                  background: activeTab === item.name ? "#2A00FF" : "none",
                  width: item.width ?? "75%",
                }}
              />
            </div>
          );
        })}
      </div>
      {hasHr && <hr style={{ marginTop: "-1px" }} />}
    </>
  );
};

export default CustomNavbar;
