import MainLayout from "../MainLayout";
import { auth, db } from "../../firebase";
import { useEffect, useState } from "react";
import { UserInfoContext } from "../../contexts";
import "../UxChecklist/UxChecklist.css";
import { FiArrowLeft, FiPlus } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import {
  getUserInformation,
  getUserProjects,
  paymentSquare,
} from "../../helpers";
import { Spinner, Tooltip, OverlayTrigger } from "react-bootstrap";
import { doc, updateDoc, getDoc } from "firebase/firestore";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import pdf from "../../assets/pdf.png";
import microsoft from "../../assets/microsoft.png";

import PaymentModal from "../PaymentModal/PaymentModal";

function PlanAndBillingsContent({
  setSavingToDb,
  savingToDb,
  userInfo,
  setPaymentModalIsOpen,
}) {
  const showBillings = false;

  const bills = [
    { date: "January 2024" },
    { date: "December 2023" },
    { date: "November 2023" },
    { date: "January 2024" },
    { date: "December 2023" },
    { date: "November 2023" },
    { date: "January 2024" },
    { date: "December 2023" },
    { date: "November 2023" },
    { date: "January 2024" },
    { date: "December 2023" },
    { date: "November 2023" },
    { date: "January 2099" },
    { date: "December 2099" },
    { date: "November 2099" },
  ];

  const [filteredBills, setFilteredBills] = useState([]);
  const [showAllBills, setShowAllBills] = useState(false);

  const [showMicrosoft, setShowMicrosoft] = useState(false);

  useEffect(() => {
    setFilteredBills(bills.slice(0, 12));
  }, []);

  useEffect(() => {
    if (showAllBills) {
      setFilteredBills(bills);
    } else {
      setFilteredBills(bills.slice(0, 12));
    }
  }, [showAllBills]);

  return (
    <div style={{ height: "100vh", display: "flex", flexGrow: 1 }}>
      <div className="profile-main-content">
        <div style={{ marginLeft: "50.36px" }}>
          <div style={{ marginTop: "35.5px" }}>
            <Link to={"/"}>
              <div
                style={{
                  width: "158px",
                  height: "24px",
                  textAlign: "center",
                }}
                className="fs-14 fw-700 fc-black"
              >
                <FiArrowLeft
                  color="#000"
                  size="24px"
                  style={{ marginRight: "10px", marginBottom: "2px" }}
                />
                Back to Dashboard
              </div>
            </Link>
            <div
              style={{
                height: "24px",
                marginTop: "61.55px",
                marginBottom: "100px",
              }}
              className="fs-24 fw-700 lh-243 "
            >
              Payment Plan
            </div>
          </div>

          {paymentSquare(userInfo?.paymentPlan, setPaymentModalIsOpen)}

          {showMicrosoft && (
            <div style={{ marginTop: "50px" }}>
              <div className="fs-18 fw-700 fc-black lh-243">Billings</div>
              <div
                className="fs-16 fw-500 fc-grey lh-20"
                style={{ marginTop: "12px", marginBottom: "32px" }}
              >
                Billings are linked to your Microsoft account.
              </div>
              <button
                className="btn-octonary fw-700"
                style={{
                  height: "50px",
                  paddingLeft: "28px",
                  paddingRight: "28px",
                }}
              >
                <img
                  src={microsoft}
                  alt="microsoft"
                  style={{ width: "20px", height: "20px", marginRight: "12px" }}
                ></img>
                Connect to Microsoft
              </button>
            </div>
          )}

          <div style={{ marginTop: "50px" }}>
            <div>
              {showBillings && (
                <>
                  <hr style={{ marginLeft: "-50px" }}></hr>
                  <div
                    className="row"
                    style={{
                      margin: "0",
                      width: "100%",
                      boxSizing: "border-box",
                      marginLeft: "6px",
                    }}
                  >
                    <div className="fw-700 fs-18 lh-243"> Billings</div>

                    <div
                      className="col fw-700 fs-18 lh-243"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        maxWidth: "70%",
                        flexWrap: "nowrap",
                        overflow: "auto",
                        padding: "0px",
                      }}
                    ></div>

                    <div style={{ marginTop: "16px" }}>
                      {filteredBills.map((b, j) => (
                        <div key={j} className="darker-on-hover">
                          <>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                paddingTop: "16px",
                                paddingBottom: "16px",
                                paddingLeft: "20.52px",
                              }}
                            >
                              <div
                                className="fw-500 fs-18 lh-24"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  className="col fw-500 fs-18 lh-24"
                                  style={{
                                    minWidth: "150px",
                                    maxWidth: "300px",
                                  }}
                                >
                                  {b.date}
                                </div>
                              </div>
                              <div style={{ width: "30%" }}>
                                <div
                                  className="align-items-center fw-700"
                                  style={{
                                    width: "367px",
                                    marginRight: "50.12px",
                                    height: "44px",
                                    width: "140px",
                                    float: "right",
                                  }}
                                >
                                  <button className="btn-secondary fw-700 fs-16">
                                    <img
                                      src={pdf}
                                      alt="PDF icon"
                                      style={{
                                        width: "20px",
                                        height: "20px",
                                        marginTop: "-3px",
                                      }}
                                    ></img>{" "}
                                    Download
                                  </button>
                                </div>
                              </div>
                            </div>
                            {j < bills.length - 1 && (
                              <hr style={{ margin: "0px", width: "95%" }}></hr>
                            )}
                          </>
                        </div>
                      ))}
                    </div>
                    <div>
                      <button
                        onClick={() => setShowAllBills(!showAllBills)}
                        className="fw-700 btn-secondary"
                        style={{ marginTop: "32px", marginLeft: "-17px" }}
                      >
                        {showAllBills ? <>Show less</> : <>Show more</>}
                      </button>
                    </div>
                  </div>
                  <hr
                    className="row"
                    style={{
                      marginLeft: "-50px",
                      marginTop: "50.81px",
                      marginBottom: "32px",
                    }}
                  ></hr>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default function Authorizations() {
  const [userInfo, setUserInfo] = useState(null);
  const [hasUserInfo, setHasUserInfo] = useState(false);
  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();

  const [savingToDb, setSavingToDb] = useState(false);

  const [isGoogleAccount, setIsGoogleAccount] = useState(false);
  const [projects, setProjects] = useState(null);
  const [paymentModalIsOpen, setPaymentModalIsOpen] = useState(false);

  useEffect(() => {
    const fetchUserInformation = async () => {
      if (user) {
        setHasUserInfo(true);
        getUserInformation().then((info) => {
          setUserInfo(info);
          setIsGoogleAccount(info?.isGoogleAccount);
          getUserProjects(info).then((projects) => {
            const updatedProjects = projects.map((project) => ({
              ...project,
            }));
            setProjects(updatedProjects);
          });
        });
      }
    };
    if (!hasUserInfo) fetchUserInformation();
  }, [user]);

  useEffect(() => {
    if (
      !loading &&
      (!user || (user && userInfo && ["designer"].includes(userInfo.role)))
    ) {
      navigate("/");
    }
  }, [loading, userInfo, user]);

  const saveProjectToDb = async (projectId, fields) => {
    setSavingToDb(true);
    const projectDoc = doc(db, "projects", projectId);
    await updateDoc(projectDoc, fields).then(() => {
      setSavingToDb(false);
    });
  };

  /**
   * If the page is loading, or user info is loading, the Spinning wheel will be shown.
   */
  if (loading || (user && !userInfo?.role)) {
    return (
      <div
        style={{
          height: "100vh",
          width: "100vw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner style={{ height: "70px", width: "70px" }} />
      </div>
    );
  }

  if (user) {
    return (
      <>
        <ToastContainer />
        <PaymentModal
          isOpen={paymentModalIsOpen}
          setIsOpen={setPaymentModalIsOpen}
          userInfo={userInfo}
          setUserInfo={setUserInfo}
          closable={true}
        />
        <PlanAndBillingsContent
          userInfo={userInfo}
          user={user}
          setUserInfo={setUserInfo}
          setSavingToDb={setSavingToDb}
          savingToDb={savingToDb}
          isGoogleAccount={isGoogleAccount}
          projects={projects}
          setProjects={setProjects}
          setPaymentModalIsOpen={setPaymentModalIsOpen}
        />
      </>
    );
  }
}
