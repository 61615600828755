import { useEffect, useState } from "react";
import CustomInput from "../CustomInput/CustomInput";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase";
import { Spinner } from "react-bootstrap";
import {
  dateFirestoreToReactDaysAgo,
  getChatroomsForAdmin,
  AdminTable,
} from "../../helpers";
import { ToastContainer } from "react-toastify";

export default function AdminMessages(props) {
  const [user, loading] = useAuthState(auth);
  const [isLoading, setIsLoading] = useState(true);
  const [filter, setFilter] = useState("");
  const [chatrooms, setChatrooms] = useState(null);
  const [filteredChatrooms, setFilteredChatrooms] = useState(null);

  useEffect(() => {
    props.setActiveMenuItem("messages");
  }, []);

  function handleStateChange(value, field, type, id, setter) {
    setter((prevList) =>
      prevList.map((states, j) => {
        if (
          (type === "id" && states.id === id) ||
          (type === "index" && j === id)
        ) {
          return {
            ...states,
            [field]: value,
          };
        }
        return states;
      }),
    );
  }

  async function populateExisting() {
    let rooms = await getChatroomsForAdmin();

    if (!rooms || (rooms && rooms.length === 0)) {
      setIsLoading(false);
      return;
    }

    let roomsWithAttributes = rooms.map((p, i) => {
      return {
        ...p,
        expanded: false,
        dropdownOpen: false,
        planLoading: false,
      };
    });
    setChatrooms(roomsWithAttributes);
    setFilteredChatrooms(roomsWithAttributes);
    setIsLoading(false);
  }

  useEffect(() => {
    if (user) {
      setIsLoading(true);
      populateExisting();
    }
  }, [user]);

  const handleFilterChange = (value) => {
    setFilter(value);
    setFilteredChatrooms(
      chatrooms.filter(
        (p) =>
          p?.clientFirstName?.toLowerCase().includes(value.toLowerCase()) ||
          p?.clientCompany?.toLowerCase().includes(value.toLowerCase()) ||
          p?.projects?.some((project) =>
            project.toLowerCase().includes(value.toLowerCase()),
          ),
      ),
    );
  };

  const existingWidths = [230, 270, 185, 185, 300];
  const existingHeaders = [
    {
      title: "User",
      icon: <i className="fa-light fa-circle-user"></i>,
      render: (p) => (
        <div>
          <div
            className="charlimit-no-width"
            style={{ paddingTop: "6px", maxWidth: "230px" }}
          >
            {p?.clientFirstName}
          </div>
        </div>
      ),
    },
    {
      title: "Organization",
      icon: <i className="fa-regular fa-star"></i>,
      render: (p) => (
        <div>
          {p?.organizations?.length > 0 && <div>{p.organizations[0]}</div>}
          {p?.organizations?.slice(1).length > 0
            ? `+${p?.organizations?.slice(1).length} more`
            : ""}
        </div>
      ),
      renderExpand: (p) => (
        <div style={{ minHeight: "30px" }}>
          {p?.organizations?.slice(1).map((organization, k) => (
            <div
              key={k}
              className="charlimit-no-width"
              style={{
                paddingTop: "6px",
                maxWidth: "230px",
                marginBottom: "20px",
              }}
            >
              {organization}
            </div>
          ))}
        </div>
      ),
    },

    {
      title: "Last message",
      icon: <i className="fa-light fa-star"></i>,
      render: (p) => <div>{dateFirestoreToReactDaysAgo(p?.latestMessage)}</div>,
    },
    {
      title: "Project",
      icon: <i className="fa-light fa-star"></i>,
      render: (p) => (
        <div>
          {p?.projects?.length > 0 && <div>{p.projects[0]}</div>}
          {p?.projects?.slice(1).length > 0
            ? `+${p?.projects?.slice(1).length} more`
            : ""}
        </div>
      ),
      renderExpand: (p) => (
        <div style={{ minHeight: "30px" }}>
          {p?.projects?.slice(1).map((project, k) => (
            <div
              key={k}
              className="charlimit-no-width"
              style={{
                paddingTop: "6px",
                maxWidth: "230px",
                marginBottom: "20px",
              }}
            >
              {project}
            </div>
          ))}
        </div>
      ),
    },
    {
      title: "",
      icon: <></>,
      render: (p, i) => (
        <>
          <div
            style={{ display: "flex", justifyContent: "flex-end" }}
            className="fs-14"
          >
            <button
              onClick={async () => {
                props.handleChannelClick(p);
              }}
              className="btn-primary appa-btn mr-8 fw-700"
            >
              Message
            </button>

            <button
              onClick={() => {
                handleStateChange(
                  !p.expanded,
                  "expanded",
                  "id",
                  p?.id,
                  setChatrooms,
                );
                handleStateChange(
                  !p.expanded,
                  "expanded",
                  "id",
                  p?.id,
                  setFilteredChatrooms,
                );
              }}
              className="admin-expand-chevron"
            >
              {p.expanded ? (
                <i className="fc-black fa-solid fa-chevron-up"></i>
              ) : (
                <i className="fc-black fa-solid fa-chevron-down"></i>
              )}
            </button>
          </div>
        </>
      ),
    },
  ];

  return (
    <div>
      <ToastContainer />
      <div className="fs-24 fw-700 lh-243 mb-3 fc-black mb-50">Messages</div>

      <div style={{ height: "60px" }} className="row mb-32 mt-5 w-100 vhc">
        <div className="col-2">
          <CustomInput
            placeholder="Filter by User, Organization or Project"
            containerClassName="observation-text-input-container half-width"
            inputClassNames="w-100"
            className={filter === "" ? "active" : ""}
            valueState={filter}
            onChange={(e) => handleFilterChange(e.target.value)}
            value={filter}
            disabled={isLoading}
          />
        </div>
        <div className="col-10"> </div>
      </div>
      {isLoading ? (
        <Spinner style={{ height: "75px", width: "75px", marginTop: "0px" }} />
      ) : (
        <AdminTable
          widths={existingWidths}
          headers={existingHeaders}
          rows={filteredChatrooms}
        ></AdminTable>
      )}
    </div>
  );
}
