import React, { useState } from "react";
import Modal from "react-modal";
import RecordingFrame from "./RecordingFrame";
import "./RecordingModal.css";
import { Spinner } from "react-bootstrap";
import CustomNavbar from "../CustomNavbar/CustomNavbar";

const RecordingModal = ({ title, isOpen, onRequestClose, replay }) => {
  const [loading, setLoading] = useState(false);

  const tabItems = [
    {
      name: "Recording",
      onClick: () => {},
    },
    {
      name: "Key Numbers",
      onClick: () => {},
    },
    {
      name: "Insights",
      onClick: () => {},
    },
  ];

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Session recording"
      className="recording-modal custom-modal"
      overlayClassName="modal-overlay-grey"
      appElement={document.getElementById("root")}
    >
      <div className="recording-modal-content">
        <h2 className="mt-50 fw-700 fs-20 lh-27 fc-black2 mb-32">
          {title ?? "View session recording"}
        </h2>
        <i
          className="fa-solid fa-xmark close-icon-32"
          onClick={onRequestClose}
        ></i>
        <div className="mb-32" style={{ marginRight: "45px" }}>
          <CustomNavbar tabItems={tabItems} changeOnClick={false} />
        </div>

        {replay ? (
          <div className="recording-frame-container">
            <RecordingFrame data={replay} />
          </div>
        ) : loading ? (
          <div
            style={{
              height: "80%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "100px",
            }}
          >
            <Spinner style={{ height: "70px", width: "70px" }} />
          </div>
        ) : (
          <>
            <p>No recording found...</p>
          </>
        )}
      </div>
    </Modal>
  );
};

export default RecordingModal;
